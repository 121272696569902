import { PartialDependent, Relationship } from 'types/dependent'

import { ssnValidation } from './types'

export const formDependent = (relationship: Relationship, defaultToggle?: boolean): PartialDependent => ({
	birthDate: '',
	dependentId: self.crypto.randomUUID(),
	firstName: '',
	gender: '',
	isDisabled: defaultToggle,
	isOfAge: true,
	isStudent: defaultToggle,
	lastName: '',
	relationship,
	socialSecurityNumber: '',
})

const areaCodeAndNextThree = (value: string): string => `(${value.slice(0, 3)}) ${value.slice(3)}`
/**
 * @param {string} value - current phone input value
 * @returns {string} '(xxx) xxx-', (xxx) xxx-x', '(xxx) xxx-xx', '(xxx) xxx-xxx', '(xxx) xxx-xxxx'
 */
const finalPhoneNumber = (value: string): string => `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
// only allows 0-9 inputs
const convertStringToNumber = (value: string): string => value.replace(/[^\d]/g, '')

export const formatInteger = (minValue: number, maxValue: number) => (value: string | number) => {
	const stringValue = value.toString()

	if (!stringValue) return ''

	const integerValue = convertStringToNumber(stringValue)

	if (!integerValue) return ''

	if (+integerValue < minValue || +integerValue > maxValue) {
		return integerValue.substring(0, stringValue.length - 1)
	}

	return integerValue
}

export const formatSsn = (value: string): string => {
	if (!value) return ''

	const firstFiveSsn = (val: string): string => `${val.slice(0, 3)}-${val.slice(3, 5)}`
	const finalSsn = (val: string): string => `${val.slice(0, 3)}-${val.slice(3, 5)}-${val.slice(5, 9)}`
	const currentValue: string = convertStringToNumber(value)

	if (currentValue.length < 4) {
		return currentValue
	}
	if (currentValue.length < 6) {
		return firstFiveSsn(currentValue)
	}

	return finalSsn(currentValue)
}

export const formatPhoneNumber = (value: string): string => {
	const currentValue: string = convertStringToNumber(value)

	if (currentValue.length < 4) {
		return currentValue
	}

	if (currentValue.length < 7) {
		return areaCodeAndNextThree(currentValue)
	}

	return finalPhoneNumber(currentValue)
}

export const formatPhoneNumberFromE165 = (value: string): string => {
	if (!value) {
		return ''
	}

	return formatPhoneNumber(value[0] === '+' ? value.substring(2) : value)
}

export const formatZipCode = (value: string): string => {
	const addHyphen = (val) => `${val.slice(0, 5)}-${val.slice(5)}`
	if (value.length > 5 && !value.includes('-')) {
		return addHyphen(value)
	}

	return value
}

export const getSsnErrorMessage = (ssn: string | null) => {
	try {
		ssnValidation.validateSync(ssn)
	} catch (e: any) {
		return e.message
	}

	return undefined
}
