import { StateMachineStatus } from 'hooks/use-async-fetch'
import { PersonalInfo } from 'pages/enrollment-wizard/info/types'
import { EnrollmentStatus, Question } from 'pages/enrollment-wizard/types'
import { PlanList, WizardErrorModalStatus } from 'pages/enrollment-wizard/wizard/types'
import { createSelector } from 'reselect'
import { RateRequestBoolVals } from 'services/benefit-plans.service'
import { SaveEnrollmentResponseV2 } from 'services/enrollments.service'
import { findPlanById, getPlanAsArray, getPlanEffectiveDate, selectAvailablePlans } from 'store/benefit-plans/selectors'
import type { BenefitPlanV2, BenefitPlansState } from 'store/benefit-plans/types'
import { RootState } from 'store/rootReducer'
import { getSingleId } from 'store/utils'
import { match } from 'ts-pattern'
import { Tier } from 'types/benefit-plan'
import { PartialDependentList, SPOUSE } from 'types/dependent'
import EnrollmentEvent from 'types/enrollment-event'
import { NewRate } from 'types/rates'
import { StatusEnum } from 'types/status-enums'
import { UserEnrollmentRes } from 'types/user-enrollment'
import {
	EnrollmentWizardState,
	PlanAnswer,
	Selections,
	StartEnrollmentError,
	WizardPlanData,
	WizardValidationErrors,
} from './types'
import { getEnrollmentIdFromState } from './util'

export type PlanSelectionPage = {
	answeredQuestions: Array<Question>
	eoiAgreement: boolean
	isDeclined: boolean
	isEditing: boolean
	isHighlightingRequiredFields: boolean
	isSavingEnrollment: StateMachineStatus
	plan: BenefitPlanV2 | undefined
	planAnswers: Record<string, PlanAnswer>
	programEnrollmentId: string
	savePersonStatus: StateMachineStatus
	selections: Selections
	termsAgreement: boolean
	userEnrollmentOverviewStatus: StateMachineStatus
}

export type QuestionsPage = {
	activePlan: BenefitPlanV2
	answeredQuestions: Array<Question>
	isDeclined: boolean
	isSavingEnrollment: StateMachineStatus
	selections: Selections
}

export type PersonalPage = {
	personal: PersonalInfo
	shouldShowSSN: boolean
}

export const ratesToSelections = (rateSelection: NewRate): Selections => ({
	amount: rateSelection?.amount,
	coverage: rateSelection?.coverageCode,
	coverageId: rateSelection?.coverageId,
	frequency: rateSelection?.frequency,
	tier: rateSelection?.tierCode as Tier,
	tierId: rateSelection?.tierId,
})

export const formSelections = (
	planSelections: WizardPlanData,
	benefitPlanId: number,
): Selections & { benefitPlanId: number } => ({
	amount: planSelections?.amount,
	benefitPlanId,
	coverage: planSelections?.coverage,
	coverageId: planSelections?.coverageId ?? '',
	frequency: planSelections?.frequency,
	tier: planSelections?.tier,
	tierId: planSelections?.tierId ?? '',
})
export const buildEmptySelections = (): Selections => ({
	amount: null,
	coverage: '',
	coverageId: '',
	frequency: '',
	tier: null,
	tierId: '',
})
const enrollmentWizard = (state: RootState): EnrollmentWizardState => state.enrollmentWizard
export const answeredKnockoutSelector = createSelector(enrollmentWizard, (wizard) => wizard.hasAnsweredKnockout)

export const isPlanDeclinedOrCanceled = (planData: WizardPlanData) =>
	planData?.enrollmentStatus === EnrollmentStatus.DECLINED || planData?.enrollmentStatus === EnrollmentStatus.CANCELED

export const getDeclinedPlans = (wizard: EnrollmentWizardState): Array<number> =>
	Object.keys(wizard.planSelections)
		.filter((benefitPlanId) => isPlanDeclinedOrCanceled(wizard.planSelections[benefitPlanId]))
		.map((x) => +x)

const selectedPlans = (enrollmentWizard: EnrollmentWizardState): Array<number> => enrollmentWizard.selectedPlans

const questionsPage = (enrollmentWizard: EnrollmentWizardState, id: number): QuestionsPage => ({
	activePlan: enrollmentWizard.activePlan,
	answeredQuestions: enrollmentWizard.planSelections?.[id].answeredQuestions,
	isDeclined: isPlanDeclinedOrCanceled(planSelections?.[id]),
	isSavingEnrollment: enrollmentWizard.isSavingEnrollment,
	selections: formSelections(enrollmentWizard.planSelections?.[id], id),
})

const planSelections: (state: RootState) => Record<number, WizardPlanData> = (
	state: RootState,
): Record<number, WizardPlanData> => state.enrollmentWizard.planSelections

export const isEditingPlanSelector = createSelector(
	planSelections,
	getSingleId,
	(planSelections: Record<number, WizardPlanData>, id: number): boolean => planSelections?.[id]?.isEditing,
)
export const selectPlanSelectionsSelector = createSelector(
	planSelections,
	getSingleId,
	(planSelections: Record<number, WizardPlanData>, id: number): WizardPlanData | undefined => {
		return planSelections?.[id]
	},
)

export const isDisplayingSystemErrorsSelector = createSelector(
	enrollmentWizard,
	(wizard) => wizard.isDisplayingSystemErrors,
)
export const selectCurrentProgramEnrollmentId = createSelector(
	enrollmentWizard,
	(wizard) => wizard.planSelections?.[wizard.activePlan.benefitPlanId].programEnrollmentId ?? '',
)
export const selectDecliningModalStatus = createSelector(
	enrollmentWizard,
	(wizard): StatusEnum => wizard.decliningInModalStatus,
)
export const selectFinalDisclaimer = createSelector(
	enrollmentWizard,
	(wizard): boolean => wizard.acknowledgedFinalDisclaimer,
)
export const selectValidationResults = createSelector(
	enrollmentWizard,
	(wizard): WizardValidationErrors[] => wizard.validationResults,
)
const getActivePlan = (enrollmentWizard: EnrollmentWizardState): BenefitPlanV2 => enrollmentWizard.activePlan

export const selectEnteredDob = createSelector(enrollmentWizard, (wizard: EnrollmentWizardState) => wizard.enteredDob)
export const selectHasDobError = createSelector(enrollmentWizard, (wizard: EnrollmentWizardState) => wizard.hasDobError)
export const areAnswersLoadedSelector = createSelector(
	enrollmentWizard,
	({ areAnswersLoaded }: EnrollmentWizardState) => areAnswersLoaded,
)
export const selectEnrolledPetDataStatus = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.petEnrollmentFetchStatus,
)
export const selectEnrolledPetData = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.petEnrollments,
)
export const selectSplitPetData = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.petEnrollments.splitPets,
)
export const selectMappedPetData = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.petEnrollments.mappedPets,
)
export const selectPetData = createSelector(enrollmentWizard, (wizard: EnrollmentWizardState) => ({
	petData: wizard.petInfo,
	petDisclaimer: wizard.petDisclaimer,
	petFrequency: wizard.petFrequency,
}))
export const selectPetFrequency = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.petFrequency,
)
export const selectPetSubmissionInfo = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.petSubmissionInfo,
)
export const selectPetTotal = createSelector(enrollmentWizard, (wizard: EnrollmentWizardState) => wizard.petTotal)
export const personalPageSelector = createSelector(
	enrollmentWizard,
	(state: RootState) => ({
		benefitPlans: state.benefitPlans,
	}),
	(
		{
			activePlan,
			event,
			firstPlanInOrder,
			isSingleProductFlow,
			personal,
			planSelections,
			selectedPlans,
		}: EnrollmentWizardState,
		{ benefitPlans },
	) => {
		const isInSelectedPlans = (planId: number): boolean => selectedPlans.includes(planId)
		const shouldShowAdditionalInfoFields: boolean = selectedPlans.length > 0
		const filteredPlans = getPlanAsArray(benefitPlans, {
			event: event as EnrollmentEvent,
			isSingleProductFlow,
			planId: activePlan?.benefitPlanId ?? -1,
			shouldReOrder: firstPlanInOrder > -1,
		})

		const isSelected = ({ benefitPlanId }: BenefitPlanV2): boolean =>
			isInSelectedPlans(benefitPlanId) ||
			planSelections[benefitPlanId]?.tier != null ||
			planSelections[benefitPlanId]?.enrollmentStatus === EnrollmentStatus.LEAVED ||
			planSelections[benefitPlanId]?.enrollmentStatus === EnrollmentStatus.ENROLLED

		const shouldShowSSN: boolean = filteredPlans.some(
			(plan: BenefitPlanV2): boolean =>
				plan?.shouldCollectSSN && isSelected(plan) && !isPlanDeclinedOrCanceled(planSelections[plan.benefitPlanId]),
		)

		return { personal, shouldShowAdditionalInfoFields, shouldShowSSN }
	},
)

export const earliestPlanEffectiveDate = ({ selectedPlans }: EnrollmentWizardState, benefitPlans): string => {
	const plans: string[] =
		selectedPlans
			.map((plan) => getPlanEffectiveDate(benefitPlans, plan))
			.filter((x) => x != null)
			.map((effectiveDate) => effectiveDate?.toString()) ?? []
	if (!plans.length) return ''

	return plans.reduce((pre, curr) => (Date.parse(pre) > Date.parse(curr) ? curr : pre))
}

export const enrollmentIdSelector = createSelector(enrollmentWizard, (state: EnrollmentWizardState): string =>
	getEnrollmentIdFromState(state),
)

export const shouldIgnoreChildAge = (
	{
		activePlan,
		event,
		isSingleProductFlow,
		planSelections,
		selectedPlans,
	}: Pick<EnrollmentWizardState, 'activePlan' | 'event' | 'isSingleProductFlow' | 'planSelections' | 'selectedPlans'>,
	{
		benefitPlanState,
		userEnrollmentOverview,
	}: { benefitPlanState: BenefitPlansState; userEnrollmentOverview: Record<number, UserEnrollmentRes[]> },
): boolean => {
	const plans = getPlanAsArray(
		{ ...benefitPlanState, firstPlanInOrder: -1 },
		{
			event: event as EnrollmentEvent,
			isSingleProductFlow: isSingleProductFlow,
			planId: activePlan?.benefitPlanId ?? -1,
			shouldReOrder: false,
		},
	)
	const plansList: BenefitPlanV2[] = plans.filter(
		({ benefitPlanId }) =>
			!isPlanDeclinedOrCanceled(planSelections[benefitPlanId]) && selectedPlans.includes(benefitPlanId),
	)
	const isInSelectedPlans: boolean = plansList.some((plan: BenefitPlanV2): boolean => {
		const tier = planSelections?.[plan.benefitPlanId].tier
		const coverage = planSelections?.[plan.benefitPlanId].coverage

		if (!plan.shouldIgnoreChildAge || !tier || (!tier && !coverage)) return false

		return tier === Tier.EC || tier === Tier.FA
	})

	const isInUserEnrollments: boolean = Object.keys(userEnrollmentOverview)
		.filter((planId: string) => !plansList.find((p) => p.benefitPlanId === +planId))
		.map((planId: string) => {
			const v2Plan = plansList?.find((p) => p.programId === +planId)

			return {
				planId: +planId,
				shouldIgnoreChildAge: v2Plan?.shouldIgnoreChildAge ?? false,
			}
		})
		.some(({ planId, shouldIgnoreChildAge }: { planId: number; shouldIgnoreChildAge: boolean }): boolean =>
			!planSelections?.[planId]?.isEditing && !isPlanDeclinedOrCanceled(planSelections[planId]) && shouldIgnoreChildAge
				? userEnrollmentOverview?.[planId]?.[0]?.tier === Tier.EC ||
				  userEnrollmentOverview?.[planId]?.[0]?.tier === Tier.FA
				: false,
		)

	return isInSelectedPlans || isInUserEnrollments
}

export const disableSpouseDobSelector = createSelector(
	enrollmentWizard,
	(state: RootState) => state.benefitPlans.availablePlans,
	({ planSelections }, benefitPlans) => {
		const mappedValues = Object.keys(planSelections).map((key) => ({ ...planSelections[key], benefitPlanId: +key }))
		const filteredPlans = benefitPlans.filter((plan) =>
			mappedValues.find(({ benefitPlanId }) => benefitPlanId === plan.benefitPlanId),
		)
		const mergedPlans = mappedValues.map((plan) => ({
			...filteredPlans.find(({ benefitPlanId }) => benefitPlanId === plan.benefitPlanId),
			...plan,
		}))

		return mergedPlans.some(
			(plan) => plan?.shouldRequireSpouseInfoForPricing && (plan?.tier === Tier.ES || plan?.tier === Tier.FA),
		)
	},
)

export const shouldShowDependents = (
	{ planSelections, selectedPlans }: EnrollmentWizardState,
	{
		benefitPlans,
		userEnrollmentOverview,
	}: { benefitPlans: BenefitPlanV2[]; userEnrollmentOverview: Record<number, UserEnrollmentRes[]> },
	plans?: PlanList,
): boolean => {
	// Plans that are declined shouldnt matter
	const planSelectionsIds = (plans?.map((x) => x.id) ?? selectedPlans).filter(
		(id: number) => !isPlanDeclinedOrCanceled(planSelections[id]),
	)

	return planSelectionsIds
		.filter((id) => benefitPlans.find(({ benefitPlanId }) => benefitPlanId === id) != null)
		.map((key) => ({ ...planSelections[key], ...benefitPlans.find(({ benefitPlanId }) => benefitPlanId === key) }))
		.some((plan) => {
			const enrollment: UserEnrollmentRes = userEnrollmentOverview[plan.benefitPlanId as number]?.[0]
			const isEnrollmentEO: boolean = enrollment?.tier === Tier.EO
			if (!plan || !plan?.tier || plan?.tier === Tier.EO || (enrollment && !plan.isEditing && isEnrollmentEO)) {
				return false
			}

			return plan.shouldCollectDependents
		})
}
export const planSelectionSelector = createSelector(
	enrollmentWizard,
	(state: RootState, id: number) => ({
		id,
		plan: state.benefitPlans.availablePlans.find(({ benefitPlanId }) => benefitPlanId === id),
		userEnrollmentOverviewStatus: state.enrollments.userEnrollmentOverviewStatus,
	}),
	(
		{ isSavingEnrollment, planSelections, savePersonStatus }: EnrollmentWizardState,
		{ id, plan, userEnrollmentOverviewStatus },
	): PlanSelectionPage => ({
		answeredQuestions: planSelections?.[id]?.answeredQuestions,
		eoiAgreement: planSelections?.[id]?.evidenceOfInsurabilityAgreement,
		isDeclined: isPlanDeclinedOrCanceled(planSelections?.[id]),
		isEditing: planSelections?.[id]?.isEditing,
		isHighlightingRequiredFields: planSelections?.[id]?.isHighlightingRequiredFields,
		isSavingEnrollment,
		plan,
		planAnswers: planSelections?.[id]?.planAnswers,
		programEnrollmentId: planSelections?.[id]?.programEnrollmentId ?? '',
		savePersonStatus,
		selections: formSelections(planSelections?.[id], id),
		termsAgreement: planSelections?.[id]?.termsConditionAgreement,
		userEnrollmentOverviewStatus,
	}),
)

export const wizardPlanDataSelector = createSelector(
	enrollmentWizard,
	getSingleId,
	(state, id) => state.planSelections[id],
)

export const earliestPlanEffectiveDateSelector = createSelector(
	enrollmentWizard,
	(state: RootState) => state.benefitPlans,
	earliestPlanEffectiveDate,
)
export const enrollmentWizardSelector = createSelector(enrollmentWizard, (enrollmentWizard) => {
	const declinedPlans = getDeclinedPlans(enrollmentWizard)

	return { ...enrollmentWizard, declinedPlans }
})

export const shouldIgnoreChildAgeSelector = createSelector(
	({
		enrollmentWizard: { activePlan, event, isSingleProductFlow, planSelections, selectedPlans },
	}: RootState): Pick<
		EnrollmentWizardState,
		'activePlan' | 'event' | 'isSingleProductFlow' | 'planSelections' | 'selectedPlans'
	> => ({
		activePlan,
		event,
		isSingleProductFlow,
		planSelections,
		selectedPlans,
	}),
	(state: RootState) => ({
		benefitPlanState: state.benefitPlans,
		userEnrollmentOverview: state.enrollments.userEnrollmentOverview,
	}),
	selectAvailablePlans,
	shouldIgnoreChildAge,
)
export const shouldShowDependentsSelector = createSelector(
	enrollmentWizard,
	(state: RootState) => ({
		benefitPlans: state.benefitPlans.availablePlans,
		isOE: state.benefitPlans.isOE,
		oeWindow: state.benefitPlans.oeWindow,
		userEnrollmentOverview: state.enrollments.userEnrollmentOverview,
	}),
	(state: RootState, x: any) => x,
	shouldShowDependents,
)

export const shouldShowDependentsSSNSelector = createSelector(
	enrollmentWizard,
	(state: RootState) => state.enrollments.userEnrollmentOverview,
	(state: RootState) => state.benefitPlans,
	selectAvailablePlans,
	(
		{ event, firstPlanInOrder, isSingleProductFlow, planSelections }: EnrollmentWizardState,
		userEnrollmentOverview: Record<number, UserEnrollmentRes[]>,
		benefitState: BenefitPlansState,
	): boolean => {
		const tierIsNotEO = (tier: Tier) =>
			match(tier)
				.with(Tier.EC, Tier.FA, Tier.ES, () => true)
				.otherwise(() => false)
		const plansList = Object.keys(planSelections)
			.map(Number)
			.filter((id) => planSelections?.[id]?.tier && planSelections[id].tier !== Tier.EO)

		return plansList
			.map((id) =>
				getPlanAsArray(
					{ ...benefitState, firstPlanInOrder },
					{ event: event as EnrollmentEvent, isSingleProductFlow, planId: id, shouldReOrder: firstPlanInOrder > -1 },
				),
			)
			.flat()
			.some(
				(plan): boolean =>
					plan.shouldCollectDependentSSN &&
					((planSelections?.[plan.benefitPlanId]?.tier &&
						tierIsNotEO((planSelections[plan.benefitPlanId].tier as Tier) ?? Tier.EO)) ||
						tierIsNotEO(userEnrollmentOverview?.[plan.benefitPlanId]?.[0]?.tier ?? Tier.EO)),
			)
	},
)

const getDependents = (state: EnrollmentWizardState): PartialDependentList => state.dependentsInfo

export const dependentsSelector = createSelector(
	enrollmentWizard,
	(state: RootState) => state.benefitPlans,
	getDependents,
)

/**
 *
 * @returns plan selections made in the wizard as an array of selections
 */
interface SelectedTiers extends Selections {
	benefitPlanId: number
}
export const getSelectedTiers = ({ planSelections }: EnrollmentWizardState): Array<SelectedTiers> =>
	Object.keys(planSelections).map((planId) => formSelections(planSelections?.[+planId], +planId))

export const selectedTiersSelector = createSelector(enrollmentWizard, getSelectedTiers)
export const selectedTierSelector = createSelector(
	({ planSelections }: EnrollmentWizardState): Record<number, WizardPlanData> => planSelections,
	getSingleId,
	(planSelections: Record<number, WizardPlanData>, id): Selections | null => formSelections(planSelections?.[id], id),
)
export const questionsPageSelector = createSelector(enrollmentWizard, getSingleId, questionsPage)
export const selectedPlansSelector = createSelector(enrollmentWizard, selectedPlans)
export const activePlanSelector = createSelector(enrollmentWizard, getActivePlan)

const getStartEnrollmentError = (state: EnrollmentWizardState): StartEnrollmentError | null =>
	state.startEnrollmentError
const getStartEnrollmentStatus = (state: EnrollmentWizardState): StateMachineStatus => state.isStartingEnrollment
const getSaveEnrollmentStatus = (state: EnrollmentWizardState): StateMachineStatus => state.isSavingEnrollment
const getStartEnrollmentResponse = (state: EnrollmentWizardState): SaveEnrollmentResponseV2 | null =>
	state.startEnrollmentResponse
export const wizardErrorModalStatusSelector = createSelector(
	enrollmentWizard,
	(state: EnrollmentWizardState): WizardErrorModalStatus | null => state.wizardErrorModalStatus,
)

export const startEnrollmentErrorSelector = createSelector(enrollmentWizard, getStartEnrollmentError)
export const startEnrollmentStatusSelector = createSelector(enrollmentWizard, getStartEnrollmentStatus)
export const startEnrollmentResponseSelector = createSelector(enrollmentWizard, getStartEnrollmentResponse)
export const saveEnrollmentStatusSelector = createSelector(enrollmentWizard, getSaveEnrollmentStatus)
export const selectPetRatesFetchStatus = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.petRatesFetchStatus,
)

export const isEditingRateRequestParametersSelector = createSelector(
	enrollmentWizard,
	(state) => state.isEditingRateRequestParameters,
)
export const rateRequestParamsSelector = createSelector(
	(state: RootState): RootState => state,
	getSingleId,
	(_: any, __: any, isDobRequired: boolean): boolean => isDobRequired,
	(
		state: RootState,
		id: number,
		isDobRequired: boolean,
	): { isCallValid: boolean; rateRequestParams: RateRequestBoolVals } => {
		const wizard = enrollmentWizard(state)
		//From benefitPlans store
		const benefitPlan = findPlanById(state.benefitPlans.availablePlans, id)
		//From EnrollmentWizard store
		const plan = wizard.planSelections?.[id]

		const employeeDob = wizard.enteredDob
		const spouseDob = wizard.dependentsInfo.find((dep) => dep.relationship === SPOUSE)?.birthDate.toString()
		const keyDate = new Date(plan?.keyDate as string).toDateString()
		const isIncludingSpouseInfo = plan?.isIncludingSpouseInfo

		//Checking what the requirements for the call are
		const isEmployeeDobRequired = isDobRequired
		const isEmployeeSmokerRequired =
			(benefitPlan?.hasEmployeeTobaccoQuestion ?? false) || (benefitPlan?.hasFamilyTobaccoQuestion ?? false)
		const isSpouseDobRequired = isIncludingSpouseInfo
		const isSpouseSmokerRequired = isIncludingSpouseInfo && (benefitPlan?.hasSpouseTobaccoQuestion ?? false)

		//Checking if the call has the required params to make the call
		const isEmployeeDobRequiredAndMissing: boolean = isEmployeeDobRequired && (wizard.hasDobError || !employeeDob)
		const isEmployeeSmokerRequiredAndMissing: boolean =
			isEmployeeSmokerRequired && plan?.rateRequestParams?.employeeIsSmoker === undefined
		const isSpouseDobRequiredAndMissing: boolean =
			isSpouseDobRequired && isIncludingSpouseInfo && (!spouseDob || spouseDob === '')
		const isSpouseSmokerRequiredAndMissing: boolean =
			isSpouseSmokerRequired && plan?.rateRequestParams?.spouseIsSmoker === undefined
		const isPlanDetailsMissing: boolean = id === undefined || keyDate === undefined

		//Checking if date fields are currently being editted
		const isEditingRateRequestParameters: boolean = wizard.isEditingRateRequestParameters

		const isCallValid: boolean = !(
			isEmployeeDobRequiredAndMissing ||
			isEmployeeSmokerRequiredAndMissing ||
			isSpouseDobRequiredAndMissing ||
			isSpouseSmokerRequiredAndMissing ||
			isPlanDetailsMissing ||
			isEditingRateRequestParameters
		)

		const params: RateRequestBoolVals = { benefitPlanId: id, keyDate: keyDate }

		if (isEmployeeDobRequired) params.employeeDoB = employeeDob
		if (isEmployeeSmokerRequired) params.employeeIsSmoker = plan?.rateRequestParams?.employeeIsSmoker
		if (isSpouseDobRequired) params.spouseDoB = spouseDob
		if (isSpouseSmokerRequired) params.spouseIsSmoker = plan?.rateRequestParams?.spouseIsSmoker

		return { isCallValid, rateRequestParams: params }
	},
)

export const benefitElectionsSubmitStatusSelector = createSelector(
	enrollmentWizard,
	(state: EnrollmentWizardState): StateMachineStatus => state.submitBenefitElectionsStatus,
)

export const benefitElectionsSubmitErrorSelector = createSelector(
	enrollmentWizard,
	(state: EnrollmentWizardState): string[] => state.benefitElectionsSubmitError,
)

export const enrollmentEventSelector = createSelector(
	enrollmentWizard,
	(state: EnrollmentWizardState): string | null => state.event,
)

export const selectStartEnrollmentData = createSelector(
	enrollmentWizard,
	(wizard: EnrollmentWizardState) => wizard.startEnrollmentData,
)
