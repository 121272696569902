import { P, match } from 'ts-pattern'
import EnrollmentEvent from 'types/enrollment-event'

export const getEnrollmentIdFromState = (state): string =>
	match<{ enrollmentId: string | Record<EnrollmentEvent, string>; event: EnrollmentEvent | null }, string>({
		enrollmentId: state.enrollmentId,
		event: state.event,
	})
		.with({ event: P.string }, ({ enrollmentId, event }) => enrollmentId?.[event] ?? '')
		.with({ enrollmentId: P.string }, ({ enrollmentId }) => enrollmentId)
		.otherwise(() => '')
