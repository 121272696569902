import { UserManager } from 'oidc-client'
import { createSelector } from 'reselect'

import { CustomerCareInfo, SiteInfoResponse } from 'types/tenant-site-types'
import { Confirmed } from './enrollments/selectors'
import { siteInfoSelector, siteSelector } from './site/selectors'
import { SiteState } from './site/types'
import { localUserSelector, oidcSelector } from './user/selectors'
import { UserState } from './user/types'

export type HeaderSelectorReturnType = {
	firstName?: string
	logo: string
	primaryColor: string
}

export type SilentRenewSelector = {
	siteInfo: SiteInfoResponse
	userManager: UserManager
}

export type ConfirmationPage = Confirmed & CustomerCareInfo

const headerInfo = (globalElements, oidc): HeaderSelectorReturnType => ({
	firstName: oidc?.user?.profile?.given_name ?? 'User',
	logo: globalElements.logo ?? '',
	primaryColor: globalElements.primaryColor ?? '',
})

export const headerSelector = createSelector(siteInfoSelector, oidcSelector, headerInfo)
const silentRenewInfo = (site: SiteState, user: UserState): SilentRenewSelector => ({
	siteInfo: site.siteInfo,
	userManager: user.userManager,
})

export const silentRenewSelector = createSelector(siteSelector, localUserSelector, silentRenewInfo)
