import { GlobalContextProviders } from 'app/GlobalContextProviders'
import { AxiosInterceptor } from 'config/axios'
import { useLogRocket } from 'hooks/logRocket'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { OidcProvider, loadUser } from 'redux-oidc-js'
import { isSiteLoadingSelector } from 'store/site/selectors'
import { store } from 'store/store'
import { getUserManagerSelector, isLoggedInSelector } from 'store/user/selectors'
import StandardLayout from './standard-layout'

const AppLayout = () => {
	const userManager = useSelector(getUserManagerSelector)
	const isSiteLoading = useSelector(isSiteLoadingSelector)
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)
	useLogRocket(isLoggedIn)

	// /**
	//  * Load the user
	//  */
	useEffect(() => {
		if (!isSiteLoading) loadUser(store, userManager)
	}, [isSiteLoading, userManager])

	return (
		<OidcProvider store={store} userManager={userManager}>
			<AxiosInterceptor>
				<GlobalContextProviders isLoggedIn={isLoggedIn}>
					<StandardLayout />
				</GlobalContextProviders>
			</AxiosInterceptor>
		</OidcProvider>
	)
}

export default AppLayout
