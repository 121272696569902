import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Beneficiary } from 'api/benefit-elections'
import { AxiosError } from 'axios'
import { events } from 'config/analytics'
import { appInsights, reactPlugin } from 'config/app-insights'
import isAfter from 'date-fns/isAfter'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { BenefitElectionsService } from 'services/benefit-elections-service'
import { EnrollmentService } from 'services/enrollments.service'
import { WorkerService } from 'services/worker.service'
import { findPlanById } from 'store/benefit-plans/selectors'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { getPetCoverageLabel } from 'store/cart/utils'
import { getDeclinedPlans } from 'store/enrollment-wizard/selectors'
import { EnrollmentWizardState } from 'store/enrollment-wizard/types'
import { AppThunkAction } from 'store/util-types/types'
import { normalize } from 'store/utils'
import EnrollmentEvent from 'types/enrollment-event'
import { QLEEventsList, QLESubmissionProps } from 'types/enrollment-profile'
import { PetData } from 'types/pet'
import { RecentElection } from 'types/recent-election'
import { ERROR, IDLE, LOADING, SUCCESS } from 'types/status-types'
import { BenefitElectionsOverviewResponse, UserElection } from 'types/user-elections'
import { RecentUserEnrollmentRes, UserEnrollmentRes } from 'types/user-enrollment'
import { getDisclaimerText, periodMap, trackEvent } from 'utils/utils'
import { Enrolled, EnrollmentsState, OverviewSuccessData, ParsedOverviewData, RecentUserEnrollment } from './types'
import { parseElectionData, parseOverviewData } from './util'

const initialState: EnrollmentsState = {
	allLatestUserEnrollments: {},
	beneficiaries: [],
	confirmation: '',
	enrolledPet: [],
	enrollmentSubmitError: [],
	isSubmittingEnrollment: IDLE,
	qleEvents: null,
	qleEventsError: null,
	qleEventsStatus: IDLE,
	qleSubmissions: [],
	recentUserEnrollment: {},
	recentUserEnrollmentError: null,
	recentUserEnrollmentStatus: IDLE,
	userEnrollmentOverview: {},
	userEnrollmentOverviewError: null,
	userEnrollmentOverviewStatus: IDLE,
}

export const enrollmentsSlice = createSlice({
	initialState,
	name: 'enrollments',
	reducers: {
		addConfirmation(state: EnrollmentsState, action: PayloadAction<string>): void {
			state.confirmation = action.payload
		},
		addLatestUserEnrollment(state: EnrollmentsState, action: PayloadAction<RecentUserEnrollmentRes>): void {
			state.allLatestUserEnrollments = {
				...state.allLatestUserEnrollments,
				[action.payload.benefitPlanId]: action.payload,
			}
		},
		addRecentEnrollment(state: EnrollmentsState, action: PayloadAction<RecentUserEnrollmentRes>): void {
			if (isAfter(new Date(action.payload.planEffectiveDate), new Date())) {
				state.recentUserEnrollment = { ...state.recentUserEnrollment, [action.payload.benefitPlanId]: action.payload }
			}
		},
		clearRecentEnrollments(state: EnrollmentsState): void {
			state.recentUserEnrollment = {}
		},
		getQLEEventsError(state: EnrollmentsState, action: PayloadAction<Error>): void {
			state.qleEventsError = action.payload
			state.qleEventsStatus = ERROR
		},
		getQLEEventsSuccess(state: EnrollmentsState, action: PayloadAction<QLEEventsList>): void {
			state.qleEvents = action.payload
			state.qleEventsStatus = SUCCESS
		},
		/**
		 * Update recentUserEnrollment props of slice.
		 * Decided to keep the properties as is for now instead of
		 * adding new props just for a name change. We can change them
		 * with the removal of und3484
		 */
		getRecentElectionsSuccess(state: EnrollmentsState, action: PayloadAction<RecentElection[]>): void {
			state.recentUserEnrollment = normalize(action.payload, 'benefitPlanId', true) as RecentElection

			state.recentUserEnrollmentError = null
			state.recentUserEnrollmentStatus = SUCCESS
		},
		/**
		 * This can be renamed to getRecentUserEnrollmentsError with the removal of flag und3484
		 */
		getRecentUserEnrollmentsError(state: EnrollmentsState, action: PayloadAction<Error>): void {
			state.recentUserEnrollmentError = action.payload
			state.recentUserEnrollmentStatus = ERROR
		},
		/**
		 * @deprecated replaced with getRecentElectionsSuccess under flag und3484
		 */
		getRecentUserEnrollmentsSuccess(state: EnrollmentsState, action: PayloadAction<RecentUserEnrollmentRes[]>): void {
			const allNativeEnrollments = action.payload.filter((e) => e.isNative)
			state.recentUserEnrollment = normalize(allNativeEnrollments, 'benefitPlanId') as RecentUserEnrollment

			state.recentUserEnrollmentError = null
			state.recentUserEnrollmentStatus = SUCCESS
		},
		getUserElectionsSuccess(state: EnrollmentsState, action: PayloadAction<OverviewSuccessData>): void {
			/**
			 * allLatestUserEnrollments will not be necessary when we switch to the benefit elections
			 * version of the overview endpoint. This is here as a stopgap. We are currently only
			 * using the overview data from benefit elections for the my benefits page and the enrollment
			 * banner for the product cards/pages. However, we need data in the allLatestUserEnrollments prop
			 * for the wizard to work because we have not made changes for the wizard to use benefit elections
			 * overview data. We dont plan to either so for now we will do this:
			 */
			state.allLatestUserEnrollments = Object.values(action.payload.parsedElectionData).reduce((acc, bp) => {
				return { ...acc, [bp[0].benefitPlanId]: bp[0] }
			}, {})
			state.userEnrollmentOverview = action.payload.parsedElectionData
			state.userEnrollmentOverviewError = null
			state.userEnrollmentOverviewStatus = SUCCESS
			state.beneficiaries = action.payload.beneficiaries ?? []
		},
		getUserEnrollmentOverviewError(state: EnrollmentsState, action: PayloadAction<Error>): void {
			state.userEnrollmentOverviewError = action.payload
			state.userEnrollmentOverviewStatus = ERROR
		},
		/**
		 * @deprecated Replaced with getUserElectionsSuccess under flag und3484
		 */
		getUserEnrollmentOverviewSuccess(state: EnrollmentsState, action: PayloadAction<ParsedOverviewData>): void {
			state.allLatestUserEnrollments = action.payload.allLatestUserEnrollments
			state.userEnrollmentOverview = action.payload.userEnrollmentOverview

			state.userEnrollmentOverviewError = null
			state.userEnrollmentOverviewStatus = SUCCESS
		},
		loadingQLEEvents(state: EnrollmentsState): void {
			state.qleEventsStatus = LOADING
		},
		loadingRecentUserEnrollments(state: EnrollmentsState): void {
			state.recentUserEnrollmentStatus = LOADING
		},
		loadingUserEnrollmentOverview(state: EnrollmentsState): void {
			state.userEnrollmentOverviewStatus = LOADING
		},
		resetRecentUserEnrollmentStatus: (state: EnrollmentsState): void => {
			state.recentUserEnrollmentStatus = IDLE
		},
		// This next function is needed to not tie in to the reset submit in the wizard
		resetSubmitFromModal: (state: EnrollmentsState): void => {
			state.enrollmentSubmitError = []
			state.isSubmittingEnrollment = IDLE
		},
		resetSubmitStatus: (state: EnrollmentsState): void => {
			state.isSubmittingEnrollment = IDLE
		},
		setQleSubmissions(state: EnrollmentsState, action: PayloadAction<QLESubmissionProps>): void {
			if (!state.qleSubmissions.find((e) => e.enrollmentEvent === action.payload.enrollmentEvent)) {
				state.qleSubmissions.push(action.payload)
			}
		},
		submitEnrollmentError(state: EnrollmentsState, action: PayloadAction<AxiosError>): void {
			const { payload: error } = action
			const errorMessage: string[] = []
			if (error.response?.data && Array.isArray(error.response.data)) errorMessage.push(...error.response.data)
			else if (error.response?.data) errorMessage.push(error.response.data as string)

			state.enrollmentSubmitError = errorMessage
			state.isSubmittingEnrollment = ERROR
		},
		submitEnrollmentSuccessV2(
			state: EnrollmentsState,
			action: PayloadAction<{
				benefitPlans: BenefitPlanV2[]
				wizard: EnrollmentWizardState & { confirmation: string }
			}>,
		): void {
			const plans: BenefitPlanV2[] = Object.keys(action.payload.wizard.planSelections)
				.map(Number)
				.map((planId) => findPlanById(action.payload.benefitPlans, planId))
				.filter((plan: BenefitPlanV2 | undefined): boolean => plan != null) as BenefitPlanV2[]
			const normalizedPlans = normalize(plans, 'benefitPlanId')

			// Pets need their own array of enrollments with a record for each pet
			const formatPets = (petInfo: PetData[], petFrequency, plans: Record<number, BenefitPlanV2>): Enrolled[] => {
				const petPlanInfo = Object.values(plans).find(
					(plan) =>
						plan.benefitPlanType.toLowerCase().includes('pet') &&
						plan.benefitProviderName.toLowerCase().includes('nationwide'),
				)

				return petInfo.map((pet) => ({
					benefitPlanId: petPlanInfo?.benefitPlanId,
					carrierName: petPlanInfo?.benefitProviderName,
					cost: pet.amount,
					effectiveDate: petPlanInfo?.keyDate,
					name: petPlanInfo?.benefitPlanName,
					plan: getPetCoverageLabel(
						`${pet.petName.charAt(0).toUpperCase()}${pet.petName.slice(1)}`,
						pet.coverageName,
						pet.hasWellness,
					),
					planId: petPlanInfo?.benefitPlanId,
					ratePeriod: periodMap[petFrequency],
					statusCode: EnrollmentStatus.ENROLLED,
					tier: `${pet.coverage ? pet.coverage.substring(pet.coverage.length - 2) : ''}% Coverage`,
					type: petPlanInfo?.benefitPlanType,
				})) as Enrolled[]
			}

			state.isSubmittingEnrollment = SUCCESS
			state.confirmation = action.payload.wizard.confirmation

			// Handle pet enrollments
			if (
				action.payload.wizard.selectedPlans.some(
					(planId) =>
						normalizedPlans[planId].benefitPlanType.toLowerCase().includes('pet') &&
						normalizedPlans[planId].benefitProviderName.toLowerCase().includes('nationwide'),
				)
			)
				state.enrolledPet = formatPets(
					action.payload.wizard.petInfo,
					action.payload.wizard.petFrequency,
					normalizedPlans,
				)

			const freshSubmissions: Record<number, EnrollmentStatus> = {}

			plans.forEach((plan) => {
				freshSubmissions[plan.benefitPlanId] = EnrollmentStatus.ENROLLED
			})
			getDeclinedPlans(action.payload.wizard).forEach((planId: number) => {
				const cancelReason = action.payload.wizard.planSelections[planId].cancelReason
				freshSubmissions[planId] = EnrollmentStatus.DECLINED
				if (cancelReason) {
					trackEvent(reactPlugin, events.benefitEnrollment.cancelled, {
						cancelReason: cancelReason,
						planType: normalizedPlans[planId].benefitPlanType,
					})
				}
			})
		},
		submittingEnrollment(state: EnrollmentsState): void {
			state.enrollmentSubmitError = []
			state.isSubmittingEnrollment = LOADING
		},
	},
})

// Extract the action creators object and the reducer
const { actions, reducer } = enrollmentsSlice
// Export the reducer and actions
export { actions, reducer as enrollments }

export const submitEnrollmentV2 =
	(): AppThunkAction =>
	async (dispatch, getState): Promise<void> => {
		const state = getState()
		const name = `${state.user.userProfile.firstName} ${state.user.userProfile.lastName}`
		const wizard: EnrollmentWizardState = state.enrollmentWizard
		const benefitPlans = state.benefitPlans.availablePlans
		dispatch(actions.submittingEnrollment())
		const enrollmentText: string = getDisclaimerText(name, state.site.siteInfo?.organizationName ?? '')
		const isTermsAccepted: boolean = wizard.acknowledgedFinalDisclaimer

		const pendingTrustmarkEnrollments = state.enrollmentWizard.trustmarkEnrollment.plans
		let trustmarkCoverageTiers: string[] | undefined = undefined
		if (pendingTrustmarkEnrollments) {
			trustmarkCoverageTiers = pendingTrustmarkEnrollments.map((e) => e.coverageTier)
		}

		try {
			const { confirmationId: confirmation, additionalEvents } = await EnrollmentService.submitEnrollmentV2(
				wizard.enrollmentId[wizard.event as EnrollmentEvent],
				isTermsAccepted ? enrollmentText : '',
				trustmarkCoverageTiers,
			)

			if (
				pendingTrustmarkEnrollments &&
				pendingTrustmarkEnrollments.length > 0 &&
				!additionalEvents?.find((e) => e === 'TRUSTMARK_ENROLLMENT_SUBMISSION_FAILED')
			) {
				trackEvent(reactPlugin, events.benefitsWizard.trustmark.enrollmentCompleted)
			}

			dispatch(actions.submitEnrollmentSuccessV2({ benefitPlans, wizard: { ...wizard, confirmation } }))
		} catch (error) {
			appInsights.trackException({
				exception: error as Error,
				properties: {
					Caller: 'submitEnrollmentV2',
					EnrollmentId: wizard.enrollmentId[wizard.event as EnrollmentEvent] ?? '',
					Event: wizard.event,
					OrganizationId: state.site.siteInfo?.organizationId ?? '',
				},
			})

			dispatch(actions.submitEnrollmentError(error as AxiosError<any>))
			throw error
		}
	}

export const getRecentUserEnrollments =
	(und3484: boolean): AppThunkAction =>
	async (dispatch): Promise<PayloadAction<Error | RecentUserEnrollmentRes[] | RecentElection[]>> => {
		dispatch(actions.loadingRecentUserEnrollments())

		try {
			const recentUserEnrollments: RecentUserEnrollmentRes[] | RecentElection[] | any = und3484
				? await BenefitElectionsService.GetRecentElections()
				: await WorkerService.getRecentUserEnrollments()
			und3484
				? dispatch(actions.getRecentElectionsSuccess(recentUserEnrollments))
				: dispatch(actions.getRecentUserEnrollmentsSuccess(recentUserEnrollments))

			return recentUserEnrollments
		} catch (error) {
			appInsights.trackException({
				exception: error as Error,
				properties: { Caller: 'getRecentUserEnrollments' },
			})

			return dispatch(actions.getRecentUserEnrollmentsError(error as Error))
		}
	}

type GetOverviewParams = {
	und3484: boolean
	shouldClearLists?: boolean
}

/**
 * Fetch enrollment overview data from api
 * @param shouldClearLists used to clear enrolled and waived arrays
 *  from store when enrollments are cleared via my-profile.tsx
 * @returns UserEnrollmentRes[]
 */
export const getUserEnrollmentOverview =
	({ und3484, shouldClearLists = false }: GetOverviewParams): AppThunkAction =>
	async (dispatch, getState): Promise<PayloadAction<Error | RecentUserEnrollmentRes[]>> => {
		const { benefitPlans } = getState()
		dispatch(actions.loadingUserEnrollmentOverview())

		try {
			let userEnrollmentOverview: UserElection[] | UserEnrollmentRes[] | BenefitElectionsOverviewResponse | any

			if (shouldClearLists) {
				// clear benefits wizard
				dispatch(actions.resetSubmitStatus())
			}

			if (und3484) {
				userEnrollmentOverview = await BenefitElectionsService.getUserElections()
				const beneficiaries = userEnrollmentOverview.beneficiaries.map((b: Beneficiary) => ({
					...b,
					clientSideId: self.crypto.randomUUID(),
				}))
				const parsedElectionData = parseElectionData(userEnrollmentOverview.elections)
				dispatch(
					actions.getUserElectionsSuccess({
						beneficiaries,
						parsedElectionData,
						workerEmailAddress: userEnrollmentOverview.workerEmailAddress,
					}),
				)
			} else {
				userEnrollmentOverview = await EnrollmentService.getEnrollmentOverview()
				const parsedOverviewData = parseOverviewData(userEnrollmentOverview, benefitPlans.availablePlans)
				dispatch(actions.getUserEnrollmentOverviewSuccess(parsedOverviewData))
			}

			return userEnrollmentOverview
		} catch (error) {
			appInsights.trackException({
				exception: error as Error,
				properties: { Caller: 'getUserEnrollmentOverview' },
			})

			return dispatch(actions.getRecentUserEnrollmentsError(error as Error))
		}
	}

const defaultQLEEvents: QLEEventsList = [
	'Benefit Eligibility Status Change',
	'Birth of a Child',
	'Death of a Spouse or Dependent',
	'Divorce',
	'Marriage',
]
export const getQLEEvents =
	(employerAccountCode: string): AppThunkAction =>
	async (dispatch): Promise<QLEEventsList> => {
		dispatch(actions.loadingQLEEvents())

		let events: QLEEventsList = defaultQLEEvents

		try {
			const response = await EnrollmentService.getQLEEvents(employerAccountCode)

			if (response) events = response
		} catch (e) {
			appInsights.trackException({
				exception: e as Error,
				properties: { Caller: 'defaultQLEEvents', EmployerAccountCode: employerAccountCode },
			})
			dispatch(actions.getQLEEventsError(e as Error))
		} finally {
			dispatch(actions.getQLEEventsSuccess(events))

			// eslint-disable-next-line no-unsafe-finally
			return events
		}
	}

export const saveQleSubmission =
	(props: QLESubmissionProps): AppThunkAction =>
	async (dispatch): Promise<void> => {
		try {
			if (props.enrollmentEvent === EnrollmentEvent.NEWHIRE) {
				await EnrollmentService.VerifyNewHireDateForStartingEnrollment()
			}

			dispatch(actions.setQleSubmissions(props))
		} catch (error) {
			const e = error as any
			appInsights.trackException({ error: e, properties: { Caller: 'saveQleSubmission' } })

			// incoming error is an axios response
			if (e && e.response && e.response.data) {
				throw e.response.data
			} else {
				throw e
			}
		}
	}
