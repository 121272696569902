import { Checkbox, FormControlLabel } from '@mui/material'
import { CTAButton } from 'components/buttons'
import GenericModalV2 from 'components/generic-modal-v2'
import { TextV2 } from 'components/text-v2'
import { _useCaptureEvent } from 'hooks/analytics'
import { User } from 'oidc-client'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { IMyProfileUser, MyProfileUser, getUserManagerSelector, myProfileSelector } from 'store/user/selectors'
import { updateUserProfile } from 'store/user/slice'
import { useReduxDispatch } from 'store/util-types/types'
import { useIsPaychex } from 'utils/url'
import { LocalStore } from 'utils/utils'
import styles from './opt-in-modal.module.scss'

const OptInModal = () => {
	const dispatch = useReduxDispatch()
	const isPaychex = useIsPaychex()
	const capture = _useCaptureEvent()

	const user: MyProfileUser = useSelector(myProfileSelector)
	const userManager = useSelector(getUserManagerSelector)

	const [optInRequiredModal, setOptInRequiredModal] = useState(true)
	const [canContact, setCanContact] = useState(true)

	const getProfileUpdates = useCallback(
		(updatedUser: IMyProfileUser): Promise<MyProfileUser> => dispatch(updateUserProfile(updatedUser, '')),
		[dispatch],
	)

	const handleClose = () => {
		updateProfile()
	}
	const handleCheck = (): void => setCanContact((prevState): boolean => !prevState)

	const updateProfile = async (): Promise<void> => {
		/**
		 * We do not allow Paychex users to update any of the data
		 * contained in the form of this page EXCEPT for the canContact
		 * boolean. As a result we need to hide the phone # input to prevent
		 * the phone # validation flow and disable the zip code field. We do not
		 * want to trigger any checks on the Profile api for the phone #
		 * nor do we want to update any values we have here for the user,
		 * so we just send an empty string for the phone # as seen below
		 */
		const updatedUser: IMyProfileUser = {
			canContact,
			email: user.email,
			firstName: user.firstName,
			isDiscountsOnly: user.isDiscountsOnly,
			lastName: user.lastName,
			phoneNumber: isPaychex ? '' : user.phoneNumber,
			zipCode: user.zipCode,
		}
		getProfileUpdates(updatedUser).then(async (res): Promise<void> => {
			capture({
				actionType: 'click',
				elementName: 'opt-in modal',
				elementType: 'modal',
				pageName: '',
			})
			const tmpUser: User = (await userManager.getUser()) as User
			tmpUser.profile = { ...tmpUser.profile, ...res }
			await userManager.storeUser(tmpUser)
			LocalStore.remove('usedEmailClaimForRegistration')
			setOptInRequiredModal(false)
		})
	}

	return (
		<GenericModalV2
			hideTitle
			disableBackdropClick
			disableEscapeKeyDown
			hideCloseIcon
			showModal={optInRequiredModal}
			onClose={handleClose}
		>
			<div className={styles['opt-in-content']}>
				<TextV2 variant='h4'>Hi {user.firstName}!</TextV2>
				<TextV2 variant='h5'>We&apos;re glad you&apos;re here.</TextV2>
				<TextV2 variant='h5'>
					Would you like to receive marketing emails about new products, benefits, and special offers?
				</TextV2>
				<FormControlLabel label='Yes, opt-in!' control={<Checkbox onChange={handleCheck} checked={canContact} />} />
				<CTAButton id='product-page-confirm-close' size='small' onClick={handleClose}>
					Submit
				</CTAButton>
			</div>
		</GenericModalV2>
	)
}

export default OptInModal
