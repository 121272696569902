/* eslint-disable sort-keys */
import { Environments } from 'utils/env'
import { SiteEnvironment } from './types'

const options = [
	{ value: 'int', label: 'Int' },
	{ value: 'dev', label: 'Dev' },
	// { value: 'test', label: 'UAT' },
	// { value: '', label: 'Prod' },
]

const tenantOptions = [
	{ value: 'wawa', label: 'Wawa' },
	{ value: 'advantagesolutions', label: 'Advantage Solutions' },
	{ value: 'amita', label: 'Adventist Midwest Health (AMITA)' },
	{ value: 'akumin', label: 'Akumin' },
	{ value: 'ahcs', label: 'Alliance Healthcare' },
	{ value: 'alion', label: 'Alion Science and Technology Corporation (ALION)' },
	{ value: 'adp-atlanticare', label: 'Atlanticare' },
	{ value: 'aptiv', label: 'Aptiv' },
	{ value: 'myascension', label: 'Ascension - Benefits Eligible' },
	{ value: 'cmc', label: 'Community Medical Centers' },
	{ value: 'cvs', label: 'CVS Health' },
	{ value: 'guidehousedigital', label: 'Dovel Technologies' },
	{ value: 'epri', label: 'EPRI' },
	{ value: 'ferguson', label: 'Ferguson' },
	{ value: 'adp-franklinelectric', label: 'Franklin Electric' },
	{ value: 'ikea', label: 'Ikea' },
	{ value: 'iuhealth', label: 'IU Health' },
	{ value: 'mgbrigham', label: 'Mass General Brigham' },
	{ value: 'msu', label: 'Michigan State University (MSU)' },
	{ value: 'moffitt', label: 'Moffitt Cancer Center' },
	{ value: 'ochsner', label: 'Ochsner Health' },
	{ value: 'orix', label: 'Orix' },
	{ value: 'paychex', label: 'Paychex' },
	{ value: 'pfizer', label: 'Pfizer' },
	{ value: 'adp-raymond', label: 'Raymond Corp' },
	{ value: 'slcounty', label: 'Salt Lake County' },
	{ value: 'ms-soi', label: 'State of Illinois' },
	{ value: 'twu', label: "Texas Woman's University" },
	{ value: 'adp-tillys', label: 'Tillys' },
	{ value: 'towerhealth', label: 'Tower Health' },
	{ value: 'ms-toyota', label: 'Toyota Motor North America' },
	{ value: 'temple', label: 'Temple Health (TUHS)' },
	{ value: 'tuedu', label: 'Temple University (TUEDU)' },
	{ value: 'ms-idaho', label: 'University of Idaho' },
	{ value: 'upenn-uphs', label: 'University of Pennsylvania Health System' },
	{ value: 'universityofpittsburgh', label: 'University of Pittsburgh' },
	{ value: 'universityofrochester', label: 'University of Rochester' },
	{ value: 'uuhc', label: 'University of Utah Health' },
]

export function PreviewEnvironmentSelectorForm({ onSubmit }: { onSubmit: (site: SiteEnvironment) => void }) {
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()

				const data = new FormData(e.target as HTMLFormElement)

				const object: {
					env: string
					tenant: string
				} = {
					env: '',
					tenant: '',
				}
				data.forEach((value, key) => (object[key] = value))

				onSubmit({
					env: object.env as Environments,
					subdomain: object.tenant,
				})
			}}
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
				gap: '1rem',
			}}
		>
			<div style={{ display: 'flex', gap: '1rem' }}>
				<label htmlFor='env'>Environment</label>
				<select name='env' id='env'>
					{options.map((v) => (
						<option key={v.value} value={v.value}>
							{v.label}
						</option>
					))}
				</select>
			</div>
			<div style={{ display: 'flex', gap: '1rem' }}>
				<label htmlFor='tenant'>Tenant</label>
				<select name='tenant' id='tenant'>
					{tenantOptions.map((v) => (
						<option key={v.value} value={v.value}>
							{v.label}
						</option>
					))}
				</select>
			</div>
			<button type='submit'>Submit</button>
		</form>
	)
}
