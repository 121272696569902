/* eslint-disable newline-per-chained-call */
import { isDate, parse } from 'date-fns'
import { CHILD, PartialDependent } from 'types/dependent'
import { Gender } from 'types/genders'
import { phoneRegex, socialSecurityNumberRegex, ssnBlacklist } from 'utils/validation-utils'
import * as yup from 'yup'

export type PersonalInfo = {
	address: PersonalInfoAddress
	birthDate: string
	phoneNumber: string
	gender: Gender
	email: string
	socialSecurityNumber: string
}

export type PersonalInfoAddress = {
	city: string
	state: string
	streetOne: string
	streetTwo: string
	zipCode: string
}

export type DependentInfo = {
	birthDate: Date | string
	firstName: string
	gender: string
	lastName: string
	relationship: string
	socialSecurityNumber: string
}

export const personalInitState: PersonalInfo = {
	address: {
		city: '',
		state: '',
		streetOne: '',
		streetTwo: '',
		zipCode: '',
	},
	birthDate: '',
	email: '',
	gender: Gender.EMPTY,
	phoneNumber: '',
	socialSecurityNumber: '',
}

export const dependentInitState: DependentInfo = {
	birthDate: '',
	firstName: '',
	gender: '',
	lastName: '',
	relationship: '',
	socialSecurityNumber: '',
}

const getAddressFieldsShape = (requireAddressFields: boolean, shouldShowAdditionalInfoFields) => {
	if (requireAddressFields && shouldShowAdditionalInfoFields) {
		return {
			city: yup.string().max(50).required('City is required'),
			state: yup.string().required('State is required'),
			streetOne: yup.string().max(255).required('Street Address Line 1 is required'),
			streetTwo: yup.string().max(255).notRequired().nullable(),
			zipCode: yup.string().min(5).max(5).required(),
		}
	}

	return {
		city: yup.string().max(50).notRequired(),
		state: yup.string().notRequired(),
		streetOne: yup.string().max(255).notRequired(),
		streetTwo: yup.string().max(255).notRequired().nullable(),
		zipCode: yup.string().max(5).notRequired().nullable(),
	}
}

const getBirthDateSchema = (shouldShowAdditionalInfoFields, requireBirthDateField) => {
	if (shouldShowAdditionalInfoFields) {
		return yup
			.date()
			.required()
			.transform((value, originalValue) =>
				isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy-MM-dd', new Date()),
			)
			.min(new Date(0, 0, 2))
			.max(new Date())
	} else if (requireBirthDateField) {
		return yup.string().required('Date of Birth is required')
	} else {
		return yup.string().notRequired().nullable()
	}
}

export const ssnValidation = yup
	.string()
	.min(11, 'Invalid Social Security Number')
	.max(11, 'Invalid Social Security Number')
	.matches(socialSecurityNumberRegex, 'Invalid Social Security Number')
	.notOneOf(ssnBlacklist, 'Invalid Social Security Number')
	.required('Social security number is required')

export const personalInfoValidationSchema = (
	requireSsn: boolean,
	per: Record<string, unknown>,
	requireAddressFields: boolean,
	requireBirthDateField: boolean,
	requireGenderField: boolean,
	shouldShowAdditionalInfoFields: boolean,
): boolean => {
	const schema = yup.object().shape({
		address: yup
			.object()
			.shape(getAddressFieldsShape(requireAddressFields && shouldShowAdditionalInfoFields, getAddressFieldsShape)),
		birthDate: getBirthDateSchema(shouldShowAdditionalInfoFields, requireBirthDateField),
		email: yup.string().email().required('Email Address is required'),
		gender:
			requireGenderField && shouldShowAdditionalInfoFields
				? yup.string().required('Gender is required')
				: yup.string().notRequired(),
		phoneNumber: yup.string().matches(phoneRegex).required('Phone number is required'),
		socialSecurityNumber: requireSsn ? ssnValidation : yup.string().notRequired().nullable(),
	})

	return schema.isValidSync(per)
}

export const personalInfoSchema = (
	requireSsn: boolean,
	requireAddressFields: boolean,
	requireBirthDateField: boolean,
	requireGenderField: boolean,
	shouldShowAdditionalInfoFields: boolean,
) =>
	yup.object().shape({
		address: yup
			.object()
			.shape(getAddressFieldsShape(requireAddressFields && shouldShowAdditionalInfoFields, getAddressFieldsShape)),
		birthDate: getBirthDateSchema(shouldShowAdditionalInfoFields, requireBirthDateField),
		email: yup.string().email().required('Email Address is required'),
		gender:
			requireGenderField && shouldShowAdditionalInfoFields
				? yup.string().required('Gender is required')
				: yup.string().notRequired(),
		phoneNumber: yup.string().matches(phoneRegex).required('Phone number is required'),
		socialSecurityNumber: requireSsn ? ssnValidation : yup.string().notRequired().nullable(),
	})

// should ignore child age?
export const depValidationSchema = (requireSsn?: boolean, shouldIgnoreChildAge?: boolean) =>
	yup.object().shape({
		birthDate: !shouldIgnoreChildAge
			? yup.date().required('Date of birth is required')
			: yup.date().notRequired().nullable(),
		firstName: yup.string().required('First name is required'),
		gender: yup.string().oneOf([Gender.DECLINED, Gender.MALE, Gender.FEMALE]).required('Gender is required'),
		lastName: yup.string().required('Last name is required'),
		socialSecurityNumber: requireSsn
			? yup.string().min(11).max(11).required('Social Security Number is required')
			: yup.string().notRequired().nullable(),
	})

export const isDepValid = (dep: PartialDependent, requireSsn?: boolean, shouldIgnoreChildAge?: boolean): boolean => {
	const isValidAge = shouldIgnoreChildAge ? true : dep?.isOfAge ?? false
	const isValidDep = depValidationSchema(requireSsn, dep.relationship === CHILD && shouldIgnoreChildAge).isValidSync({
		birthDate: dep.birthDate,
		firstName: dep.firstName,
		gender: dep.gender,
		lastName: dep.lastName,
		socialSecurityNumber: dep.socialSecurityNumber,
	})

	return isValidDep && isValidAge
}
