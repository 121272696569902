export const SPOUSE = 'Spouse'
export const CHILD = 'Child'

export type Relationship = typeof SPOUSE | typeof CHILD
export interface Dependent {
	birthDate: Date | string
	dependentId: string
	firstName: string
	gender: string
	id?: number
	isActive: boolean
	isOfAge?: boolean
	isDisabled?: boolean
	isStudent?: boolean
	isTobacco?: boolean
	lastName: string
	middleName: string | null
	nameSuffix: string | null
	phone: string | null
	relationship: Relationship
	socialSecurityNumber: string | null
}

export type DependentList = Array<Dependent>
export type PartialDependent = Pick<
	Dependent,
	| 'dependentId'
	| 'firstName'
	| 'gender'
	| 'isDisabled'
	| 'isStudent'
	| 'isOfAge'
	| 'isTobacco'
	| 'lastName'
	| 'relationship'
	| 'birthDate'
	| 'socialSecurityNumber'
>
export type PartialDependentList = Array<PartialDependent>

export const sortDependentsByRelationship = (a: PartialDependent, b: PartialDependent) => {
	if (a.relationship === SPOUSE && b.relationship === CHILD) {
		return -1
	}

	if (a.relationship === CHILD && b.relationship === SPOUSE) {
		return 1
	}

	return 0
}
