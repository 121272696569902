import { EnrolledTrustmarkStatuses, LTCEnrollmentResponse } from 'api/benefit-elections'
import { useTrustmarkEnrollment } from 'api/query-hooks'
import { useEnrollmentImplementationValidation } from 'feature/benefit-plan/utils'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { useCallback, useMemo } from 'react'
import { BenefitPlanV2, EnrollmentImplementation } from 'store/benefit-plans/types'
import { match } from 'ts-pattern'
import { PipeFuncReturn } from '.'

export function useThirdPartyEnrollmentData(listsOfPlans: BenefitPlanV2[][]) {
	const { checkExperience } = useEnrollmentImplementationValidation()

	const trustmarkQuery = useTrustmarkEnrollment({
		enabled: !!listsOfPlans.find((list) => {
			return !!list.find((plan) => checkExperience(plan, EnrollmentImplementation.trustmark))
		}),
	})

	// apply updates, streams in data when it arrives
	const results = useMemo(
		() =>
			listsOfPlans.map((plans) => {
				return plans.map((plan) => {
					return match(plan)
						.when(
							(p) => checkExperience(p, EnrollmentImplementation.trustmark),
							(p) => (trustmarkQuery.data ? applyTrustmarkData(p, trustmarkQuery.data) : { ...p }),
						)
						.otherwise(() => ({ ...plan }))
				})
			}),
		[checkExperience, listsOfPlans, trustmarkQuery.data],
	)

	return {
		/**
		 * allow users to subscribe to loading statses they care about
		 */
		isLoading: {
			trustmark: trustmarkQuery.isLoading,
		},
		results,
	}
}

function applyTrustmarkData(plan: BenefitPlanV2, data: LTCEnrollmentResponse) {
	const isEnrolled = !!data.pxResults?.find((i) => EnrolledTrustmarkStatuses.includes(i.applicationStatus))

	const planCopy = { ...plan }
	planCopy.isEnrolled = plan.isEnrolled || isEnrolled
	planCopy.enrollmentStatus = planCopy.isEnrolled ? EnrollmentStatus.ENROLLED : plan.enrollmentStatus

	return planCopy
}

export function usePipeThirdPartyData(config: {
	sourceListsOfPlans: BenefitPlanV2[][]
}): PipeFuncReturn<BenefitPlanV2[][]> {
	const { checkExperience } = useEnrollmentImplementationValidation()

	const trustmarkQuery = useTrustmarkEnrollment({
		enabled: !!config.sourceListsOfPlans.find((list) => {
			return !!list.find((plan) => checkExperience(plan, EnrollmentImplementation.trustmark))
		}),
	})

	const callback = useCallback(
		(listOfPlans: BenefitPlanV2[][]) => {
			return listOfPlans.map((plans) => {
				return plans.map((plan) => {
					return match(plan)
						.when(
							(p) => checkExperience(p, EnrollmentImplementation.trustmark),
							(p) => (trustmarkQuery.data ? applyTrustmarkData(p, trustmarkQuery.data) : { ...p }),
						)
						.otherwise(() => ({ ...plan }))
				})
			})
		},
		[checkExperience, trustmarkQuery.data],
	)

	return useMemo(
		() => ({
			apply: callback,
			subscription: {
				data: trustmarkQuery.data,
				isLoading: trustmarkQuery.isLoading || trustmarkQuery.isFetching,
			},
		}),
		[callback, trustmarkQuery.data, trustmarkQuery.isFetching, trustmarkQuery.isLoading],
	)
}
