import { Theme } from './theme'

export interface SiteInfoResponse {
	organizationUnitId: string
	organizationUnitName: string
	organizationId: string
	organizationName: string
	partnerId: string
	partnerName: string
	status: SiteStatus
	properties: Properties
}

export enum DisplayLocation {
	Never = 'never',
	UnauthHome = 'unauth-home',
	AuthHome = 'auth-home',
	Always = 'always',
}

export function canDisplayUnauth(location: DisplayLocation): boolean {
	return location === DisplayLocation.Always || location === DisplayLocation.UnauthHome
}

export function canDisplayAuth(location: DisplayLocation): boolean {
	return location === DisplayLocation.Always || location === DisplayLocation.AuthHome
}

export type UnmappedProperties = {
	'alert-color'?: string
	'background-color'?: string
	'background-image'?: string
	'brand-color'?: string
	'cta-color'?: string
	'customer-care-email'?: string
	'customer-care-phone'?: string
	'demo-my-deductions'?: string
	'eligiblity-error-message'?: string
	'employee-descriptor'?: string
	'enrollment-newhire-count'?: string
	'enrollment-qle-count'?: string
	'exclude-cs-phone-number'?: string
	'foreground-text-color'?: string
	'has-auto-quoting'?: string
	'has-discounts'?: string
	'has-local-discounts'?: string
	'has-voluntary-benefits'?: string
	'has-payroll'?: string
	'default-redirect-url'?: string
	keywords?: string
	'logo-image'?: string
	'ppds-enabled'?: string
	'use-ppds-auth'?: string
	'employeeId-type'?: string
	'ppds-tenant-code'?: string
	'primary-subdomain'?: string
	'show-discounts-grid'?: string
	'site-benefits-description'?: string
	'site-effective-date'?: string
	'site-title-external'?: string
	'discounter-blacklist'?: string
	url?: string
	theme?: Theme
	'use-default-colors'?: string
}

export type MappedProperties = {
	alertColor?: string
	backgroundColor: string
	backgroundImage: string
	brandColor?: string
	ctaColor?: string
	customerCareEmail?: string
	customerCarePhone?: string
	copy?: string
	defaultRedirectUrl?: string
	disclaimerDisplayLocation?: DisplayLocation
	disclaimerText?: string
	eligibilityErrorMessage: string
	employeeIdType?: string
	employeeDescriptor?: string
	enrollmentNewHireCount: number | null
	enrollmentQLECount: number | null
	excludeCSPhoneNumber?: boolean
	hasAutoQuoting?: boolean
	hasDecisionSupport?: boolean
	hasDiscounts?: boolean
	hasLocalDiscounts?: boolean
	hasVoluntaryBenefits?: boolean
	hasPayroll?: boolean
	headline?: string
	keywords?: string
	logoImage?: string
	ppdsEnabled?: boolean
	ppdsTenantCode?: string
	primarySubdomain?: string
	siteBenefitsDescription?: string
	siteEffectiveDate?: string
	siteTitleExternal?: string
	ssoAuthUrl?: string
	ssoEnabled?: boolean
	showDiscountsGrid?: boolean
	discounterBlacklist?: Array<string>
	url?: string
	usePPDSAuth?: boolean
}

export enum SiteStatus {
	Inactive = 'Inactive',
	Active = 'Active',
	Demo = 'Demo',
}

export type PageSelectorReturnType = {
	backgroundColor: string
	backgroundImage: string
	customerCareEmail: string
	customerCarePhone: string
	employeeDescriptor: string
	headerColor: string
	primaryColor: string
	siteName: string
}

export type CustomerCareInfo = {
	customerCareEmail: string
	customerCarePhone: string
}

export class Properties implements MappedProperties {
	alertColor?: string
	backgroundColor: string = ''
	backgroundImage: string = ''
	brandColor: string = ''
	clientIntroHomepageText?: string
	ctaColor?: string
	customerCareEmail?: string
	customerCarePhone?: string
	copy?: string
	defaultRedirectUrl?: string
	demoMyDeductions?: string
	disclaimerDisplayLocation: DisplayLocation = DisplayLocation.Never
	disclaimerText?: string
	eligibilityErrorMessage: string =
		'Employee record not found. Please view the Help menu option for additional assistance.'
	employeeIdInfo?: string = ''
	employeeIdType?: string = 'EmployeeId'
	employeeDescriptor?: string
	enrollmentNewHireCount: number | null = null
	enrollmentQLECount: number | null = null
	excludeCSPhoneNumber?: boolean
	foregroundTextColor?: string = 'var(--neutral-neutral-20)'
	hasAutoQuoting?: boolean
	hasDecisionSupport?: boolean
	hasDiscounts?: boolean
	hasLocalDiscounts?: boolean
	hasVoluntaryBenefits?: boolean
	hasPayroll?: boolean
	headline?: string
	headerColor?: string
	keywords?: string
	loginPageText: string | null = null
	logoImage: string = ''
	loginSubheader: string | null = 'Your employment perks are one click away.'
	mumboJumboDisplayLocation: DisplayLocation = DisplayLocation.Never
	mumboJumboText: string | null = null
	ppdsEnabled?: boolean
	ppdsTenantCode?: string
	primarySubdomain?: string
	registrationPageText: string | null = null
	showDiscountsGrid?: boolean
	siteBenefitsDescription?: string
	siteEffectiveDate?: string
	siteTitleExternal?: string
	ssoAuthUrl?: string
	ssoEnabled?: boolean
	url?: string
	usePPDSAuth?: boolean
	discounterBlacklist?: Array<string>
	theme?: Theme
	useDefaultColors?: boolean

	constructor(data?: UnmappedProperties) {
		if (!data) return

		this.alertColor = convertColorToHexColor(data['alert-color'])
		this.backgroundColor = convertColorToHexColor(data['background-color']) ?? ''
		this.backgroundImage = getImageUrl(<string>data['background-image']) ?? ''
		this.brandColor = convertColorToHexColor(data['brand-color']) ?? ''
		this.ctaColor = convertColorToHexColor(data['cta-color'])
		this.customerCareEmail = data['customer-care-email']
		this.customerCarePhone = data['customer-care-phone']
		this.copy =
			data['copy'] ??
			'Take advantage of these affordable benefit choices to ' +
				'give you peace of mind and help protect your family’s future!'
		this.defaultRedirectUrl = data['default-redirect-url']
		this.demoMyDeductions = data['demo-my-deductions']
		this.disclaimerText = data['disclaimer-text']
		this.disclaimerDisplayLocation = data['disclaimer-display-location']
		this.eligibilityErrorMessage = data['eligiblity-error-message']
			? data['eligiblity-error-message']
			: this.eligibilityErrorMessage
		this.employeeDescriptor = data['employee-descriptor']
		this.enrollmentNewHireCount = data['enrollment-newhire-count'] ? parseInt(data['enrollment-newhire-count']) : null
		this.enrollmentQLECount = data['enrollment-qle-count'] ? parseInt(data['enrollment-qle-count']) : null
		this.excludeCSPhoneNumber =
			data['exclude-cs-phone-number'] === '1' || data['exclude-cs-phone-number']?.toLowerCase() === 'true'
		this.foregroundTextColor = convertColorToHexColor(data['foreground-text-color']) ?? 'var(--neutral-neutral-20)'
		this.hasAutoQuoting = data['has-auto-quoting'] === '1'
		this.hasDecisionSupport = data['has-decision-support'] === '1'
		this.hasDiscounts = data['has-discounts'] === '1'
		this.hasLocalDiscounts = data['has-local-discounts'] === '1'
		this.hasVoluntaryBenefits = data['has-voluntary-benefits'] === '1'
		this.hasPayroll = data['has-payroll'] === '1'
		this.headline = data['headline'] ?? 'Now is the Time to Enroll!'
		this.keywords = data.keywords
		this.loginPageText = data['login-page-text']
		this.logoImage = getImageUrl(<string>data['logo-image'])
		this.loginSubheader = data['login-subheader'] ?? 'Your employment perks are one click away.'
		this.mumboJumboDisplayLocation = data['mumbo-jumbo-display-location']
		this.mumboJumboText = data['mumbo-jumbo-text']
		this.ppdsEnabled = data['ppds-enabled'] === '1' || data['ppds-enabled']?.toLowerCase() === 'true'
		this.ppdsTenantCode = data['ppds-tenant-code']
		this.primarySubdomain = data['primary-subdomain']
		this.registrationPageText = data['registration-page-text']
		this.siteBenefitsDescription = data['site-benefits-description']
		this.siteEffectiveDate = data['site-effective-date']
		this.siteTitleExternal = data['site-title-external']
		this.ssoAuthUrl = data['sso-auth-url']
		this.ssoEnabled = data['sso-enabled'] === '1'
		this.showDiscountsGrid = data?.['show-discounts-grid'] === '1'
		this.url = data.url
		this.discounterBlacklist = data['discounter-blacklist']?.split(',')
		this.employeeIdType = data['employeeId-type'] ?? 'EmployeeId'
		this.usePPDSAuth = data['use-ppds-auth'] === '1'
		this.employeeIdInfo = data['employee-id-info']
		this.theme = 'primary'
		this.useDefaultColors = data['use-default-colors'] === '1'
		this.clientIntroHomepageText = data['client-intro-homepage-text']
	}

	getEmployeeIdInputType(): string {
		if (!this || this.employeeIdType === undefined) {
			return 'text'
		} else if (this.employeeIdType.toLowerCase().includes('date')) {
			return 'date'
		} else if (this.employeeIdType.toLowerCase().includes('email')) {
			return 'email'
		} else {
			return 'text'
		}
	}
	getEmployeeIdLabel(): string {
		// TODO: clean up default check from admin side to allow for straight replacement
		return this.employeeIdType?.toLowerCase() === 'employeeid' ? 'Employee ID' : this.employeeIdType ?? 'Employee ID'
	}

	getEmployeeIdLength(): number {
		if (!this || this.employeeIdType === undefined) {
			return 20
		} else if (this.employeeIdType.toLowerCase().includes('email')) {
			return 50
		} else {
			return 20
		}
	}

	getPageSelectorReturnType(): PageSelectorReturnType {
		return {
			backgroundColor: this.backgroundColor ?? '',
			backgroundImage: this.backgroundImage ?? '',
			customerCareEmail: this.customerCareEmail ?? '',
			customerCarePhone: this.customerCarePhone ?? '',
			employeeDescriptor: this.employeeDescriptor ?? '',
			headerColor: this.headerColor ?? '',
			primaryColor: this.brandColor ?? '',
			siteName: this.siteTitleExternal ?? '',
		}
	}

	getCustomerCareInfo(): CustomerCareInfo {
		return {
			customerCareEmail: this.customerCareEmail ?? '',
			customerCarePhone: this.customerCarePhone ?? '',
		}
	}
}

const getImageUrl = (jsonString: string): string => {
	try {
		return JSON.parse(jsonString)['Item1']
	} catch (e) {
		return ''
	}
}

const convertColorToHexColor = (color: string | undefined): string | undefined => {
	if (!color) return undefined
	if (color.includes('#')) {
		const splitColor = color.split('#')

		return `#${splitColor[splitColor.length - 1]}`
	}

	return `#${color}`
}
