import { useFlags } from 'launchdarkly-react-client-sdk'
import { isTrustmarkLTC } from 'pages/trustmark-life/utils'
import { useCallback } from 'react'
import { BenefitPlanV2, EnrollmentImplementation } from 'store/benefit-plans/types'
import { _isPetExperience, isLibertyAutoQuoting, isSingleTierProduct } from './parse-form-experience'

export const useEnrollmentImplementationValidation = () => {
	const { b4b4465_enrollment_methods } = useFlags()

	return {
		checkExperience: useCallback(
			(plan: BenefitPlanV2, implementation: EnrollmentImplementation | null) => {
				if (!b4b4465_enrollment_methods) {
					if (implementation === EnrollmentImplementation.nwPet) return _isPetExperience(plan)
					else if (implementation === EnrollmentImplementation.singleTier) return isSingleTierProduct(plan)
					else if (implementation === EnrollmentImplementation.liberty) return isLibertyAutoQuoting(plan)
					else if (implementation === EnrollmentImplementation.trustmark) return isTrustmarkLTC(plan)
					else if (implementation === null) return true
					else return false
				}

				return validateEnrollmentImplementation(plan, implementation)
			},
			[b4b4465_enrollment_methods],
		),
	}
}
function validateEnrollmentImplementation(bp: BenefitPlanV2, implementation: EnrollmentImplementation | null) {
	return bp.enrollmentImplementation === implementation
}
