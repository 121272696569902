import { Close } from '@mui/icons-material'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	Slide,
	SlideProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import { cx } from 'class-variance-authority'
import { PropsWithChildren, ReactElement, ReactNode, Ref, forwardRef, memo } from 'react'
import styles from './generic-modal-v2.module.scss'

interface ClassesProp {
	container?: string
	title?: string
}

interface GenericModalV2Props {
	actions?: ReactNode
	disableBackdropClick?: boolean
	disableEscapeKeyDown?: boolean
	disableMobileFullScreen?: boolean
	fullWidth?: boolean
	hideCloseIcon?: boolean
	hideTitle?: boolean
	onClose: () => void
	showModal: boolean
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
	subTitle?: string | ReactNode
	title?: string | ReactNode
	dialogProps?: Partial<DialogProps>
	classes?: ClassesProp
	id?: string
}

const Transition = forwardRef(function Transition(
	props: SlideProps & { children?: ReactElement<any, any> },
	ref: Ref<unknown>,
) {
	return <Slide direction='up' ref={ref} {...props} />
})

const GenericModalV2 = (props: PropsWithChildren<GenericModalV2Props>): JSX.Element => {
	const theme = useTheme()
	const mobileFullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const handleClose = (_, reason) => {
		if (props.disableBackdropClick && reason === 'backdropClick') {
			return
		}

		props.onClose()
	}

	return (
		<Dialog
			{...props.dialogProps}
			disableEscapeKeyDown={props.disableEscapeKeyDown}
			fullWidth={props.fullWidth}
			fullScreen={props.disableMobileFullScreen ? false : mobileFullScreen}
			TransitionComponent={mobileFullScreen ? Transition : undefined}
			onClose={handleClose}
			open={props.showModal}
			maxWidth={props.maxWidth}
			className={cx(styles['generic-modal-v2'], props.classes?.container)}
			PaperProps={{ id: props.id }}
		>
			{props.hideTitle ? null : (
				<DialogTitle className={props.classes?.title}>
					<div className={styles['header']}>
						<div>
							{typeof props.title === 'string' ? (
								<Typography variant='h3'>
									<strong>{props.title}</strong>
								</Typography>
							) : (
								props.title
							)}
							{typeof props.subTitle === 'string' ? (
								<p style={{ paddingTop: 'var(--space-xl)' }}>{props.subTitle}</p>
							) : (
								props.subTitle
							)}
						</div>
						{props.hideCloseIcon ? null : (
							<IconButton
								aria-label='close'
								sx={(theme) => ({
									'@media (min-width:600px)': {
										top: -2,
									},
									color: theme.palette.grey['900'],
									position: 'absolute',
									right: -12,
									top: -4,
								})}
								onClick={props.onClose}
							>
								<Close />
							</IconButton>
						)}
					</div>
				</DialogTitle>
			)}
			<DialogContent style={{ position: 'relative' }}>
				<>
					{props.hideTitle && !props.hideCloseIcon && (
						<IconButton aria-label='close' className={styles['hidden-title-close-button']} onClick={props.onClose}>
							<Close />
						</IconButton>
					)}
					{props.children}
				</>
			</DialogContent>
			{props.actions && (
				<DialogActions className={styles['generic-modal-v2--actions-container']}>{props.actions}</DialogActions>
			)}
		</Dialog>
	)
}

GenericModalV2.defaultProps = {
	maxWidth: 'md',
}

export default memo(GenericModalV2)
