import { ArrowBackIosNew } from '@mui/icons-material'
import { Collapse, IconButton, Portal, Slide } from '@mui/material'
import { useMobileDisplay } from 'hooks/use-mobile-display'
import { MegaMenuColumn } from './mega-menu-column'
import styles from './mega-menu.module.scss'
import { MegaMenuItem } from './types'

type Props = {
	anchorRef: null | HTMLElement
	minimumFirstColumnFillSize?: number
	categoryKey?: string
	handleClose: (isSelectionMade: boolean) => void
	incomingItems: MegaMenuItem[]
	mobileLabel?: string
	numOfColumns?: number
	open: boolean
	supplementalItems?: MegaMenuItem[]
	dataTestId?: string
}

const MegaMenu = ({
	anchorRef,
	minimumFirstColumnFillSize = 4,
	handleClose,
	incomingItems,
	mobileLabel,
	numOfColumns = 4,
	open,
	supplementalItems,
	dataTestId,
}: Props) => {
	const isMobile = useMobileDisplay()

	const sortedItems = [...incomingItems].sort((a, b) => a.label.localeCompare(b.label))

	const renderColumns = () => {
		//Copy and sort the incoming array alphabetically
		const numOfItems = sortedItems.length

		//Determines if we want to fill up the front columns
		//  with items first or distribute items evenly
		const isEvenlyDistributed = numOfItems > numOfColumns * minimumFirstColumnFillSize

		// eslint-disable-next-line newline-per-chained-call
		return new Array(numOfColumns).fill(0).map((_, colNum) => {
			const addOnAmt = colNum < numOfItems % numOfColumns ? 1 : 0
			const itemsInColumn = isEvenlyDistributed
				? Math.floor(numOfItems / numOfColumns) + addOnAmt
				: minimumFirstColumnFillSize

			const columnItems = sortedItems.splice(0, itemsInColumn)

			return (
				<MegaMenuColumn
					handleClose={handleClose}
					hideLeftBorder={colNum === numOfColumns - 1}
					isSupplementalItems={false}
					items={columnItems}
					key={self.crypto.randomUUID()}
					typographyProps={{
						component: 'p',
						variant: 'body1',
					}}
				/>
			)
		})
	}

	return isMobile ? (
		<Portal container={anchorRef}>
			<Slide in={open} direction='left'>
				<div className={styles['mega-menu-mobile-container']} data-testid={dataTestId}>
					<div className={styles['mega-menu-mobile-header']}>
						<IconButton className='icon-button' onClick={() => handleClose(false)} aria-label='Close menu'>
							<ArrowBackIosNew />
						</IconButton>
						<h1>{mobileLabel}</h1>
					</div>
					<hr />
					{supplementalItems && (
						<MegaMenuColumn
							handleClose={handleClose}
							hideLeftBorder={true}
							isSupplementalItems={true}
							items={supplementalItems}
							typographyProps={{
								component: 'p',
								variant: 'body1',
							}}
						/>
					)}
					{supplementalItems && <hr />}
					<MegaMenuColumn
						handleClose={handleClose}
						hideLeftBorder={true}
						isSupplementalItems={false}
						items={sortedItems}
						typographyProps={{
							component: 'p',
							variant: 'body1',
						}}
					/>
				</div>
			</Slide>
		</Portal>
	) : (
		<Portal container={anchorRef}>
			<Collapse in={open}>
				<div className={`${styles['mega-menu-container']}`} data-testid={dataTestId}>
					{supplementalItems && (
						<MegaMenuColumn
							handleClose={handleClose}
							hideLeftBorder={false}
							isSupplementalItems={true}
							items={supplementalItems}
							typographyProps={{
								component: 'p',
								variant: 'body1',
							}}
						/>
					)}
					{renderColumns()}
				</div>
			</Collapse>
		</Portal>
	)
}

export default MegaMenu
