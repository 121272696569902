import { Typography } from '@material-ui/core'
import styled from 'styled-components'

function getColor(props): string {
	if (props.override) return props.override + ' !important'
	if (props.usecta) return props.theme.ctaColor + ' !important'

	return !props.ignoreorg ? props.theme.primaryColor : ''
}

export const StyledTextV2 = styled(Typography)<any>`
	&& {
		color: ${(props): string => getColor(props)};
		text-align: ${(props): string => props.textalign};
	}
`
