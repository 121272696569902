import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch'
import { match } from 'ts-pattern'
import { FEATURED, HERO, NONE, TRENDING } from 'types/feature-level'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { Environments, getEnv } from 'utils/env'

const algoliaAppId: string = import.meta.env.VITE_ALGOLIA_APP_ID ?? ''
const algoliaApiKey: string = import.meta.env.VITE_ALGOLIA_API_KEY ?? ''
const env: string = getEnv()

export const INCLUSIVE_TENANTS_PROPERTY = 'ALL'
export const EXCLUSIVE_BLACKLIST = 'None'

const getIndex =
	(index: string) =>
	(val: string): string =>
		match(val)
			.with(Environments.INT, (e) => `${e}_${index}`)
			.with(Environments.UAT, () => `uat_${index}`) // uat env is test, we need the uat string
			.with(Environments.PROD, () => index)
			.otherwise(() => `${Environments.DEV}_${index}`)
/**
 * This function is necessary for IE11/Edge. Previous mapping isn't compatible.
 * @param env environment the user is currently in
 * @returns index to use for Algolia
 */
const getOffersIndex = getIndex('offers')
const getBenefitPlansIndex = getIndex('benefit_plans')
const getFaqsIndex = getIndex('faqs')
const getLocalDealsIndex = (): string => 'local_offers'

/**
 * Search Site Filters
 * We need some default filters to apply to the site globally. Leverage this
 * function to create a filters string to filter our parameters correctly
 */

type FilterProps = {
	showDiscounts: boolean
	siteInfo: SiteInfoResponse
	withFilterLevels: boolean
	wrp2642: boolean
}

export const getFilters =
	(withFilterLevels: boolean) =>
	(siteInfo: SiteInfoResponse, showDiscounts: boolean, wrp2642: boolean = false): string =>
		filters({
			showDiscounts,
			siteInfo,
			withFilterLevels,
			wrp2642,
		})

// Filter to check the blacklist and whitelist values
const blackWhiteListFilter = (orgId: string) =>
	`(tenantIDs: ${orgId} OR tenantIDs: ${INCLUSIVE_TENANTS_PROPERTY}) AND (NOT blacklistedTenantIDs: ${orgId})`

function filters(props: FilterProps): string {
	const { siteInfo, withFilterLevels, wrp2642 } = props

	const currentDate: number = Math.trunc(Date.now() / 1000)
	const filterArray: string[] = wrp2642
		? [`endDate >= ${currentDate}`, `startDate <= ${currentDate}`, `tenantIDs: ${siteInfo.organizationId}`]
		: [
				`endDate >= ${currentDate}`,
				`startDate <= ${currentDate}`,
				blackWhiteListFilter(siteInfo.organizationId),
				'NOT status: 0',
		  ]

	if (withFilterLevels) {
		filterArray.push(
			// eslint-disable-next-line max-len
			`(level: "${TRENDING}" OR level: "${FEATURED}" OR level: "${HERO}" OR level: "${NONE}")`,
		)
	}

	if (siteInfo.properties.discounterBlacklist != null) {
		siteInfo.properties.discounterBlacklist?.forEach((discounterId) => {
			if (discounterId !== '') {
				filterArray.push(`NOT vendor.discounterId: "${discounterId}"`)
			}
		})
	}

	return filterArray.join(' AND ')
}

/**
 * Filters for algolia to use when getting the benefit plans for the provider logos on the Unauth pages.
 * If user is logged in, use searchEligibleBenefitPlansFilters instead.
 */
export const searchSiteBenefitPlansFilters = (organizationId: string): string => {
	const siteFilter = `status > 0 AND organizationId: ${organizationId}`
	const currentDate: number = Math.trunc(Date.now() / 1000)
	const endDate = `endDate > ${currentDate}`
	const startDate = `startDate <= ${currentDate}`
	const filtersArray: string[] = [siteFilter, endDate, startDate]

	return filtersArray.join(' AND ')
}

/**
 * Algolia filters for the search page if we know the user's eligible plans, already.
 * @param organizationId Current site's organization ID
 * @param eligiblePlans Array of eligible plan IDs
 * @returns Algolia filters string
 */
export const searchEligibleBenefitPlansFilters = (organizationId: string, eligiblePlanIds: number[]): string => {
	const siteFilter = `status > 0 AND organizationId: ${organizationId}`
	const currentDateFilter: number = Math.trunc(Date.now() / 1000)
	const endDateFilter = `endDate > ${currentDateFilter}`
	const startDateFilter = `startDate <= ${currentDateFilter}`
	const eligiblePlans = eligiblePlanIds.map((id) => `benefitPlanId = ${id}`).join(' OR ')
	const eligiblePlansFilter = `(${eligiblePlans})`

	const filtersArray: string[] = [siteFilter, endDateFilter, startDateFilter, eligiblePlansFilter]

	return filtersArray.join(' AND ')
}

/**
 * Filters for algolia to retrieve an individual plan
 */
export const individualBenefitPlanFilters = (organizationId: string, id: number): string => {
	const siteFilter = `status > 0 AND organizationId: ${organizationId}`
	const currentDate: number = Math.trunc(Date.now() / 1000)
	const endDate = `endDate > ${currentDate}`
	const startDate = `startDate <= ${currentDate}`
	const benefitPlanIdFilter = `benefitPlanId = ${id}`
	const filtersArray: string[] = [siteFilter, benefitPlanIdFilter, endDate, startDate]

	return filtersArray.join(' AND ')
}

export const offersIndex: string = getOffersIndex(env)
export const benefitPlansIndex: string = getBenefitPlansIndex(env)
export const client: SearchClient = algoliasearch(algoliaAppId, algoliaApiKey)
export const rawClient: SearchIndex = client.initIndex(offersIndex)
export const benefitPlansClient: SearchIndex = client.initIndex(benefitPlansIndex)
export const faqsClient: SearchIndex = client.initIndex(getFaqsIndex(env))
export const localDealsClient: SearchIndex = client.initIndex(getLocalDealsIndex())
export const showMoreLimit = 100
