import { CircularProgress } from '@mui/material'
import { FC } from 'react'

interface InlineLoadingBtnIconProps {
	color?: string
}

const InlineLoadingBtnIcon: FC<InlineLoadingBtnIconProps> = ({
	color = 'var(--neutral-neutral-100)',
}: InlineLoadingBtnIconProps) => {
	return <CircularProgress size={14} thickness={5} style={{ color }} />
}

export default InlineLoadingBtnIcon
