export const phoneRegex = new RegExp(/^\([2-9][0-9]{2}\)\s[0-9]{3}-[0-9]{4}/)

export const socialSecurityNumberRegex: RegExp = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/)

export const ssnBlacklist = [
	'000-00-0000',
	'111-11-1111',
	'222-22-2222',
	'333-33-3333',
	'444-44-4444',
	'555-55-5555',
	'666-66-6666',
	'777-77-7777',
	'888-88-8888',
	'123-45-6789',
]
