import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { PET_PLAN_ID } from 'types/pet'

/**
 * @deprecated - Remove after b4b4465_enrollment_methods flag removal
 */
export function _isPetExperience(data: BenefitPlanV2) {
	return data.programId === PET_PLAN_ID
}

/**
 * @deprecated - Remove after b4b4465_enrollment_methods flag removal
 */
export function isSingleTierProduct(bp: BenefitPlanV2) {
	const shortTermDisabilityProductId = 'dca52a6e-a843-40ea-8323-da7e7530ac6f'
	const singleTierProducts = [shortTermDisabilityProductId]

	return bp.isNative && singleTierProducts.includes(bp.corestreamProductId)
}

/**
 * @deprecated - Remove after b4b4465_enrollment_methods flag removal
 */
export function isLibertyAutoQuoting(bp: BenefitPlanV2) {
	const libertyAutoQuotingProductId = 'fc32e088-80c1-43c0-9f6b-192208193f18'

	return bp.benefitProductId === libertyAutoQuotingProductId
}
