import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'

const getColor = (props, otherColor: string, selector = 'ctaColor'): string =>
	props['cta-color'] === 'true' ? props.theme[selector] : otherColor

export const CTAButtonPrimary = styled(MuiButton)`
	&& {
		background-color: ${(props): string | false =>
			props.variant !== 'outlined' && props.variant !== 'text' && getColor(props, props.theme.primaryColor)};
		&:hover {
			background-color: ${(props): string | false =>
				props.variant !== 'outlined' &&
				props.variant !== 'text' &&
				getColor(props, props.theme.secondaryColor, 'primaryColor')};
		}
		${(props): false | string =>
			props.variant === 'outlined' &&
			props['cta-color'] === 'true' &&
			`
				color: ${getColor(props, props.theme.primaryColor)} !important;
				border-color: ${getColor(props, props.theme.primaryColor)};
				&:hover {
					color: ${getColor(props, props.theme.secondaryColor, 'primaryColor')} !important;
					border-color: ${getColor(props, props.theme.secondaryColor, 'primaryColor')};
				}
			`}
		${(props): false | string =>
			props.variant === 'outlined' &&
			props['cta-color'] === 'false' &&
			`
          color: ${getColor(props, 'var(--neutral-neutral-0)')} !important;
          border-color: ${getColor(props, 'var(--neutral-neutral-0)')};
        `}
		text-transform: capitalize;
	}
`
export const DiscountButtonPrimary = styled(MuiButton)`
	color: ${(props): string => props.theme.primaryColor};
`

export const DiscountButtonCodeText = styled.span`
	color: ${(props): string => (!props.theme.isPrimaryTheme ? props.theme.primaryColor : 'var(--color-dark-gray)')};
`
