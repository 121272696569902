import { useGetCorestreamProducts } from 'api/benefit-elections/queries/corestream-products/use-get-corestream-products'
import { PipeFuncReturn } from 'hooks/user-data'
import { useCallback, useMemo } from 'react'
import { BenefitPlanV2 } from 'store/benefit-plans/types'

export const useSortingByProductType = (listsOfPlans: BenefitPlanV2[][]) => {
	const { data: corestreamProducts } = useGetCorestreamProducts()

	return useMemo(() => {
		return listsOfPlans.map((plans) => {
			return [...plans].sort((a, b) => {
				const left = corestreamProducts?.find((product) => product.productName === a.benefitPlanType)
				const right = corestreamProducts?.find((product) => product.productName === b.benefitPlanType)

				return (left?.defaultRank ?? 0) - (right?.defaultRank ?? 0)
			})
		})
	}, [corestreamProducts, listsOfPlans])
}

export const usePipeSortingByProductType = (): PipeFuncReturn<BenefitPlanV2[][]> => {
	const { data: corestreamProducts } = useGetCorestreamProducts()

	return {
		apply: useCallback(
			(data) => {
				return data.map((plans) => {
					return [...plans].sort((a, b) => {
						const left = corestreamProducts?.find((product) => product.productName === a.benefitPlanType)
						const right = corestreamProducts?.find((product) => product.productName === b.benefitPlanType)

						return (left?.defaultRank ?? 0) - (right?.defaultRank ?? 0)
					})
				})
			},
			[corestreamProducts],
		),
		subscription: useMemo(
			() => ({
				data: undefined,
				isLoading: false,
			}),
			[],
		),
	}
}
