import { ReactElement, useState } from 'react'
import { Button } from '@material-ui/core'
import './skip-link.scss'

interface SkipLinkProps {
	anchor?: string
}

const SkipLink = ({ anchor = '#main' }: SkipLinkProps): ReactElement => {
	const [focus, setFocus] = useState<boolean>(false)

	return (
		<div className={`skip-link ${focus ? 'skip-link-focused' : ''}`}>
			<Button
				className='skip-link-button'
				variant='contained'
				tabIndex={0}
				href={anchor}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
			>
				Skip to main content
			</Button>
		</div>
	)
}

export default SkipLink
