import { createAction } from '@reduxjs/toolkit'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { PersonalInfo } from 'pages/enrollment-wizard/info/types'
import { EnrollmentStatus, Question } from 'pages/enrollment-wizard/types'
import { WizardErrorModalStatus } from 'pages/enrollment-wizard/wizard/types'
import { SaveEnrollmentResponseV2, StartEnrollmentPropsV2 } from 'services/enrollments.service'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { Tier } from 'types/benefit-plan'
import { DependentList, PartialDependentList } from 'types/dependent'
import EnrollmentEvent from 'types/enrollment-event'
import { PendingTrustmarkEnrollment } from 'types/ltc-enrollment-response'
import { EnrolledPetData, PetData, PetRate } from 'types/pet'
import { NewRate } from 'types/rates'
import { StatusEnum } from 'types/status-enums'

export type WizardValidationErrors = {
	code: string
	entityId: string
	group: string
	severity: string
	text: string
}

export type PlanAnswer = {
	questionName: string
	value: string
}

export interface RateRequestParams {
	employeeIsSmoker?: boolean
	spouseIsSmoker?: boolean
}

export type WizardPlanData = {
	amount: number | null
	answeredQuestions: Array<Question>
	cancelReason: string
	coverage: string
	coverageId: string
	enrollmentEvent?: EnrollmentEvent
	enrollmentStatus?: EnrollmentStatus
	evidenceOfInsurabilityAgreement: boolean
	editedSinceLastSave: boolean
	frequency: string
	isCompleted: boolean
	isEditing: boolean
	isHighlightingRequiredFields
	isIncludingSpouseInfo: boolean
	keyDate?: string
	planAnswers: Record<string, PlanAnswer>
	planTiers: NewRate[]
	programEnrollmentId: string
	rateRequestParams: RateRequestParams
	spouseAnswers: Record<string, any>
	termsConditionAgreement: boolean
	tier: Tier | null
	tierId: string
}

export type Selections = Pick<WizardPlanData, 'amount' | 'coverage' | 'coverageId' | 'frequency' | 'tier' | 'tierId'>

export interface PlanSelections {
	[planId: string]: WizardPlanData
}

export type EnrollmentWizardState = {
	acknowledgedFinalDisclaimer: boolean
	activePlan: BenefitPlanV2
	areAnswersLoaded: boolean
	benefitElectionsSubmitError: string[]
	confirmDeclined: boolean
	// Two Pieces of the same state for dependents, one to be modified, one as a source of truth
	dependents: DependentList
	dependentsInfo: PartialDependentList
	dependentsLoadStatus: StateMachineStatus
	dependentsLoadError: Error | null
	enrollmentId: Record<EnrollmentEvent, string>
	enteredDob: string
	event: EnrollmentEvent | null
	firstPlanInOrder: number
	hasAnsweredKnockout: boolean
	hasDobError: boolean
	hasSubmissionErrors: boolean
	isAddingDep: boolean
	decliningInModalStatus: StatusEnum
	//Used to control wether the wizard Error Modal displays the errors from a failed submitEnrollment call
	isDisplayingSystemErrors: boolean
	isEditingRateRequestParameters: boolean
	isSavingEnrollment: StateMachineStatus
	isSingleProductFlow: boolean
	isStartingEnrollment: StateMachineStatus
	personal: PersonalInfo
	petDisclaimer: string
	petEnrollmentFetchError: Error | null
	petEnrollmentFetchStatus: StateMachineStatus
	petEnrollments: EnrolledPetData
	petFrequency: string
	petInfo: Array<PetData>
	petRatesFetchStatus: StateMachineStatus
	petSelections: Record<string, PetRate> // object key is petId/pet guid
	petSubmissionInfo: Array<string>
	petTotal: number | null
	petZip: string
	planFetchStatus: StateMachineStatus
	planLoadError: Error | null
	plansFetchStatus: StateMachineStatus
	plansLoadError: Error | null
	planSelections: PlanSelections
	saveEnrollmentError: string[]
	savePetError: Error | null
	savePetStatus: StateMachineStatus
	savePersonStatus: StateMachineStatus
	selectedPlans: Array<number>
	startEnrollmentData: StartEnrollmentPropsV2 | null
	startEnrollmentError: StartEnrollmentError | null
	startEnrollmentResponse: SaveEnrollmentResponseV2 | null
	submitBenefitElectionsStatus: StateMachineStatus
	trustmarkEnrollment: PendingTrustmarkEnrollment
	validationResults: Array<WizardValidationErrors>
	version: string
	wizardErrorModalStatus: WizardErrorModalStatus | null
	wrp2596: boolean
}

export type StartEnrollmentError = {
	detail: string
	status: number
	title: string
	traceId: string
}

//This action needs to be defined outside of the slice to avoid a circular dependency
export const saveEnrollmentSuccess = createAction<{
	benefitPlan: BenefitPlanV2
	enrollmentId: string
	isSavingPerson?: boolean
	planId: number
	programEnrollmentId: string
	validationResults: Array<WizardValidationErrors>
}>('enrollmentWizard/saveEnrollmentSuccess')
