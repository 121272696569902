import { ProductRecommendation } from 'api/user-insights'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { ReactNode } from 'react'
import { MyProfileUser } from 'store/user/selectors'
import { match } from 'ts-pattern'
import { IDLE } from 'types/status-types'

export type BenefitPlanStateRequestStates = {
	benefitPlansFetchStatus: StateMachineStatus
	oeWindowsFetchStatus: StateMachineStatus
	templateTokensFetchStatus: StateMachineStatus
	isbenefitPlanInitLoading: boolean
}

export type BenefitPlansState = {
	availablePlans: BenefitPlanV2[]
	calculatedTokens: Record<string, TemplateToken>
	currentOEWindows: OEWindow[]
	effectiveDates: Record<number, string>
	filterPlanIds: number[]
	isOE: boolean
	oeWindow: OEWindow | null | undefined
	oeWindows: OEWindow[]
	organizationName: string
	requestStates: BenefitPlanStateRequestStates
}

export const initialState: BenefitPlansState = {
	availablePlans: [],
	calculatedTokens: {},
	currentOEWindows: [],
	effectiveDates: {},
	filterPlanIds: [],
	isOE: false,
	oeWindow: null,
	oeWindows: [],
	organizationName: '',
	requestStates: {
		benefitPlansFetchStatus: IDLE,
		isbenefitPlanInitLoading: true,
		oeWindowsFetchStatus: IDLE,
		templateTokensFetchStatus: IDLE,
	},
}
export interface EffectiveDatesResponse {
	benefitPlanEffectiveDates: Record<number, string>
}

export const NATIVE = 'Native'
export const NON_NATIVE = 'Non-native'

export type OEWindowPlan = {
	orderIndex: number
	useElectionsEffectiveDate: boolean
}
export interface OEWindow {
	createdDate: string
	displayEndDate: string
	electionsEffectiveDate: string
	employmentGroup: number
	employmentGroupName: string
	endDate: string
	oeWindowPlans: Record<number, OEWindowPlan>
	openEnrollmentWindowId: string
	openEnrollmentWindowName: string
	startDate: string
}

export interface OEWindowsResponse {
	items: Array<OEWindow>
	page: number
	totalResults: number
}

export interface UserOEWindowResponse {
	currentWindow: OEWindow
}

export type TemplateToken = {
	description: string
	isCalculated: boolean
	isMutable: boolean
	originalPropertyKey: string | null
	propertyType: string
	tokenRegex: RegExp
	tokenKey: string
	value?: string | number
}

export type ProductTemplate = {
	productTemplateId: string
	benefitProductId: string
	cardText: string
	cardTextHeadline: string
	createdDate: string
	createdUser: string
	ctaUrl: string
	enrollmentPageContent: string
	enrollmentPageFootnotes: string
	headerMedia?: string
	headerMediaIsVideo: boolean
	iPageContent: string
	modifiedDate: string
	modifiedUser: string
	productPageContent: string
	productUtilizationContent: string
	productTemplateDescription: string
	productTemplateName: string
	status: number
	termsAndConditionsText: string
}

type TokenKeyValue = {
	key: string
	value: string
}

export interface MarketingContent {
	benefitPlanMarketingContentId: string
	createdDate: string
	createdUser: string
	endDate: string
	ctaUrl: string
	modifiedDate: string
	modifiedUser: string
	productTemplate: ProductTemplate
	startDate: string
	tokens: TokenKeyValue[]
	versionName: string
}

export interface BenefitPlanV2 {
	benefitPlanId: number
	benefitPlanName: string
	benefitPlanType: string
	benefitProductId: string
	benefitProductCode: string
	benefitProductImage: string
	benefitProductName: string
	benefitProviderDescription: string
	benefitProviderName: string
	benefitProviderImage: string
	cancellationEvergreen: boolean
	cancellationOE: boolean
	cancellationQLE: boolean
	shouldIgnoreChildAge: boolean
	corestreamProductId: string
	ctaText: string
	effectiveMarketingContent: MarketingContent
	employerAccountCode: string
	employmentGroups: number[]
	employmentGroupNames: string[]
	enrollmentMethod: string
	enrollmentStatus?: EnrollmentStatus
	enrollmentImplementation: EnrollmentImplementation | null
	evidenceOfInsurabilitySubmissionLink?: string
	endDate: number
	grandfatheredPlanId?: number
	hasEmployeeTobaccoQuestion: boolean
	hasFamilyTobaccoQuestion: boolean
	hasKnockOutQuestions: boolean
	hasMultipleCoverageOptions: boolean
	hasSpouseTobaccoQuestion: boolean
	hasVisited: boolean
	isAvailableForLifeChangeEvent: boolean
	isAvailableForNewhires: boolean
	isEnrolled: boolean
	isEvergreen: boolean
	isIncludedInOE?: boolean
	isNative: boolean
	keyDate?: Date | string
	knockOutQuestionAnswer?: boolean
	knockOutQuestionClarifier: boolean
	knockOutQuestions: string
	lowestRate: string
	marketingContent: MarketingContent[]
	maxChildAge?: number
	maxEmployeeAge: number
	maxSpouseAge?: number
	minEffectiveDate?: string
	minEmployeeAge: number
	minChildAge?: number
	minSpouseAge?: number
	nonNativeHeader?: string
	nonNativeCoverageHighlights?: string
	objectID: string
	organizationId: string
	parentPlanId?: number
	ppdsProductCode: string
	ppdsProviderCode: string
	programId: number
	rank: number
	requiredQuestions?: number[]
	shouldCollectBeneficiary: boolean
	shouldCollectDependents: boolean
	shouldCollectDependentSSN: boolean
	shouldCollectSSN: boolean
	shouldHideCTA: boolean
	shouldRequireEvidenceOfInsurability: boolean
	shouldRequireSpouseInfoForPricing: boolean
	startDate: number
	status: number //this may need to be a string in the future
	overriddenGrandfatherId?: number
	isOverriddenByParent?: boolean
	recommendation?: ProductRecommendation
}

export enum EnrollmentImplementation {
	nwPet = 'NW Pet',
	trustmark = 'Trustmark Simplink',
	liberty = 'Liberty Autoquoting',
	singleTier = 'Single-Tier',
}

export const NativeEnrollmentGuardImplementations = [
	EnrollmentImplementation.trustmark,
	EnrollmentImplementation.nwPet,
	EnrollmentImplementation.singleTier,
]

export type BenefitPlanCategory = {
	categoryName: string
	categoryDescription: string
	icon: string | ReactNode
	iconName: string
	isEnrolled: boolean
	rank: number
}

export const TokenContainers = [
	'enrollmentPageContent',
	'enrollmentPageFootnotes',
	'iPageContent',
	'termsAndConditionsText',
]

export enum GroupIds {
	ALL,
	ACTIVE,
	'RETIRED MEDICARE' = 2,
	'RETIRED NON-MEDICARE' = 3,
	'SELECTED EIDS' = 4,
	'CORESTREAM ONLY' = 5,
	'PT' = 7,
	'FT' = 8,
	'DIRECTBILL' = 9,
	'PAYROLL' = 10,
	'USA' = 11,
	'CAN' = 12,
	'MARTIN' = 13,
	'NON-MARTIN' = 14,
}

export interface SplitPlans {
	anytimePlans: BenefitPlanV2[]
	oePlans: BenefitPlanV2[]
	hasNativeInOe: boolean
}

export const AUTOHOME: string = 'auto & home insurance'

export const getEmploymentGroupId = (userProfile: MyProfileUser, organizationName: string): number =>
	match({
		benefitDeductionGroupCode: userProfile?.benefitDeductionGroupCode?.toUpperCase() ?? '',
		countryCode: userProfile.countryCode?.toUpperCase() ?? '',
		employmentGroup: userProfile?.employmentGroup?.toUpperCase() ?? '',
		employmentType: userProfile?.employmentType?.toUpperCase() ?? '',
		orgName: organizationName?.toUpperCase() ?? '',
	})
		.with(
			{ employmentGroup: 'RETIRED', employmentType: 'RETMED' },
			({ employmentGroup }) => GroupIds[`${employmentGroup} NON-MEDICARE`],
		)
		.with(
			{ employmentGroup: 'RETIRED', employmentType: 'MEDICARE' },
			({ employmentGroup, employmentType }) => GroupIds[`${employmentGroup} ${employmentType}`],
		)
		// Advantage solutions
		.when(
			({ employmentType, orgName }) => orgName === 'ADVSOL' && (employmentType === 'PT' || employmentType === 'FT'),
			({ employmentType }) => GroupIds[`${employmentType}`],
		)
		// MGB
		.when(
			({ benefitDeductionGroupCode, orgName }) =>
				orgName === 'MGB' && (benefitDeductionGroupCode === 'DIRECTBILL' || benefitDeductionGroupCode === 'PAYROLL'),
			({ benefitDeductionGroupCode }) => GroupIds[`${benefitDeductionGroupCode}`],
		)
		.when(
			({ benefitDeductionGroupCode, orgName }) => orgName === 'MGB' && benefitDeductionGroupCode !== 'DIRECTBILL',
			() => GroupIds[`PAYROLL`],
		)
		// Aptiv
		.when(
			({ countryCode, orgName }) => orgName === 'APTIV' && (countryCode === 'USA' || countryCode === 'CAN'),
			({ countryCode }) => GroupIds[`${countryCode}`],
		)
		.when(
			({ employmentType, orgName }) => orgName === 'IPG' && employmentType === 'MARTIN',
			({ employmentType }) => GroupIds[`${employmentType}`],
		)
		.when(
			({ employmentType, orgName }) => {
				return orgName === 'IPG' && employmentType !== 'MARTIN'
			},
			() => GroupIds[`NON-MARTIN`],
		)
		.otherwise(({ employmentGroup }) => GroupIds[`${employmentGroup}`])
