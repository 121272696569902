import { reactPlugin } from 'config/app-insights'
import { EnrollmentType } from 'hooks/user-data'
import { useFlags } from 'launchdarkly-react-client-sdk'
import LogRocket from 'logrocket'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { siteInfoSelector } from 'store/site/selectors'
import { trackEvent } from 'utils/utils'
import { useAlgoliaTrackingContext } from './algolia-tracking-context'
import { useEventPageName } from './useEventPageName'

export type AnalyticsActionTypes = 'click' | 'element viewed' | 'search' | 'on leave' | 'user evaluation'

/**
 * IMPORTANT Please update the "Corestream Event Tracking" Google Sheet when adding an event to a user action in our app
 *
 * https://docs.google.com/spreadsheets/d/1WbwcPNAt4xlkkMfgggahdkxkaWBt4e3qXeLcvGxn6PI/edit?usp=sharing
 */
export interface RequiredTypes {
	actionType: AnalyticsActionTypes
	elementType: 'button' | 'hyperlink' | 'modal' | 'page' | 'video' | 'eligibility' | 'textbox'
	elementName: string // e.g. product card, wizard action button, etc.
	pageName?: pageNames
}

export interface ICaptureEventDetails extends RequiredTypes {
	enrollmentType?: EnrollmentType

	// plans
	benefitPlanId?: number

	// details
	elementDetails?: string

	// offers
	discountOfferId?: string
	offerType?: 'discounts' | 'benefits'
	offerBrand?: string // hotwire, truecar, ticketsatwork, ge appliances, etc.
	offerName?: string // “10% off!”, “save on flights + hotels”, “preferred pricing”, etc.

	// errors
	errorType?: errorTypes

	// autofilled
	organizationId?: string // autofilled
	organizationName?: string // autofilled
	tenant?: string // autofilled
	url?: string // autofilled
}

// Add new Page Names here!
export type pageNames =
	| 'auto'
	| 'coming soon'
	| 'wizard'
	| 'more coverage'
	| LoginPageNames
	| RegistrationPageNames
	| AccountPageNames
	| LegacyWizardPageNames
	| EnrollmentImplementationNames
	| HomePageNames
	| 'home'
	| SurveyPageNames
	| 'search'
	| 'plan'
	| 'plan legacy'
	| 'plan category'
	| 'plan category legacy'
	| 'non native plan'
	| 'faq'
	| 'enrollment wizard'
	| 'legacy trustmark simplink'
	| 'eoi'
	| 'my benefits'
	| 'faq'
	| 'terms'
	| 'data use policy'
	| 'deals'
	| 'local discounts'
	| 'error'
	| ''

export type LoginPageNames = 'login' | 'register' | 'saml' | 'callback' | 'forgot password' | 'worker redirect'

export type RegistrationPageNames = 'native registration' | 'saml registration'

export type AccountPageNames =
	| 'account deductions'
	| 'account enrollments'
	| 'account profile'
	| 'account profile legacy'
	| 'account my benefits legacy'
	| 'account deductions legacy'

export type LegacyWizardPageNames = 'old wizard' | 'old wizard confirmation'

// Add new Error Types here!
type errorTypes = ''

export type EnrollmentImplementationNames = 'trustmark-simplink' | 'liberty-auto' | 'pet' | 'single-tier'

export type HomePageNames = 'home' | 'control' | 'product values home'

export type SurveyPageNames = 'survey' | 'typeform-survey' | 'survey-results'

/**
 * Send tracking events to Segment.
 *
 * The purpose of this hook is to gatekeep the properties we send
 * in our events. We need to be thoughtful about any new properties
 * we add, and get permission from the data team.
 *
 * Only use this in special one-off cases. Please make a new hook
 * with a defined interface for the additonal required attributes.
 */
export const _useCaptureEvent = () => {
	const { b4b_13017_logrocketevents } = useFlags()
	const siteInfo = useSelector(siteInfoSelector)
	const currentURL = window.location.href

	const routePageName = useEventPageName()

	const algoliaData = useAlgoliaTrackingContext()

	return useCallback(
		(eventData: ICaptureEventDetails) => {
			const updatedEventData: ICaptureEventDetails = {
				...algoliaData,
				organizationId: siteInfo.organizationId,
				organizationName: siteInfo.organizationName,
				tenant: siteInfo.properties.ppdsTenantCode,
				url: currentURL,
				...eventData,
				pageName: eventData.pageName ?? routePageName ?? '',
			}
			trackEvent(reactPlugin, 'event', updatedEventData)

			if (b4b_13017_logrocketevents) {
				LogRocket.track('event', {
					...updatedEventData,
				})
			}
		},
		[
			algoliaData,
			b4b_13017_logrocketevents,
			currentURL,
			routePageName,
			siteInfo.organizationId,
			siteInfo.organizationName,
			siteInfo.properties.ppdsTenantCode,
		],
	)
}
