import { API_SUBDOMAIN, endpoints } from 'api/benefit-elections/constants'
import { BenefitPlanQuestion, BenefitQuestionV2, EligibleBenefitPlansResponseDto } from 'api/benefit-elections/types'
import { axiosInstance as axios } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class BenefitPlansService {
	static fetchEffectiveDates(orgId: string, signal?: AbortSignal): Promise<void> {
		const endpoint = endpoints.BenefitPlans.fetchEffectiveDates(orgId)

		return axios.get(urlBuilder.setEndpoint(endpoint).url(), { signal })
	}

	static getBenefitQuestions(benefitPlanId: number, signal?: AbortSignal): Promise<BenefitPlanQuestion[]> {
		const endpoint = endpoints.BenefitPlans.getBenefitQuestions(benefitPlanId)

		return axios.get(urlBuilder.setEndpoint(endpoint).url(), { signal })
	}

	static getEligiblePlans(orgId: string, signal?: AbortSignal): Promise<EligibleBenefitPlansResponseDto> {
		const endpoint = endpoints.BenefitPlans.getEligiblePlans()

		return axios.get(urlBuilder.setEndpoint(endpoint).url(), {
			params: { organizationId: orgId },
			signal,
		})
	}

	/**
	 * legacy
	 * @deprecated und3484 - use getBenefitQuestions
	 */
	static async getQuestions(benefitPlanId: number, signal?: AbortSignal): Promise<BenefitQuestionV2[]> {
		const endpoint = endpoints.BenefitPlans.getQuestions(benefitPlanId)

		try {
			const rawQuestions: BenefitQuestionV2[] = await axios.get(urlBuilder.setEndpoint(endpoint).url(), {
				signal,
			})

			return rawQuestions
				.filter(
					(question) =>
						question?.role?.toLowerCase() !== 'relative' &&
						!question?.name?.toLowerCase()?.includes('ssn') &&
						!question.name.includes('active-at-work') &&
						!question.name.includes('premium'),
				)
				.map((question) =>
					question.fieldDefinitionId === 78 ? { ...question, name: 'tobacco-use', type: 'check-value' } : question,
				)
		} catch (error) {
			throw new Error(error as string)
		}
	}
}
