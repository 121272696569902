/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { upsertItem } from 'store/utils'
import { CartItem } from './types/cartItemTypes'
import { CartState, MetaField, initialState } from './types/types'

const cartSlice = createSlice({
	extraReducers: {},
	initialState,
	name: 'cart',
	reducers: {
		clearItems: (state: CartState): void => {
			state.items = []
		},

		clearLifeEvent: (state: CartState): void => {
			state.lifeEvent = initialState.lifeEvent
		},

		clearMeta: (state: CartState): void => {
			state.meta = initialState.meta
		},

		initialize: (state: CartState, action: PayloadAction<Partial<CartState>>) => {
			return {
				...state,
				...action.payload,
			}
		},

		/**
		 * Removes an item from the cart given the benefitPlanId and lookupKey
		 */
		removeItem: (state: CartState, action: PayloadAction<Pick<CartItem, 'benefitPlanId' | 'lookupKey'>>): void => {
			const index = state.items.findIndex(
				(item) => item.benefitPlanId === action.payload.benefitPlanId && item.lookupKey === action.payload.lookupKey,
			)
			if (index !== -1) state.items.splice(index, 1)
		},

		/**
		 * Removes all pet items from the cart
		 */
		removePetItems: (state: CartState): void => {
			state.items = state.items.filter((item) => item.cartItemType !== 'NwPetItem')
		},

		/**
		 * Resets state for cart, keep life event though
		 *
		 */
		reset: (state: CartState) => {
			return {
				...initialState,
				lifeEvent: state.lifeEvent,
			}
		},
		/**
		 * Add or update the item in the cart
		 * The lookupkey is optional. Its used for when cart items share the same benefitPlanId,
		 * such as adding multiple pets.
		 */

		storeItem: (state: CartState, action: PayloadAction<CartItem>): void => {
			upsertItem<CartState, CartItem>(state, action.payload, 'items', (i, given) => {
				// First, check that the benefitPlanId matches
				if (i.benefitPlanId !== given.benefitPlanId) return false

				// If a lookupKey is defined, check that it matches
				if (i.lookupKey && i.lookupKey !== given.lookupKey) return false

				// If we got this far, then the given value matches
				return true
			})
		},

		storeLifeEvent: (state: CartState, action: PayloadAction<Partial<CartState['lifeEvent']>>): void => {
			state.lifeEvent = {
				...state.lifeEvent,
				...action.payload,
			}
		},

		storeMetaProperty: (state: CartState, action: PayloadAction<MetaField>) => {
			state.meta[action.payload.name] = action.payload.value
		},
	},
})

const { actions, reducer } = cartSlice
export { reducer as cart, actions as cartActions }
