import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Box, ButtonBase, ClickAwayListener, Link } from '@mui/material'
import { cx } from 'class-variance-authority'
import { useMegaMenuItems } from 'components/app-header/hooks/use-mega-menu-items'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { User } from 'oidc-client'
import { FC, MutableRefObject, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { accountRoutes, routesMap } from 'routes/routes-map'
import { selectIsOE } from 'store/benefit-plans/selectors'
import { siteInfoSelector } from 'store/site/selectors'
import { MyProfileUser, myProfileSelector } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { MegaMenuContext } from '../../app-header'
import { Tabs } from '../../types'
import MegaMenu from '../mega-menu'
import { DefaultTabs } from './default-tabs'
import styles from './navigation-tabs.module.css'

interface NavigationTabsProps {
	activeTab: any
	appHeaderContainerRef: MutableRefObject<HTMLElement | null>
	user: User | undefined
}

const NavigationTabs: FC<NavigationTabsProps> = ({ activeTab, appHeaderContainerRef, user }: NavigationTabsProps) => {
	const { benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems } = useMegaMenuItems()

	const [showDealsMegaMenu, setShowDealsMegaMenu] = useState<boolean>(false)
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const hasDiscounts = properties.hasDiscounts
	const hasBenefits = properties.hasVoluntaryBenefits
	const isBenefitsEligible = hasBenefits && !userProfile.isDiscountsOnly

	const handleDealsMegaMenuClose = () => showDealsMegaMenu && setShowDealsMegaMenu(false)

	return (
		<MegaMenuContext.Provider
			value={useMemo(
				() => ({ benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems }),
				[benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems],
			)}
		>
			<DefaultTabs activeTab={activeTab} user={user}>
				<Benefits
					activeTab={activeTab}
					appHeaderContainerRef={appHeaderContainerRef}
					isBenefitsEligible={isBenefitsEligible ?? false}
					user={user}
				/>
				{user && hasDiscounts && (
					<ClickAwayListener onClickAway={handleDealsMegaMenuClose}>
						<div className={styles['dropdown-button']}>
							<ButtonBase
								id='deals'
								className={cx(styles['tab'], {
									[styles['tab-active']]: activeTab === Tabs.Deals,
								})}
								onClick={() => setShowDealsMegaMenu(!showDealsMegaMenu)}
							>
								Deals
								<Box sx={{ marginTop: '5px' }}>{showDealsMegaMenu ? <ArrowDropUp /> : <ArrowDropDown />}</Box>
							</ButtonBase>
							<MegaMenu
								handleClose={handleDealsMegaMenuClose}
								incomingItems={dealsMenuItems ?? []}
								open={showDealsMegaMenu}
								supplementalItems={supplementalDealsItems}
								anchorRef={appHeaderContainerRef.current}
								dataTestId='categories-mega-menu'
							/>
						</div>
					</ClickAwayListener>
				)}
			</DefaultTabs>
		</MegaMenuContext.Provider>
	)
}

export default NavigationTabs

const Benefits = (props: {
	isBenefitsEligible: boolean
	activeTab: Tabs
	appHeaderContainerRef: MutableRefObject<HTMLElement | null>
	user: User | undefined
}) => {
	const megaMenuItems = useContext(MegaMenuContext)
	const { b4b_12591_my_benefits_mui5 } = useFlags()

	const isOE = useSelector(selectIsOE)

	const [showBenefitsMegaMenu, setShowBenefitsMegaMenu] = useState<boolean>(false)
	const handleBenefitMegaMenuClose = () => showBenefitsMegaMenu && setShowBenefitsMegaMenu(false)

	if (!props.user) {
		return null
	}

	if (props.isBenefitsEligible && isOE) {
		return (
			<Link
				id='my benefits'
				href={b4b_12591_my_benefits_mui5 ? accountRoutes.enrollments : routesMap.myBenefits}
				className={cx(styles['tab'], {
					[styles['tab-active']]: props.activeTab === Tabs.Benefits,
				})}
			>
				{b4b_12591_my_benefits_mui5 ? 'Enrollments' : 'Enrolled Benefits'}
			</Link>
		)
	}

	if (props.isBenefitsEligible) {
		return (
			<ClickAwayListener onClickAway={handleBenefitMegaMenuClose}>
				<div className={styles['mega-menu-button']}>
					<ButtonBase
						id='categories'
						className={cx(styles['tab'], {
							[styles['tab-active']]: props.activeTab === Tabs.Benefits,
						})}
						onClick={() => setShowBenefitsMegaMenu(!showBenefitsMegaMenu)}
					>
						Benefits
						<Box sx={{ marginTop: '5px' }}>{showBenefitsMegaMenu ? <ArrowDropUp /> : <ArrowDropDown />}</Box>
					</ButtonBase>
					<MegaMenu
						handleClose={handleBenefitMegaMenuClose}
						incomingItems={megaMenuItems.benefitMenuItems ?? []}
						open={showBenefitsMegaMenu}
						supplementalItems={megaMenuItems.supplementalBenefitItems}
						anchorRef={props.appHeaderContainerRef.current}
						dataTestId='benefits-mega-menu'
					/>
				</div>
			</ClickAwayListener>
		)
	}

	return null
}
