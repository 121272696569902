import { cx } from 'class-variance-authority'
import { FC } from 'react'

import InlineLoadingBtnIcon from './inline-loading-icon'
import { StandardButtonProps } from './types'

import './buttons.scss'
import { CTAButtonPrimary } from './styles'

const CTAButton: FC<StandardButtonProps> = ({
	id = '',
	size = 'standard',
	type = 'button',
	useCtaColor = true,
	variant = 'contained',
	...props
}: StandardButtonProps) => {
	const classes = cx('button', 'cta-button-primary', props.className, {
		'button-large': size === 'large',
		'button-small': size === 'small',
	})

	return (
		<CTAButtonPrimary
			aria-label={props.ariaLabel}
			cta-color={useCtaColor.toString()}
			className={classes}
			data-testid={props['data-testid'] ?? ''}
			disabled={props.disabled}
			id={id}
			onClick={props.onClick}
			onKeyDown={props.onKeyDown}
			variant={variant}
			startIcon={props.isLoading ? <InlineLoadingBtnIcon /> : null}
			type={type}
		>
			{props.children ? props.children : ''}
		</CTAButtonPrimary>
	)
}

export default CTAButton
