import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { appInsights } from 'config/app-insights'
import { format, isAfter, isBefore, isSameDay, isWithinInterval } from 'date-fns'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { ElectionStatus, EnrollmentStatus } from 'pages/enrollment-wizard/types'
import BenefitPlansService from 'services/benefit-plans.service'
import { actions as enrollmentActions } from 'store/enrollments/slice'
import { OverviewSuccessData } from 'store/enrollments/types'
import { RootState } from 'store/rootReducer'
import { MyProfileUser } from 'store/user/selectors'
import { hydrateTokens, normalize } from 'store/utils'
import { P, match } from 'ts-pattern'
import { PET_PLAN_ID } from 'types/pet'
import { ERROR, LOADING, SUCCESS } from 'types/status-types'
import { EnrollmentSource, UserEnrollmentRes } from 'types/user-enrollment'
import { BenefitPlansService as BenefitPlansApi } from '../../api/benefit-elections/services'
import {
	BenefitPlanStateRequestStates,
	BenefitPlanV2,
	BenefitPlansState,
	NATIVE,
	OEWindow,
	ProductTemplate,
	TemplateToken,
	initialState,
} from './types'

export type FetchBenefitsSuccessPayload = BenefitPlanV2[]

const today = new Date()
const blackListedStatesForMASA = ['AK', 'NY', 'WA', 'ND', 'NJ', 'ON']

const getOeTokenDates = (oeWindow: OEWindow, calculatedTokens: Record<string, TemplateToken>) => {
	if (!oeWindow) {
		return calculatedTokens
	}

	calculatedTokens.OEEndDate.value = format(new Date(oeWindow.endDate), 'MM/dd/yyyy')
	calculatedTokens.OEStartDate.value = format(new Date(oeWindow.startDate), 'MM/dd/yyyy')

	// populate oe specific token values
	return calculatedTokens
}

/**
 * Final filtration of oe windows and benefit plans. Initial filtration of benefit plans and oe windows
 * was based soley on start/end dates and todays date, in their respective succession .
 * @param availablePlans state.availablePlans - prefiltered array of benefit plans to be filtered down
 *  further based on userProfile data
 * @param currentOEWindows state.currentOEWindows - prefiltered array of open enrollment windows to be
 *  filtered down further based on userProfile data
 * @param effectiveDates state.effectiveDates - map with benefit plan ids as key with a value of an effective date
 * @param organizationName state.organizationName - current org name
 * @param employmentGroup string - employmentGroup from userProfile
 * @returns Object with final filtered list of benefit plans and open enrollment window
 */
function getFilteredGroupData(
	availablePlans: BenefitPlanV2[],
	calculatedTokens: Record<string, TemplateToken>,
	currentOEWindows: OEWindow[],
	effectiveDates: Record<number, string>,
	userProfile: MyProfileUser,
) {
	const benefitDeductionGroupCode = userProfile?.benefitDeductionGroupCode ?? ''
	const employmentType = userProfile?.employmentType?.toLowerCase() ?? ''
	const oeWindow = currentOEWindows[0]
	const updatedTokens = getOeTokenDates(oeWindow, calculatedTokens)

	const filteredPlans = availablePlans.flatMap((bp) => {
		const isNative: boolean = bp.enrollmentMethod.toLowerCase() === 'native'
		const employerAccountCode = bp?.employerAccountCode?.toLowerCase() ?? ''

		const isSM10MSSOIandIsPP =
			employerAccountCode === 'mssoi' &&
			bp.benefitProviderName.toLowerCase() === 'purchasing power' &&
			(benefitDeductionGroupCode.toLowerCase() === 'sm10' || benefitDeductionGroupCode.toLowerCase() === 'sm11')
		const isSM10MSSOIandIsSF =
			employerAccountCode === 'mssoi' &&
			bp.benefitProductName.toLowerCase().includes('salary') &&
			(benefitDeductionGroupCode.toLowerCase() === 'sm10' || benefitDeductionGroupCode.toLowerCase() === 'sm11')

		const isCVSANDVPI =
			employerAccountCode === 'cvs' &&
			bp.benefitProviderName === 'Nationwide Pet Health Insurance' &&
			((employmentType !== 'ft30' && isNative) || (employmentType === 'ft30' && !isNative))

		const hideMasa =
			bp.benefitProviderName === 'MASA MTS' && blackListedStatesForMASA.includes(userProfile.stateProvince)

		if (isSM10MSSOIandIsPP || isSM10MSSOIandIsSF || isCVSANDVPI || hideMasa || !effectiveDates?.[bp.benefitPlanId]) {
			return []
		}
		// This is to convert the time in ms (UTC) to local
		const convertFromUTC = (date: Date): Date => new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
		const bpStartDate = convertFromUTC(new Date(bp.startDate * 1000))
		const bpEndDate = convertFromUTC(new Date(bp.endDate * 1000))
		// if in OE window use electionsEffective date from oeWindow obj else use plan date from effectiveDates api
		let keyDate: Date = match({
			effectiveDate: effectiveDates?.[bp.benefitPlanId],
			endDate: bpEndDate,
			oePlan: oeWindow?.oeWindowPlans?.[bp.benefitPlanId],
			oeWindow,
			startDate: bpStartDate,
		})
			.with(
				{
					oePlan: P.when((plan) => plan != null && plan.useElectionsEffectiveDate),
					oeWindow: { electionsEffectiveDate: P.select() },
				},
				(electionsEffectiveDate) => new Date(electionsEffectiveDate),
			)
			.with(
				{ effectiveDate: P.not(P.nullish), oePlan: P.nullish },
				{ effectiveDate: P.not(P.nullish), oePlan: { useElectionsEffectiveDate: false } },
				({ effectiveDate }) => new Date(effectiveDate),
			)
			.with({ effectiveDate: P.select() }, (effectiveDate) => new Date(effectiveDate))
			.run()

		// if benefit plan minEffectiveDate is greater then keyDate update keyDate
		if (bp.minEffectiveDate && isAfter(new Date(bp.minEffectiveDate), keyDate)) {
			keyDate = new Date(bp.minEffectiveDate)
		}

		// use keyDate to get current marketing content
		let effectiveMarketingContent = bp.marketingContent.find((mc) => {
			const mcStartDate = new Date(mc.startDate)
			const mcEndDate = new Date(mc.endDate)

			if (!isNative) {
				return isWithinInterval(today, { end: mcEndDate, start: mcStartDate })
			}

			return isWithinInterval(keyDate, { end: mcEndDate, start: mcStartDate })
		})

		// Hide benefit card when no effectiveMarketingContent is available
		if (!effectiveMarketingContent) {
			return []
		}

		const hydratedProductTemplate = hydrateTokens(effectiveMarketingContent.productTemplate, updatedTokens)
		effectiveMarketingContent = {
			...effectiveMarketingContent,
			productTemplate: hydratedProductTemplate,
		}

		return { ...bp, effectiveMarketingContent, isNative, keyDate: new Date(keyDate) }
	})

	filteredPlans.sort((a, b) => a.rank - b.rank || a.enrollmentMethod.localeCompare(b.enrollmentMethod))

	return { availablePlans: [...filteredPlans], calculatedTokens: { ...updatedTokens }, oeWindow }
}

/**
 * The check to see if you have an active pet enrollment
 * @param overviewPlans The latest enrollments for the given benefit plan id
 * @returns If you still have active pet coverage
 */
const checkPetEnrollment = (overviewPlans) => {
	const latestEnrolledPlan = overviewPlans.find(
		(p) => p.statusCode === EnrollmentStatus.ENROLLED || p.statusCode === EnrollmentStatus.LEAVED,
	)
	const latestCancelledPlan = overviewPlans.find((p) => p.statusCode === EnrollmentStatus.CANCELED)

	const latestEnrolledPlanDate = latestEnrolledPlan ? new Date(latestEnrolledPlan.planEffectiveDate) : null
	const latestCancelledPlanDate = latestCancelledPlan ? new Date(latestCancelledPlan.planEffectiveDate) : null
	const currentDate = new Date()

	/**
	 * If the latest enrolled plan is still effective and you aren't passed a cancelled plan effective date,
	 * then you still have an enrolled plan to show
	 */
	if (
		latestEnrolledPlanDate &&
		latestEnrolledPlanDate <= currentDate &&
		(!latestCancelledPlanDate || currentDate < latestCancelledPlanDate)
	) {
		return EnrollmentStatus.ENROLLED
	}

	return EnrollmentStatus.DECLINED
}

/**
 * Set isEnrolled and enrollmentStatus properties on available plans based on data from overview api
 * @param state
 * @param action
 */
const setEnrolledFromOverview = (state: BenefitPlansState, action): void => {
	const { allLatestUserEnrollments, userEnrollmentOverview } = action.payload

	const getIsEnrolled = (
		benefit: BenefitPlanV2,
		enrollmentStatus: EnrollmentStatus | ElectionStatus | undefined = undefined,
	): boolean =>
		match({
			enrollmentMethod: benefit.enrollmentMethod,
			enrollmentStatus,
			isEnrolled: benefit.isEnrolled,
			status: benefit.status,
		})
			// when we can't determine new election status from overview and had previous value for isEnrolled, remove
			.with({ enrollmentStatus: P.nullish, isEnrolled: true }, () => false)
			.with(
				// We don't want to reset the value if we already know it is enrolled
				{ isEnrolled: true, status: 3 },
				{
					enrollmentStatus: P.when((val) => val !== EnrollmentStatus.CANCELED && val !== EnrollmentStatus.DECLINED),
					isEnrolled: true,
				},
				() => true,
			)
			.with({ enrollmentMethod: NATIVE, enrollmentStatus: P.not(P.nullish) }, ({ enrollmentStatus }) => {
				const isEnrolledLeavedOrPending =
					enrollmentStatus === EnrollmentStatus.ENROLLED ||
					enrollmentStatus === EnrollmentStatus.LEAVED ||
					enrollmentStatus === EnrollmentStatus.PENDING

				return !!isEnrolledLeavedOrPending
			})
			.with(
				{ enrollmentMethod: P.not(NATIVE) },
				() => getNonNativeEnrollmentFromOverview(userEnrollmentOverview, benefit) != null,
			)
			.otherwise(() => false)

	const updatedBenefitPlans: BenefitPlanV2[] = state.availablePlans.map((plan) => {
		const getNativeStatusCode = (plan): string | undefined => {
			const ueFromPlanId = allLatestUserEnrollments[plan.benefitPlanId]
			const ueFromProgramId = allLatestUserEnrollments[plan.programId]
			//source values of null come from DNN so we will need to use program id
			const sourceIsWRPV2 = ueFromProgramId?.source === EnrollmentSource.WRPV2
			const ue = ueFromPlanId ?? ueFromProgramId

			const getEStatus = (ue) => {
				if (
					plan.shouldRequireEvidenceOfInsurability &&
					ue.statusCode === EnrollmentStatus.ENROLLED &&
					ue.isModifiedByLtdAutoPend
				)
					return EnrollmentStatus.PENDING

				return ue.statusCode
			}

			/**
			 * The check to see if you still have an effective pet plan if the latest was declined
			 * If programid is pet && latest enrollment is declined
			 *   check overview for last enrolled and if it is still active
			 */
			if (
				ue &&
				ue.programId === PET_PLAN_ID &&
				allLatestUserEnrollments[ue.benefitPlanId]?.statusCode === EnrollmentStatus.DECLINED
			) {
				return checkPetEnrollment(userEnrollmentOverview[ue.benefitPlanId].reverse())
			}

			return match({
				sourceIsWRPV2,
				ueFromPlanId,
				ueFromProgramId,
			})
				.with({ ueFromPlanId: P.not(P.nullish) }, () => getEStatus(ueFromPlanId))
				.with({ sourceIsWRPV2: false, ueFromProgramId: P.not(P.nullish) }, () => getEStatus(ueFromProgramId))
				.otherwise(() => undefined)
		}

		const eStatus =
			plan.enrollmentMethod === NATIVE
				? getNativeStatusCode(plan)
				: getNonNativeEnrollmentFromOverview(userEnrollmentOverview, plan)?.statusCode

		return {
			...plan,
			enrollmentStatus: eStatus,
			isEnrolled: getIsEnrolled(plan, eStatus),
		}
	})

	state.availablePlans = [...updatedBenefitPlans]
}

/**
 * Set isEnrolled and enrollmentStatus properties on available plans based on data from overview api
 * @param state
 * @param action
 */
const setEnrolledFromElectionsOverview = (
	state: BenefitPlansState,
	action: PayloadAction<OverviewSuccessData>,
): void => {
	const userEnrollmentOverview = action.payload.parsedElectionData

	const getIsEnrolled = (benefit: BenefitPlanV2, enrollmentStatus: EnrollmentStatus | undefined = undefined): boolean =>
		match({
			enrollmentMethod: benefit.enrollmentMethod,
			enrollmentStatus,
			isEnrolled: benefit.isEnrolled,
			status: benefit.status,
		})
			.with(
				// We don't want to reset the value if we already know it is enrolled
				{ isEnrolled: true, status: 3 },
				{
					enrollmentStatus: P.when(
						(val) => !!val && val !== EnrollmentStatus.CANCELED && val !== EnrollmentStatus.DECLINED,
					),
					isEnrolled: true,
				},
				{
					enrollmentMethod: NATIVE,
					enrollmentStatus: P.when(
						(val) => !!val && val !== EnrollmentStatus.CANCELED && val !== EnrollmentStatus.DECLINED,
					),
				},
				() => true,
			)
			.with(
				{ enrollmentMethod: P.not(NATIVE) },
				() => getNonNativeEnrollmentFromOverview(userEnrollmentOverview, benefit) != null,
			)
			.otherwise(() => false)

	const updatedBenefitPlans: BenefitPlanV2[] = state.availablePlans.map((plan) => {
		const getUeStatusCode = (ueArray) => {
			const canBePending = plan.shouldRequireEvidenceOfInsurability
			const isEnrolled = ueArray.find((ue) => ue.statusCode === ElectionStatus.ENROLLED)
			if (canBePending && isEnrolled) {
				const isPending = ueArray.find((ue) => ue.isPending === true)
				if (isPending) return ElectionStatus.PENDING
			}

			if (ueArray.length === 1) return ueArray[0].statusCode

			//if at least one of the records is enrolled mark product as enrolled

			if (isEnrolled) return ElectionStatus.ENROLLED
			//if all are decline or cancelled mark the product appropriately
			const isAllDeclined = ueArray.every((ue) => ue.statusCode === ElectionStatus.DECLINED)
			if (isAllDeclined) return ElectionStatus.DECLINED
			const isAllCancelled = ueArray.every((ue) => ue.statusCode === ElectionStatus.CANCELLED)
			if (isAllCancelled) return ElectionStatus.CANCELLED

			return ueArray[0].statusCode
		}
		const getNativeStatusCode = (plan): string | undefined => {
			const ueFromPlanId = userEnrollmentOverview
				? (userEnrollmentOverview[plan.benefitPlanId] as UserEnrollmentRes[])
				: null
			const ueFromProgramId = userEnrollmentOverview
				? (userEnrollmentOverview[plan.programId] as UserEnrollmentRes[])
				: null
			//source values of null come from DNN so we will need to use program id
			const sourceIsWRPV2 = ueFromProgramId?.[0].source === EnrollmentSource.WRPV2
			const ue = (ueFromPlanId ?? ueFromProgramId)?.reverse()

			/**
			 * The check to see if you still have an effective pet plan if the latest was declined
			 * If programid is pet && latest enrollment is declined
			 *   check overview for last enrolled and if it is still active
			 */
			if (ue && ue[0].programId === PET_PLAN_ID && ue[0]?.statusCode === EnrollmentStatus.DECLINED) {
				return checkPetEnrollment(ue)
			}

			return match({
				sourceIsWRPV2,
				ueFromPlanId,
				ueFromProgramId,
			})
				.with({ ueFromPlanId: P.not(P.nullish) }, () => getUeStatusCode(ueFromPlanId))
				.with({ sourceIsWRPV2: false, ueFromProgramId: P.not(P.nullish) }, () => getUeStatusCode(ueFromProgramId))
				.otherwise(() => undefined)
		}

		const eStatus =
			plan.enrollmentMethod === NATIVE
				? getNativeStatusCode(plan)
				: getNonNativeEnrollmentFromOverview(userEnrollmentOverview, plan)?.statusCode

		return {
			...plan,
			enrollmentStatus: eStatus,
			isEnrolled: getIsEnrolled(plan, eStatus),
		}
	})

	state.availablePlans = [...updatedBenefitPlans]
}

const benefitPlansSlice = createSlice({
	extraReducers: (builder) => {
		builder
			.addCase(enrollmentActions.getUserEnrollmentOverviewSuccess, setEnrolledFromOverview)
			.addCase(enrollmentActions.getUserElectionsSuccess, setEnrolledFromElectionsOverview)
	},
	initialState,
	name: 'benefitPlans',
	reducers: {
		clearIsEnrolled(state: BenefitPlansState) {
			const clearedPlans = state.availablePlans.map((p) => ({ ...p, isEnrolled: false }))
			state.availablePlans = [...clearedPlans]
		},
		fetchBenefitPlansSuccess(state: BenefitPlansState, action: PayloadAction<{ benefitPlans: BenefitPlanV2[] }>) {
			// initial filtration is based only on the dates of the benefit plan and todays date
			state.availablePlans = [...action.payload.benefitPlans]
		},
		fetchEffectiveDatesSuccess(state: BenefitPlansState, action: PayloadAction<Record<number, string>>) {
			state.effectiveDates = action.payload
		},
		fetchOEWindowsSuccess(state: BenefitPlansState, action: PayloadAction<OEWindow[]>) {
			const currentOEWindows = action.payload.filter((oeWindow) => {
				const startDate = new Date(oeWindow.startDate)
				const endDate = new Date(oeWindow.endDate)

				return (isAfter(today, startDate) || isSameDay(today, startDate)) && isBefore(today, endDate)
			})
			state.currentOEWindows = currentOEWindows
			// store all windows for notification-bar
			state.oeWindows = action.payload
			state.isOE = currentOEWindows.length > 0
		},
		fetchTemplateTokensSuccess(state: BenefitPlansState, action: PayloadAction<TemplateToken[]>) {
			const calculatedTokens = action.payload.flatMap((ct) => {
				if (!ct.isCalculated) return []
				// create regex for each token once during runtime here and reuse in hydrateTokens

				// eslint-disable-next-line no-useless-escape
				return { ...ct, tokenRegex: new RegExp(`\\$\{${ct.tokenKey}\}`, 'g') }
			})
			state.calculatedTokens = normalize(calculatedTokens, 'tokenKey')
		},
		overrideGrandfatheredPlan(
			state: BenefitPlansState,
			action: PayloadAction<{ grandfatherId: number; parentPlanId: number }>,
		) {
			const parentPlan = state.availablePlans.find((x) => x.benefitPlanId === action.payload.parentPlanId)
			if (parentPlan) parentPlan.overriddenGrandfatherId = action.payload.grandfatherId
			const grandFatherPlan = state.availablePlans.find((x) => x.benefitPlanId === action.payload.grandfatherId)
			if (grandFatherPlan) grandFatherPlan.isOverriddenByParent = true
		},
		resetBenefitPlansState() {
			return initialState
		},
		resetOverrideGrandfatheredPlan(state: BenefitPlansState, action: PayloadAction<{ grandfatherId: number }>) {
			const parentPlan = state.availablePlans.find((x) => x.overriddenGrandfatherId === action.payload.grandfatherId)
			if (parentPlan) parentPlan.overriddenGrandfatherId = undefined
			const grandFatherPlan = state.availablePlans.find((x) => x.benefitPlanId === action.payload.grandfatherId)
			if (grandFatherPlan) grandFatherPlan.isOverriddenByParent = false
		},
		setFetchStatus(
			state: BenefitPlansState,
			action: PayloadAction<{ stateKey: keyof BenefitPlanStateRequestStates; value: StateMachineStatus }>,
		) {
			state.requestStates[String(action.payload.stateKey)] = action.payload.value
		},
		setFilterPlanIds(state: BenefitPlansState, action: PayloadAction<number[]>) {
			state.filterPlanIds = action.payload.length ? [...new Set([...state.filterPlanIds, ...action.payload])] : []
		},
		setHasRequiredQuestions(
			state: BenefitPlansState,
			action: PayloadAction<{ planId: number; requiredQuestionIds: number[] }>,
		) {
			const planIndex = state.availablePlans.findIndex((bp) => bp.benefitPlanId === action.payload.planId)
			if (planIndex > -1) state.availablePlans[planIndex].requiredQuestions = action.payload.requiredQuestionIds
		},
		updateAvailablePlanEnrollmentStatus(
			state: BenefitPlansState,
			action: PayloadAction<{ benefitPlanId: number; value: boolean }>,
		) {
			const plan = state.availablePlans.find((x) => x.benefitPlanId === action.payload.benefitPlanId)
			if (plan) plan.isEnrolled = action.payload.value
		},
		/*
		 * This updates other properties of this slice after the user has been logged in.
		 * It is only used on login as the available plans will have already been populated
		 * without the user data to filter on in fetchBenefitPlanSuccess.
		 */
		updateBenefitPlansWithUserData(state: BenefitPlansState, action: PayloadAction<{ userProfile: MyProfileUser }>) {
			if (!state.currentOEWindows) return

			try {
				const { availablePlans, calculatedTokens, oeWindow } = getFilteredGroupData(
					state.availablePlans,
					state.calculatedTokens,
					state.currentOEWindows,
					state.effectiveDates,
					action.payload.userProfile,
				)
				state.oeWindow = oeWindow
				state.availablePlans = [...availablePlans]
				state.calculatedTokens = { ...calculatedTokens }
				state.isOE = !!oeWindow
				state.requestStates.isbenefitPlanInitLoading = false
			} catch (e) {
				appInsights.trackException({
					exception: e as Error,
					properties: {
						Caller: 'updateBenefitPlansWithUserData',
						WorkerId: action.payload.userProfile?.workerId ?? 'No Worker Id',
					},
				})
			}
		},
		updateKnockOutAnswer(
			state: BenefitPlansState,
			action: PayloadAction<{ benefitPlanId: number; knockOutQuestionAnswer: boolean }>,
		) {
			const planIndex = state.availablePlans.findIndex((bp) => bp.benefitPlanId === action.payload.benefitPlanId)
			if (planIndex > -1) state.availablePlans[planIndex].knockOutQuestionAnswer = action.payload.knockOutQuestionAnswer
		},
		updateProductTemplate(
			state: BenefitPlansState,
			action: PayloadAction<{ benefitPlanId: number; value: ProductTemplate }>,
		) {
			const planIndex = state.availablePlans.findIndex((bp) => bp.benefitPlanId === action.payload.benefitPlanId)
			if (planIndex > -1)
				state.availablePlans[planIndex].effectiveMarketingContent.productTemplate = action.payload.value
		},
		updateTokenValue(state: BenefitPlansState, action: PayloadAction<{ tokenKey: string; value: string | number }>) {
			state.calculatedTokens[action.payload.tokenKey].value = action.payload.value
		},
	},
})

const { actions, reducer } = benefitPlansSlice
export { actions, reducer as benefitPlans }

export const getBenefitPlansAndEffectiveDates = () => async (dispatch, getState) => {
	const {
		site: { siteInfo },
		user: { userProfile },
	}: RootState = getState()
	const oeWindowKey = 'oeWindowsFetchStatus'

	try {
		dispatch(actions.setFetchStatus({ stateKey: 'benefitPlansFetchStatus', value: LOADING }))

		// -- Fetch data in parallel
		const planCall = BenefitPlansApi.getEligiblePlans(siteInfo.organizationId)
		const windowCall = BenefitPlansService.fetchUserOEWindow(siteInfo.organizationId)

		const planData = await planCall
		const windowData = await windowCall

		// ----- oe window
		dispatch(actions.setFetchStatus({ stateKey: oeWindowKey, value: LOADING }))
		const oeWindows = windowData.currentWindow ? [windowData.currentWindow] : []
		dispatch(actions.fetchOEWindowsSuccess(oeWindows))
		dispatch(actions.setFetchStatus({ stateKey: oeWindowKey, value: SUCCESS }))

		// ---- benefit plans
		dispatch(actions.fetchBenefitPlansSuccess({ benefitPlans: planData.eligiblePlans }))
		dispatch(actions.fetchEffectiveDatesSuccess(planData.benefitPlanEffectiveDates))
		dispatch(actions.updateBenefitPlansWithUserData({ userProfile }))
		dispatch(actions.setFetchStatus({ stateKey: 'benefitPlansFetchStatus', value: SUCCESS }))
	} catch (e) {
		appInsights.trackException({
			exception: e as Error,
			properties: { Caller: 'getBenefitPlansAndEffectiveDates', OrganizationId: siteInfo.organizationId },
		})
		dispatch(actions.setFetchStatus({ stateKey: oeWindowKey, value: ERROR }))
		dispatch(actions.setFetchStatus({ stateKey: 'benefitPlansFetchStatus', value: ERROR }))
	}
}

export const getTemplateTokens = () => async (dispatch) => {
	const stateKey = 'templateTokensFetchStatus'
	dispatch(actions.setFetchStatus({ stateKey, value: LOADING }))

	try {
		const templateTokens = await BenefitPlansService.fetchTemplateTokens()
		dispatch(actions.fetchTemplateTokensSuccess(templateTokens))
		dispatch(actions.setFetchStatus({ stateKey, value: SUCCESS }))
	} catch (e) {
		appInsights.trackException({
			exception: e as Error,
			properties: { Caller: 'getTemplateTokens' },
		})
		dispatch(actions.setFetchStatus({ stateKey, value: ERROR }))
	}
}

/**
 * utility function for parsing out non native enrollment from overview api, given a benefit plan to match on
 * @param userEnrollmentOverview
 * @param nonNativePlan
 * @returns
 */
export function getNonNativeEnrollmentFromOverview(userEnrollmentOverview: any, nonNativePlan: BenefitPlanV2) {
	return userEnrollmentOverview[-1]?.find((e) => {
		// native plans -> ignore
		if ((e.benefitPlanId as number) !== -1) {
			return false
		}

		return (
			like(nonNativePlan.ppdsProductCode, e.ppdsProductCode) && nonNativePlan.ppdsProviderCode === e.ppdsProviderCode
		)
	})
}

/**
 * SQL like pattern matching, but in JS
 */
export function like(pattern: string, str: string): boolean {
	// Convert SQL-like pattern to a regular expression
	const regexPattern = pattern.replace(/%/g, '.*').replace(/_/g, '.')
	const regex = new RegExp('^' + regexPattern + '$', 'i')

	// Test the regular expression against the input string
	return regex.test(str)
}
