import { axiosInstance as axios, petAxiosInstance } from 'config/axios'
import { SaveEnrollmentV2, SavePersonalV2 } from 'pages/enrollment-wizard/types'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { WizardValidationErrors } from 'store/enrollment-wizard/types'
import { BenefitPlan, RatesResponseJSON } from 'types/benefit-plan'
import { BenefitQuestionListV2 } from 'types/benefit-questions'
import { DeductableHistory } from 'types/deductibles-history'
import EnrollmentEvent from 'types/enrollment-event'
import { QLEEventsList } from 'types/enrollment-profile'
import { EnrollmentResponse } from 'types/enrollment-response'
import { LTCEnrollmentResponse } from 'types/ltc-enrollment-response'
import { PetBreed, PetEnrollmentSave, PetEnrollmentSaveV2 } from 'types/pet'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { WorkerAddressResponse } from 'types/worker-address-response'
import UrlBuilder from './util/url-builder'

const enrollmentsSubdomain: string = 'enrollments'
const urlBuilder: UrlBuilder = new UrlBuilder(enrollmentsSubdomain)
export enum Endpoints {
	BenefitElections = 'BenefitElections',
	BenefitPlans = 'BenefitPlans',
	ClearEnrollments = 'ClearEnrollments',
	ClearEnrollmentsAndDependents = 'ClearEnrollmentsAndDependents',
	BackdateEnrollments = 'BackdateEnrollments',
	EnrollmentData = 'EnrollmentData',
	EnrollmentProfiles = 'EnrollmentProfiles',
	EnrollmentReporting = 'EnrollmentReporting',
	LTC = 'LTC',
	NationwidePetBreeds = 'NationwidePetBreeds',
	Overview = 'Overview',
	Questions = 'Questions',
	Rates = 'Rates',
	StartEnrollment = 'StartEnrollment',
	SaveEnrollment = 'SaveEnrollment',
	SubmitEnrollment = 'SubmitEnrollment',
	UserAddressInfo = 'UserAddressInfo',
	UserNewHire = 'UserNewHireInfo',
	VerifyNewHireDate = 'VerifyNewHireDateForStartingEnrollment',
	VPI = 'VPI',
	SendThirdPartyConfirmationEmail = 'SendThirdPartyConfirmationEmail',
}

export type StartEnrollmentProps = {
	event: EnrollmentEvent
	eventDate?: Date
	profileId: number
	reason: string
}
export type StartEnrollmentPropsV2 = {
	benefitPlanId: number
	enrollmentEvent: EnrollmentEvent
	event: EnrollmentEvent
	eventDate?: Date
	isSingleProductFlow: boolean
	programId: number
	reason: string
}
export type SaveEnrollmentResponse = {
	benefitPlan: BenefitPlan
	enrollmentId: string
	programEnrollmentId: string
	validationResults: Array<WizardValidationErrors>
}
export type SaveEnrollmentResponseV2 = {
	benefitPlan: BenefitPlanV2
	confirmationId: string
	enrollmentId: string
	programEnrollmentId: string
	validationResults: Array<WizardValidationErrors>
}

export type ThirdPartyConfirmationEmailRequest = {
	benefitPlanId: number
	effectiveDate: Date
	amount: string
	tier: string
	deductionPeriod?: string
}

export type UserNewHireInfo = {
	isNewHire: boolean
	isProgramStart?: boolean
	newHireQualificationStartDate: string
	newHireQualificationEndDate: string
}

export class EnrollmentService {
	static clearEnrollments(): Promise<unknown> {
		const clearEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.ClearEnrollments}`

		return axios.post(urlBuilder.setEndpoint(clearEndpoint).url())
	}

	static clearEnrollmentsAndDependents(): Promise<unknown> {
		const clearEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.ClearEnrollmentsAndDependents}`

		return axios.post(urlBuilder.setEndpoint(clearEndpoint).url())
	}

	static backdateEnrollments(): Promise<unknown> {
		const backdateEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.BackdateEnrollments}`

		return axios.post(urlBuilder.setEndpoint(backdateEndpoint).url())
	}

	static deleteProgramEnrollmentById(programEnrollmentId: string): Promise<void> {
		const endpoint = `${Endpoints.BenefitElections}/${programEnrollmentId}`

		return axios.delete(urlBuilder.setEndpoint(endpoint).url())
	}

	static getRates(productId: number, questions?): Promise<RatesResponseJSON> {
		const questionKeys = Object.keys(questions)
		const updatedQuestions = {}
		questionKeys.forEach((key) => {
			if (questions[key] === 'Yes' || questions[key] === 'No') updatedQuestions[key] = questions[key] === 'Yes'
			else updatedQuestions[key] = questions[key]
		})

		return axios
			.post(urlBuilder.setEndpoint(Endpoints.Rates).url(), {
				productId: Number(productId),
				ratesRulesParamsJson: JSON.stringify(updatedQuestions),
			})
			.then((res: any) => JSON.parse(res.json))
	}

	static getQLEEvents(employerAccountCode: string): Promise<QLEEventsList> {
		const endpoint = `${Endpoints.EnrollmentProfiles}/${employerAccountCode}/QLE/Events`

		return axios.get(urlBuilder.setEndpoint(endpoint).url())
	}

	static startEnrollmentV2(props: StartEnrollmentPropsV2): Promise<SaveEnrollmentResponseV2> {
		const startEndpoint = `${Endpoints.BenefitElections}/${Endpoints.StartEnrollment}`

		return axios.post(urlBuilder.setEndpoint(startEndpoint).url(), props)
	}

	static VerifyNewHireDateForStartingEnrollment(): Promise<SaveEnrollmentResponseV2> {
		const endpoint = `${Endpoints.BenefitElections}/${Endpoints.VerifyNewHireDate}`

		return axios.post(urlBuilder.setEndpoint(endpoint).url())
	}

	static saveEnrollmentV2(enrollment: SaveEnrollmentV2): Promise<SaveEnrollmentResponseV2> {
		const saveEndpoint: string = `${Endpoints.BenefitElections}/${Endpoints.SaveEnrollment}`

		return axios.post(urlBuilder.setEndpoint(saveEndpoint).url(), enrollment)
	}

	static saveEnrollmentPersonsV2(enrollment: SavePersonalV2): Promise<SaveEnrollmentResponseV2> {
		const saveEndpoint: string = `${Endpoints.BenefitElections}/${Endpoints.SaveEnrollment}/Persons`

		return axios.post(urlBuilder.setEndpoint(saveEndpoint).url(), enrollment)
	}

	static UserNewHireInfo(): Promise<UserNewHireInfo> {
		const endpoint = `${Endpoints.BenefitElections}/${Endpoints.UserNewHire}`

		return axios.post(urlBuilder.setEndpoint(endpoint).url())
	}

	/**
	 *
	 * @param petData Data formatted to save for pet enrollments
	 * @returns Standard save enrollment response
	 */
	static savePetEnrollment(
		petData: PetEnrollmentSave | Omit<PetEnrollmentSave, 'profileId'>,
	): Promise<SaveEnrollmentResponse> {
		const saveEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.SaveEnrollment}/Pets`

		return petAxiosInstance.post(urlBuilder.setEndpoint(saveEndpoint).url(), petData)
	}
	/**
	 *
	 * @param petData Data formatted to save for pet enrollments
	 * @returns Standard save enrollment response
	 */
	static savePetEnrollmentV2(
		petData: PetEnrollmentSaveV2 | Omit<PetEnrollmentSaveV2, 'profileId'>,
	): Promise<SaveEnrollmentResponseV2> {
		const saveEndpoint: string = `${Endpoints.BenefitElections}/${Endpoints.SaveEnrollment}/Pets`

		return petAxiosInstance.post(urlBuilder.setEndpoint(saveEndpoint).url(), petData)
	}

	static submitEnrollment(
		enrollmentId: string,
		terms: string,
	): Promise<{ confirmationId: string; enrollmentId: string; programEnrollmentId: string }> {
		const submitEndpoint: string = `${Endpoints.EnrollmentProfiles}/${Endpoints.SubmitEnrollment}`

		return axios.post(urlBuilder.setEndpoint(submitEndpoint).url(), { enrollmentId, terms })
	}

	static submitEnrollmentV2(
		enrollmentId: string,
		terms: string,
		trustmarkCoverageTiers?: string[], // ["Employee", "Spouse"]
	): Promise<{
		confirmationId: string
		enrollmentId: string
		programEnrollmentId: string
		additionalEvents: Array<string>
	}> {
		const submitEndpoint: string = `${Endpoints.BenefitElections}/${Endpoints.SubmitEnrollment}`

		return axios.post(urlBuilder.setEndpoint(submitEndpoint).url(), { enrollmentId, terms, trustmarkCoverageTiers })
	}

	/**
	 *
	 * @param startDate
	 * @returns deduction history for a user
	 */
	static getDeductions(startDate: string): Promise<DeductableHistory> {
		const submitEndpoint: string = new UrlBuilder(enrollmentsSubdomain)
			.setEndpoint('api/worker/DeductionHistory')
			.setQueryParams(new URLSearchParams({ startDate }))
			.url()

		return axios.get(submitEndpoint)
	}

	/**
	 *
	 * @param startDate - the date to start getting history for (most recent)
	 * @param endDate - the date to stop getting history for (furthest back)
	 * @param ppdsProductCode - the product code for the benefit
	 * @returns deduction history for a user
	 */
	static getDeductionsV2(
		startDate: Date,
		endDate: Date,
		ppdsProductCode?: string,
		signal?: AbortSignal,
	): Promise<DeductableHistory> {
		const submitEndpoint: string = new UrlBuilder(enrollmentsSubdomain)
			.setEndpoint('api/worker/DeductionHistory')
			.setQueryParams(
				new URLSearchParams({
					endDate: endDate.toISOString(),
					ppdsProductCode: ppdsProductCode ?? '',
					startDate: startDate.toISOString(),
				}),
			)
			.url()

		return axios.get(submitEndpoint, { signal })
	}

	static getMockedDeductions(demoMyDeductions: string): DeductableHistory {
		const today = new Date()

		const mockDeductionHistory = JSON.parse(demoMyDeductions)

		for (const item of mockDeductionHistory.items) {
			item.deductionDate = new Date(today.getFullYear(), today.getMonth() - item.deductionDateDifference, 1).toString()
		}

		return mockDeductionHistory
	}

	/**
	 *
	 * @param profileId Enrollment Profile to get enrollments for
	 * @returns Active enrollments for a user for a specific profile
	 */
	static getActiveEnrollmentsByProfile(profileId: number): Promise<Array<EnrollmentResponse>> {
		const endpoint: string = `${Endpoints.EnrollmentProfiles}/${profileId}/UserEnrollmentsResponse`

		return axios.get(new UrlBuilder(enrollmentsSubdomain, endpoint).url())
	}

	static getEnrollmentData(signal?: AbortSignal): Promise<LTCEnrollmentResponse> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`${Endpoints.LTC}/${Endpoints.EnrollmentData}`)

		return axios.get(endpoint.url(), { signal })
	}

	static getWorkerAddress(): Promise<WorkerAddressResponse> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`${Endpoints.LTC}/${Endpoints.UserAddressInfo}`)

		return axios.get(endpoint.url())
	}

	static getTrustmarkClientToken(locationData: Partial<WorkerAddressResponse>, isGroupFlow: boolean): Promise<string> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`${Endpoints.LTC}`)

		return axios.post(endpoint.url(), { ...locationData, isCustomFunctionality: isGroupFlow })
	}

	static getNationwidePetBreeds(speciesCode: string): Promise<PetBreed[]> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(`api/${Endpoints.VPI}/${Endpoints.NationwidePetBreeds}`)
		endpoint.setQueryParams(new URLSearchParams({ species: speciesCode }))

		return axios.get(endpoint.url())
	}

	static getAnsweredQuestionsForProgramEnrollment(programEnrollmentId: string): Promise<BenefitQuestionListV2> {
		const url = urlBuilder.setEndpoint(`${Endpoints.Questions}/Answered/${programEnrollmentId}`).url()

		return axios.get(url)
	}

	static getEnrollmentOverview(): Promise<UserEnrollmentRes[]> {
		const url = urlBuilder.setEndpoint(`${Endpoints.BenefitElections}/${Endpoints.Overview}`).url()

		return axios.get(url)
	}

	static sendThirdPartyEnrollmentEmail(request: ThirdPartyConfirmationEmailRequest): Promise<void> {
		const endPoint: string = `${Endpoints.EnrollmentReporting}/${Endpoints.SendThirdPartyConfirmationEmail}`

		return axios.post(urlBuilder.setEndpoint(endPoint).url(), request)
	}
}
