import { SearchResponseList } from 'feature/discounts'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'
import { SiteState } from 'store/site/types'
import { GlobalSearchState } from './types'

type SearchStatus = {
	facetSearchStatus: StateMachineStatus
	offerFetchStatus: StateMachineStatus
}

//First 5 distinct offer provider images.
//Any providers that are already in benefitPlanImages will be filtered out.
//Sorted offers by featured rank, then by name.
const sortAndOrderOfferProviderLogosSummary = (
	searchState: GlobalSearchState,
	siteState: SiteState,
	benefitProviderLogos: string[],
) => {
	if (!siteState.siteInfo.properties.hasDiscounts) return []

	const { offers } = searchState

	return sortOffersByRankThenTitle(offers.featured)
		.map((offer) => offer.vendor.logo)
		.filter((logo) => !benefitProviderLogos.includes(logo))
		.slice(0, 5)
}

const searchState = (state: RootState): GlobalSearchState => state.search
export const selectFacetsState = createSelector(searchState, ({ facets }: GlobalSearchState) => facets)
export const selectFacetSearchStatus = createSelector(searchState, (state) => state.facetSearchStatus)
export const selectOfferFetchStatus = createSelector(searchState, (state) => state.offerFetchStatus)
export const selectOfferProviderLogosSummary = createSelector(
	searchState,
	(state: RootState) => state.site,
	(state: RootState, benefitProviderLogos: string[]) => benefitProviderLogos,
	sortAndOrderOfferProviderLogosSummary,
)
export const selectSearchStatus = createSelector(
	searchState,
	({ facetSearchStatus, offerFetchStatus }: GlobalSearchState): SearchStatus => ({
		facetSearchStatus,
		offerFetchStatus,
	}),
)

export const selectOfferState = createSelector(searchState, ({ offers }: GlobalSearchState) => offers)
export const selectSingleOffer = createSelector(
	searchState,
	(state: RootState, offerId: string) => offerId,
	({ offers }: GlobalSearchState, offerId: string) => {
		// Combine all the properties into one array
		const allOffers = [...offers.featured, ...offers.standard, ...offers.trending, ...offers.hero]

		return allOffers.find((offer) => offer.objectID.toLowerCase() === offerId.toLowerCase())
	},
)

export const selectRecommendedOfferState = createSelector(
	searchState,
	({ recommendedOffers }: GlobalSearchState) => recommendedOffers,
)

export const sortOffersByRankThenTitle = (offers: SearchResponseList) => {
	const sortedOffers = [...offers].sort((a, b) => {
		const rankSort = parseInt(a.rank) - parseInt(b.rank)

		return rankSort === 0 ? a.title.localeCompare(b.title) : rankSort
	})
	const offersFromUniqueProviders: SearchResponseList = []
	sortedOffers.forEach((offer) => {
		if (
			offersFromUniqueProviders.some(
				(el) => el.vendor.logo === offer.vendor.logo || el.vendor.name === offer.vendor.name,
			)
		) {
			return
		}
		offersFromUniqueProviders.push(offer)
	})

	return offersFromUniqueProviders
}

export const selectMemberExclusiveFacets = createSelector(searchState, (state) =>
	state.facets.filter((facet) => facet.count > 0 && facet.isMemberExclusive),
)
export const selectNonExclusiveFacets = createSelector(searchState, (state) =>
	state.facets.filter((facet) => facet.count > 0 && !facet.isMemberExclusive),
)
