import { ApplicationStatus } from 'api/benefit-elections'
import { BenefitPlanV2, NATIVE } from 'store/benefit-plans/types'
import { EnrollmentPlan } from 'types/ltc-enrollment-response'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { formatDateMonthDayYear } from 'utils/util-dates'

/**
 * @deprecated use useEnrollmentImplementationValidation()'s checkExperience instead
 */
export const isTrustmarkLTC = (bp: BenefitPlanV2 | undefined): boolean =>
	Boolean(bp?.benefitProviderName?.toLowerCase().includes('trustmark') && bp?.enrollmentMethod === NATIVE)

export const isTrustMarkEnrollment = (e: UserEnrollmentRes | undefined) =>
	Boolean(e?.carrierName?.toLowerCase().includes('trust'))

export const isOnlyTrustmark = (oePlans: BenefitPlanV2[]): boolean =>
	Boolean(oePlans.length === 1 && isTrustmarkLTC(oePlans[0]))

export const getEarliestDateForTrustmarkPlans = (arr: EnrollmentPlan[]) => {
	let date = ''
	if (arr && arr.length) {
		const dates = arr.map((a) => a.effectiveDate)
		date = dates.reduce((acc, date) => {
			return acc && new Date(acc) < new Date(date) ? acc : date
		}, '')
	}

	return date
}

export const getCombinedNamesForPlans = (plans: EnrollmentPlan[]): string => {
	const names: string[] = []
	for (const p of plans) {
		names.push(`${p.applicantFirstName} ${p.applicantLastName}`)
	}

	return names.join(', ')
}

export const getTrustmarkPlanDataForStatus = (
	plans: EnrollmentPlan[],
	status: ApplicationStatus[],
): { employeeNames: string; earliestEffectiveDate: string } => {
	const plansInStatus = plans.filter((x) => status.includes(<ApplicationStatus>x.applicationStatus))

	if (plansInStatus.length) {
		const earliestTrustmarkEffectiveDate = getEarliestDateForTrustmarkPlans(plansInStatus)
		const names = getCombinedNamesForPlans(plansInStatus)

		return { earliestEffectiveDate: formatDateMonthDayYear(earliestTrustmarkEffectiveDate ?? ''), employeeNames: names }
	}

	return { earliestEffectiveDate: '', employeeNames: '' }
}
