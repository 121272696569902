import { PageNameSpecMap } from './PageNameSpecMap'
import { useRouteSpec } from './useRouteSpec'

export const useEventPageName = () => {
	const routeSpec = useRouteSpec()

	if (!routeSpec) return null

	// find pageName in PageNameSpecMap

	const foundRoute = PageNameSpecMap[routeSpec]

	if (!foundRoute) return null

	return foundRoute
}
