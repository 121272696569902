import { PetEnrollmentDetails } from 'api/enrollments'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { NwPetItem } from 'store/cart/types/cartItemTypes'
import { PetData } from 'types/pet'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { flipRecord, periodMap, periods } from 'utils/utils'
import { CartItem } from './types/cartItemTypes'
import { EnrolledPetToCart, PetToCart } from './types/types'

export const isCartItemDeclinedOrCanceled = (planData: CartItem) =>
	planData?.statusCode === EnrollmentStatus.DECLINED || planData?.statusCode === EnrollmentStatus.CANCELED

/**
 * Gets the information about the pet
 * @param pet
 * @returns The pet information
 */
const getPetInfo = (pet: PetData): PetEnrollmentDetails[] => {
	return [
		{
			age: pet.age as unknown as number,
			breed: pet.breed,
			coverage: pet.coverage,
			name: pet.petName,
			petGuid: pet.petGuid,
			petId: self.crypto.randomUUID(),
			species: pet.species,
		},
	]
}

const getPetAmount = (pet: PetData, enrollmentStatus: EnrollmentStatus | undefined): number => {
	const amount = pet.coverageAmount ?? pet.amount ?? 0

	return enrollmentStatus === EnrollmentStatus.DECLINED ? 0 : amount
}

export const getPetCoverageLabel = (
	petName: string,
	petCoverageName: string | undefined,
	petWellness: boolean | undefined,
) => {
	const defaultPlanTitle = petWellness ? 'My Pet Protection With Wellness Plan' : 'My Pet Protection Plan'

	return `${!!petCoverageName && petCoverageName !== '' ? petCoverageName : defaultPlanTitle} for ${petName}`
}

/**
 * Takes in a pet to be transformed into a cart item
 * @param pet
 * @param disclaimer
 * @param planId
 * @returns A cart DTO for a new pet item
 */
export const petToCartDto = (data: PetToCart): NwPetItem => {
	const { pet, disclaimer, plan, planSelection, postal, petFrequency, effectiveDate } = data

	return {
		// Checks for existing coverageAmount first, then checks for new amount
		amount: getPetAmount(pet, planSelection?.enrollmentStatus),
		benefitPlanId: plan.benefitPlanId,
		cartItemType: 'NwPetItem',
		confirmedDisclaimer: disclaimer,
		coverageLabel: getPetCoverageLabel(pet.petName, pet.coverageName, pet.hasWellness),
		effectiveDate: new Date(plan.keyDate ?? effectiveDate ?? ''),
		frequency: petFrequency,
		lookupKey: pet.petGuid,
		pets: getPetInfo(pet),
		postalCode: postal,
		programId: plan.programId,
		statusCode: planSelection?.enrollmentStatus ?? EnrollmentStatus.NEW,

		tierLabel: `${pet.coverage ? pet.coverage.substring(pet.coverage.length - 2) : ''}% Coverage`,
	}
}

export const emptyPetToCartDto = (plan: BenefitPlanV2): NwPetItem => {
	const { benefitPlanId, keyDate, programId } = plan

	return {
		amount: 0,
		benefitPlanId: benefitPlanId,
		cartItemType: 'NwPetItem',
		confirmedDisclaimer: '',
		coverageLabel: '',
		effectiveDate: new Date(keyDate ?? ''),
		frequency: '',
		pets: [],
		programId: programId,
		statusCode: EnrollmentStatus.DECLINED,
		tierLabel: '',
	}
}

/**
 * Takes in a previously enrolled pet to be transformed into a cart item
 * @param data
 * @returns A cart DTO for a previously enrolled pet item
 */
export const enrolledPetToCartDto = (data: EnrolledPetToCart): NwPetItem => {
	const { pet, disclaimer, plan, postal, petFrequency } = data

	return {
		amount: pet.amount,
		benefitPlanId: plan.benefitPlanId,
		cartItemType: 'NwPetItem',
		confirmedDisclaimer: disclaimer,
		coverageLabel: getPetCoverageLabel(pet.petName, pet.coverageName, pet.hasWellness),
		effectiveDate: new Date(pet.effectiveDate ?? ''),
		frequency: petFrequency,
		lookupKey: pet.petGuid,
		//we dont want or need to send these pets to the api so they are omitted
		pets: [],
		postalCode: postal,
		programId: plan.programId,
		statusCode: EnrollmentStatus.LEAVED,

		tierLabel: `${pet.coverage ? pet.coverage.substring(pet.coverage.length - 2) : ''}% Coverage`,
	}
}

export const cartToPetDto = (cartItem: CartItem): PetData => {
	const pet = cartItem.pets[0]

	return {
		age: String(pet.age),
		breed: pet.breed,
		coverage: pet.coverage,
		coverageAmount: cartItem.amount,
		coverageName: '',
		frequency: cartItem.frequency,
		hasWellness: pet.coverage.includes('WL'),
		isCancelled: false,
		isCovered: false,
		petGuid: pet.petGuid,
		petName: pet.name ?? '',
		rates: [],
		species: pet.species,
	}
}

export const userEnrollmentToCartDto = (bpe: UserEnrollmentRes, nativePlan: BenefitPlanV2): Partial<CartItem> => ({
	amount: parseFloat(bpe.rate.replace(/[^0-9.-]+/g, '')),
	benefitPlanId: bpe.benefitPlanId,
	cartItemType: 'BenefitItem',
	coverage: bpe.coverage,
	coverageId: '',
	coverageLabel: bpe.coverageTitle,
	disclaimerText: '',
	effectiveDate: new Date(bpe.planEffectiveDate),
	frequency: periods.includes(bpe.rateFrequency) ? bpe.rateFrequency : flipRecord(periodMap)[bpe.rateFrequency],
	hasAcceptedTermsAndConditions: false,
	programId: nativePlan?.programId ?? bpe.programId,
	questions: [],
	shouldRequireEvidenceOfInsurability: nativePlan?.shouldRequireEvidenceOfInsurability,
	statusCode: bpe.statusCode === EnrollmentStatus.ENROLLED ? EnrollmentStatus.LEAVED : bpe.statusCode,
	tier: bpe.tier,
	tierId: '',
	tierLabel: bpe.tierTitle,
})
