import { StylesProvider } from '@material-ui/core'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ReactNode } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { ThemeProvider as CSMuiThemeProvider } from 'theme'
import { Properties } from 'types/tenant-site-types'
import { ThemeEngine } from 'types/theme'
import { hexToHSLA } from 'utils/utils'

const GlobalStyles = createGlobalStyle<{ globalElements: Properties }>`
	:root {
		--color-alert: ${(props) => props.globalElements.alertColor};
		--color-background: ${(props) => props.globalElements.backgroundColor};
		--color-background-tinted: ${(props) => hexToHSLA(props.globalElements.backgroundColor ?? '', 0.6, 1.4, 0.6)};
		--color-cta: ${(props) => props.globalElements.ctaColor};
		--color-foreground-text: ${(props) => props.globalElements.foregroundTextColor};
		--color-primary: ${(props) => props.globalElements.brandColor};
		--color-cta-desaturated: ${(props) => hexToHSLA(props.globalElements.ctaColor ?? '', 0.3)};
		--color-cta-tinted: ${(props) => hexToHSLA(props.globalElements.ctaColor ?? '', 0.6, 1.4, 1)};
		--image-background: url(${(props) => props.globalElements.backgroundImage});
		._hj_feedback_container {
			display: none;
		}
	}
`

export const ThemeRegistration = (props: { children: ReactNode; properties: Properties }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<CSMuiThemeProvider properties={props.properties}>
				<ThemeProvider theme={new ThemeEngine(props.properties)}>
					<StylesProvider injectFirst>
						<CssBaseline />
						<GlobalStyles globalElements={props.properties} />
						{props.children}
					</StylesProvider>
				</ThemeProvider>
			</CSMuiThemeProvider>
		</LocalizationProvider>
	)
}
