import { useSiteProperty } from 'hooks/use-site-property'
import { useFlags } from 'launchdarkly-react-client-sdk'
/**
 * This should only be used at the api level to determine to enable the queries.
 * For checking if a user is eligible to take the survey, use the useSurveyData hook.
 */
export const useIsDecisionSupportEnabledForClient = (): boolean => {
	const { b4b_7999_decision_support } = useFlags()
	const hasDecisionSupport = useSiteProperty('hasDecisionSupport')

	return b4b_7999_decision_support && hasDecisionSupport
}
