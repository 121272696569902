import { PagedResponse } from './paged-response'

export const EO = 'EO'
export const EC = 'EC'
export const ES = 'ES'
export const FA = 'FA'
export const MO = 'MO'
export const BW = 'BW'
export const SM = 'SM'
export const WK = 'WK'

export enum Tier {
	EC = 'EC',
	EO = 'EO',
	ES = 'ES',
	FA = 'FA',
}

// export type Tier = typeof EO | typeof EC | typeof ES | typeof FA
export type TierList = Array<Tier>
export type Rate = typeof MO | typeof BW | typeof SM | typeof WK

export type CoverageTier = {
	coverageTierId: number
	ratePeriod: Rate
	tier: Tier
	tierTitle: string
	tierRate: number
}
export type CoverageTierList = Array<CoverageTier>

export type PlanTier = {
	coverageTiers: CoverageTierList
	coverageValue: string
	planDescription: string
	planId: number
	title: string
}

export type PlanTierList = Array<PlanTier>

export interface RatesResponse {
	json: string
	ruleCode: string
}

export interface RatesResponseJSON {
	ProgramId: number
	ProgramName: string
	ProgramDescription: string
	ProgramDisclaimer: null
	PlanTiers: PlanTierList
}

export interface BenefitQuestion {
	caption: string
	customValidation: string
	dataMask: string
	description: string
	fieldDefinitionId: number
	fieldOrder: number
	hint: string
	isRequired: boolean
	name: string
	role: string
	toolTip: string
	type: string
}

export type BenefitQuestionList = Array<BenefitQuestion>

export interface BenefitPlan {
	amountsJson?: string
	benefitPlanId: number
	benefitPlanImageUrl: string
	benefitPlanName: string
	benefitPlanType: string
	benefitPlanTypeExternalName: string
	benefitPlanTypeId: number
	benefitProgramDescription: string
	benefitProviderLogo?: string
	benefitProviderId: string
	benefitQuestions: BenefitQuestionList
	benefitProviderName: string
	carrierName?: string
	collectDependents?: boolean
	collectDisabled?: boolean
	collectSSN?: boolean
	coverage?: string
	effectiveDate?: string
	enrollmentProfiles: Array<number>
	evergreenProfileId?: number
	hasKnockoutQuestions: boolean
	hasSpouseSpecificRates: boolean
	introTextCard: string
	introTextMain: string
	isAvailableForNewHires: boolean
	isAvailableForLifeChangeEvent: boolean
	isAvailableForQLECancel: boolean
	objectId?: string
	logoUrl: string | null
	overviewText: string
	planTiers: PlanTierList
	ppdsProductCode: string
	ppdsProviderCode: string
	providerAdditionalInfo: string
	programEnrollmentStatus?: string
	programSelectionDescription: string
	referenceDisclaimers: string
	status?: number
	tenantId: string
	tenantName: string
	tenantCode: string
	termsText: string
	tier?: string
}

export type PagedBenefitPlans = PagedResponse<BenefitPlan>
