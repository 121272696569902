import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { upsertItem } from 'store/utils'
import { Step, StepsState, initialState } from './types'

const { actions, reducer } = createSlice({
	initialState,
	name: 'steps',
	reducers: {
		back: (state) => {
			if (state.currentStepIndex > 0) {
				state.currentStepIndex--
			}
		},
		changeToStep: (state, action: PayloadAction<number>) => {
			const { payload: index } = action
			if (index in state.steps) {
				state.currentStepIndex = action.payload
				upsertItem<StepsState, number>(state, state.steps[state.currentStepIndex].id, 'visited')
			}
		},
		clear: () => {
			return initialState
		},
		forward: (state) => {
			if (state.currentStepIndex < state.steps.length - 1) {
				upsertItem<StepsState, number>(state, state.steps[state.currentStepIndex].id, 'completed')

				state.currentStepIndex++
				upsertItem<StepsState, number>(state, state.steps[state.currentStepIndex].id, 'visited')
			}
		},
		initialize: (state, action: PayloadAction<Partial<StepsState>>) => {
			return { ...state, ...action.payload }
		},
		insertStep: (state, action: PayloadAction<{ step: Step; index: number }>) => {
			state.steps = [
				...state.steps.slice(0, action.payload.index),
				action.payload.step,
				...state.steps.slice(action.payload.index),
			]
		},
		lockSteps: (state, action: PayloadAction<number[]>) => {
			action.payload.forEach((s) => {
				upsertItem<StepsState, number>(state, s, 'locked')
			})
		},
		removeCompletedStep: (state, action: PayloadAction<number>) => {
			const index = state.completed.findIndex((stepNumber) => stepNumber === action.payload)
			if (index !== -1) state.completed.splice(index, 1)
		},
		removeStep: (state, action: PayloadAction<number>) => {
			const index = state.steps.findIndex((step) => step.id === action.payload)
			if (index !== -1) {
				state.steps.splice(index, 1)
			}
		},
		updateCurrentStepCompletedTo: (state, action: PayloadAction<boolean>) => {
			// Add to completed array
			if (action) {
				upsertItem<StepsState, number>(state, state.steps[state.currentStepIndex].id, 'completed')
			} else {
				// remove
				const index = state.completed.findIndex((id) => id === state.currentStepIndex)
				if (index !== -1) state.completed.splice(index, 1)
			}
		},
	},
})

export { reducer as steps, actions as stepsActions }
