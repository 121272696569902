import { ReactNode } from 'react'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { SetState } from 'types/set-state'
import { StepStatus } from './sidebar/types'

// on the api side
export type Plan = {
	isComplete?: boolean
	id: number
	label: string
	planStepStatus: StepStatus
}
export type PlanList = Array<Plan>
export type StepList = Array<IWizardStep>

/**
 * Example usage:
 *
 * const event: Event = { header: 'Open Enrollment', subheader: 'Ends Oct 3, 2020' }
	const stepsComplete: StepsComplete = { 1: true, 2: false, 3: false }
	const steps: StepList = [
		{ id: 1, name: 'Your Information', plans: [] },
		{
			id: 2,
			name: 'Select Plans',
			plans: [
				'Critical Illness by Metlife',
				'Vision Care by VSP',
				'Legal Services from ARAG',
				'Long Term Care from Company',
			],
		},
		{ id: 3, name: 'Confirm', plans: [] },
	]
 */

export type Event = { header: string; subheader: string }
export type StepsComplete = Record<number, boolean>
export type ComponentList = Record<number, ReactNode> // components to display
export type SetActivePlan = SetState<number> // returns the active plan id

export type WizardProps = {
	children?: ReactNode
	onPlanDecline: (planId: number) => void
	onPlanUndoCancel: (planId: number) => void
	plans: BenefitPlanV2[]
	setActivePlan: (newPlanId: number) => void
	secondaryComponent?: ReactNode
	showQLEModal: () => void
	setSkipNavigateNext: (val: boolean) => void
	steps: StepList
}

export interface IWizardStep {
	description?: string
	id: number
	icon?: string | StepStatus
	name: string
	plans?: PlanList
	save?: (...args) => any
}

export class WizardStep implements IWizardStep {
	description = ''
	id
	image = ''
	name = ''
	plans = []

	constructor(data: IWizardStep) {
		Object.assign(this, data)
	}

	save: () => any = () => null
}

export enum WizardErrorModalStatus {
	OPEN = 'OPEN',
	CLOSED = 'CLOSED',
}
