import { Tier } from 'types/benefit-plan'
import { PartialDependentList } from 'types/dependent'
import EnrollmentEvent from 'types/enrollment-event'
import { EnrollmentRateFrequencyNative, EnrollmentRateFrequencyNonNative } from 'types/user-enrollment'

export type Question = {
	fieldDefinitionId: number
	fieldId: string
	value: string | boolean
}

// NOTE: UNKNOW CANNOT BE CHANGED TO UNKNOWN - DO NOT TOUCH
// IF DECLINED, SET TO DECLINED, OTHERWISE, DO NOT TOUCH

export enum EnrollmentStatus {
	UNKNOWN = 'Unknow',
	NEW = 'New',
	ENROLLED = 'Enrolled',
	CANCELED = 'Canceled',
	DECLINED = 'Declined',
	INELIGIBLE = 'Ineligible',
	LEAVED = 'Leaved', // user revisited wizard and did not change existing selections
	TERMINATED = 'Terminated',
	//specific to product with EOI requirements(bp.shouldRequireEvidenceOfInsurability)
	PENDING = 'Pending',
}

// create Select mapping for EnrollmentStatus options
export const enrollmentStatusOptions = [
	{ label: 'New', value: EnrollmentStatus.NEW },
	{ label: 'Enrolled', value: EnrollmentStatus.ENROLLED },
	{ label: 'Canceled', value: EnrollmentStatus.CANCELED },
	{ label: 'Declined', value: EnrollmentStatus.DECLINED },
	{ label: 'Ineligible', value: EnrollmentStatus.INELIGIBLE },
	{ label: 'Leaved', value: EnrollmentStatus.LEAVED },
	{ label: 'Terminated', value: EnrollmentStatus.TERMINATED },
]

export enum ElectionStatus {
	CANCELLED = 'CANCELLED',
	DECLINED = 'DECLINED',
	ENROLLED = 'ENROLLED',
	TERMINATED = 'TERMINATED',
	//specific to product with EOI requirements(bp.shouldRequireEvidenceOfInsurability)
	PENDING = 'PENDING',
}

export enum EditType {
	EDIT = 'Edit',
	CANCEL = 'Cancel',
}

export interface SaveEnrollmentV2 {
	amount: number
	benefitPlanId: number
	confirmedDisclaimer: string
	coverage: string
	coverageId: string
	enrollmentEvent: EnrollmentEvent
	effectiveDate: Date
	enrollmentId: string
	frequency: EnrollmentRateFrequencyNative | EnrollmentRateFrequencyNonNative
	isSingleProductFlow: boolean
	programEnrollmentId: string
	programId: number
	questions: Question[]
	statusCode: string
	tier: Tier
	tierId: string
}
type Address = {
	city: string
	state: string
	streetOne: string
	streetTwo: string
	zipCode: string
}
export type SavePersonal = {
	address: Address
	birthDate: Date | null
	dependents?: PartialDependentList
	enrollmentId: string
	gender: string
	personalQuestions?: Array<Question>
	profileId: number | null
	programEnrollmentId: string
	programId: number | null
}

export type SavePersonalV2 = {
	address: Address
	benefitPlanId: number | null
	birthDate: Date | null
	dependents?: PartialDependentList
	enrollmentId: string
	enrollmentEvent: EnrollmentEvent | null
	gender: string
	isSingleProductFlow: boolean
	personalQuestions?: Array<Question>
	programEnrollmentId: string
	programId: number | null
}

export type FooterNotesProps = {
	content: string
}

// fieldDefinitionId of question we want to hide but still update
export const QUESTIONS_TO_HIDE = []

export const TOOLTIP_CANCEL = 'Cancel your existing coverage'
export const TOOLTIP_UNDO_CANCEL = 'Undo your cancellation'
export const TOOLTIP_DECLINE = 'Decline benefit election and/or Cancel this benefit coverage'
export const TOOLTIP_SKIP = 'Skip enrolling in this benefit'

export const navigateAwayPopupText = {
	continueButtonText: 'Continue Enrollment',
	leaveButtonText: 'Yes, leave now',
	subTitle: 'If you exit this page, your enrollment will not be complete.',
	title: 'Are you sure you want to leave?',
}
