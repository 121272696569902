//@index(['./*', '!./**.{test,stories}.*', '!./{__test__,test,_}*', '!./**.scss' ], f => `export * from '${f.path}'`)
export * from './deparam'
export * from './useIsPaychex'
export * from './useOpen'
//@endindex

import queryString, { ParsedQuery } from 'query-string'
import { Location, NavigateFunction, matchPath } from 'react-router-dom'
import * as yup from 'yup'

const headerMap = {
	brands: 'brand',
	categories: 'category',
}

export const getSearchState = (location: Location): ParsedQuery<string> =>
	queryString.parse(decodeURISafe(location?.search))?.category ||
	queryString.parse(decodeURISafe(location?.search))?.brand ||
	queryString.parse(decodeURISafe(location?.search))?.query
		? queryString.parse(decodeURISafe(location?.search))
		: queryString.parse(location?.search)

export const isValidEmail = (value: string): boolean => {
	const yupString = yup.string()

	return yupString.email().isValidSync(value)
}

export function isUrlAbsolute(url: string): boolean {
	if (url.indexOf('//') === 0) {
		return true
	} // URL is protocol-relative (= absolute)
	if (url.indexOf('://') === -1) {
		return false
	} // URL has no protocol (= relative)
	if (url.indexOf('.') === -1) {
		return false
	} // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
	if (url.indexOf('/') === -1) {
		return false
	} // URL does not contain a single slash (= relative)
	if (url.indexOf(':') > url.indexOf('/')) {
		return false
	} // The first colon comes after the first slash (= relative)
	if (url.indexOf('://') < url.indexOf('.')) {
		return true
	} // Protocol is defined before first dot (= absolute)

	return false // Anything else must be relative
}

const decodeURISafe = (value: string): string => {
	if (!value) {
		return value
	}

	return decodeURI(value.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
}

export const buildUrl = (value: string | string[], paramName: string = 'category'): string => {
	const values = Array.isArray(value) ? [...value] : [value]
	const builtParams = values.map((paramValue) => `${paramName}=${encodeURIComponent(paramValue)}`)

	return builtParams.join('&')
}

/**
 * Update URL
 * @desc get the URL in sync when adding or removing a refinement. We want to keep the existing query or
 * category/brand if it is already in the URL. Build the string based on what the search string state is and
 * if no search then stay on the search page since a refinement was tweaked while the user is on the
 * search page
 */
export const updateUrl = (
	navigate: NavigateFunction,
	searchParams: URLSearchParams,
	refinements: string[],
	rawFilterName: string,
): void => {
	const filter = headerMap[rawFilterName]
	const query = searchParams.get('query')
	const brand = searchParams.get('brand')
	const category = searchParams.get('category')
	let search = ''

	if (query) {
		search = `query=${query}`
	}
	if (brand && filter === 'category') {
		search += search ? `&${buildUrl(brand, 'brand')}` : `${buildUrl(brand, 'brand')}`
	}
	if (category && filter === 'brand') {
		search += search ? `&${buildUrl(category)}` : `${buildUrl(category)}`
	}

	if (refinements.length > 0) {
		search += search ? `&${buildUrl(refinements, filter)}` : `${buildUrl(refinements, filter)}`
	}

	if (search) {
		navigate(`/search?${search}`)
	} else {
		navigate('/search')
	}
}

export const getBenefitPlanUrl = (benefitPlanType: string, benefitPlanId: number) => {
	return `/benefits/${benefitPlanType}/${benefitPlanId}`
}

/**
 * 	Builds the url for a Deal Category's search page.
 *  @param cateogry category to build url for
 *  @returns url string for Deal Category's search page
 */
export const getDealCategorySearchUrl = (category: string) => {
	return `/search?category=${encodeURIComponent(category)}`
}

export const isPathWithinGroup = (path: string, group: string[]): boolean =>
	group.some((item) => matchPath({ end: false, path: item }, path))
