import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { siteInfoSelector } from '../store/site/selectors'

const titleMap = {
	'/': 'Home',
	'/auto-quote': 'Auto Quote',
	'/confirmation': 'Confirmation',
	'/data-use-policy': 'Data Use',
	'/enroll/': 'Enroll',
	'/faq': 'Help',
	'/forgot-password': 'Forgot Password',
	'/home': 'Home',
	'/login': 'Login',
	'/my-benefits': 'Benefits',
	'/my-profile': 'Profile',
	'/register': 'Registration',
	'/reset-password': 'Reset Password',
	'/search': 'Search',
	'/terms': 'Terms',
}
/**
 * Constructs the title with postfix and barrier if not an empty title
 * @param titleParam title to construct
 * @returns constructed title
 */
function constructTitle(titleParam: string, titlePostfix: string): string {
	const barrier: string = ' | '
	if (!titleParam) {
		return titlePostfix
	}

	return `${titleParam}${barrier}${titlePostfix}`
}

/**
 * Returns the title for the current page for other usages and applies to document.title
 */
export function useTitle(titleProp: string = '') {
	const location = useLocation()
	const {
		properties: { siteTitleExternal = '' },
	} = useSelector(siteInfoSelector)
	const titlePostFix: string = siteTitleExternal ?? 'Your Discounts | Corestream'

	let newTitle = titleMap?.[location.pathname] ?? titleProp
	if (location.pathname.includes('auto')) {
		newTitle = titleMap['/auto-quote']
	} else if (location.pathname.includes('enroll')) {
		newTitle = titleMap['/enroll/']
	}

	document.title = constructTitle(newTitle, titlePostFix)

	return document.title
}
