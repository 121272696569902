/* eslint-disable sort-keys */
import { useEnrollmentData } from 'hooks/user-data'

/**
 * @returns true if there is only one plan in the OE Window
 */
export const useIsSingleProductOE = () => {
	const { enrollmentPlans } = useEnrollmentData()

	if (enrollmentPlans.length === 1) {
		return { result: true, plan: enrollmentPlans[0] }
	}

	return { result: false, plan: null }
}
