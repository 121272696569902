/* eslint-disable sort-keys */
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SearchConfiguration } from 'components/util'
import { reactPlugin } from 'config/app-insights'
import { client, offersIndex } from 'config/search-config'
import { queryClient } from 'config/tanstack-query'
import { useBootIntercom } from 'hooks/intercom'
import { useScript } from 'hooks/use-script'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Maintenance from 'pages/maintenance'
import { useEffect } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { DataLoaderCapableRoutes, NeedingToBeMigratedRoutes } from 'routes/all-routes'
import AppLayout from 'routes/app-layout'
import { actions as enrollmentWizardActions } from 'store/enrollment-wizard/slice'
import { actions as siteActions } from 'store/site/slice'
import { store } from 'store/store'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { runCacheBuster } from 'utils/run-cache-buster'
import analyticsScript from '../utils/analyticScript'
import { ThemeRegistration } from './ThemeRegistration'

interface Props {
	siteInfo: SiteInfoResponse
}

export const App = ({ siteInfo }: Props): JSX.Element => {
	const { showClientMaintenancePage } = useFlags()
	if (showClientMaintenancePage) {
		return <Maintenance />
	}

	return <AppContent siteInfo={siteInfo} />
}

function AppContent({ siteInfo }: Props): JSX.Element {
	const { wrp2596 } = useFlags()

	useEffect(() => {
		store.dispatch(enrollmentWizardActions.setWrp2596(wrp2596))
	}, [wrp2596])

	useEffect(() => {
		runCacheBuster()
	}, [])

	useEffect(() => {
		if (siteInfo) {
			store.dispatch(siteActions.getSiteInfoSuccess(siteInfo))
		}
	}, [siteInfo])

	useBootIntercom(siteInfo)

	useScript(analyticsScript, true)

	const newDataLoaderCapabaleRoutes = createRoutesFromElements(DataLoaderCapableRoutes, [1])

	const router = createBrowserRouter(
		[
			{
				element: <AppLayout />,
				path: '*',
				children: [
					...newDataLoaderCapabaleRoutes,
					{
						path: '*',
						element: <NeedingToBeMigratedRoutes />,
					},
				],
			},
		],
		{
			future: {
				v7_fetcherPersist: true,
				v7_normalizeFormMethod: true,
				v7_partialHydration: true,
				v7_relativeSplatPath: true,
			},
		},
	)

	return (
		<QueryClientProvider client={queryClient}>
			<AppInsightsContext.Provider value={reactPlugin}>
				<ThemeRegistration properties={siteInfo.properties}>
					<ReactQueryDevtools initialIsOpen={false} />
					<InstantSearch indexName={offersIndex} searchClient={client}>
						<SearchConfiguration siteInfo={siteInfo} />
						<RouterProvider router={router} />
					</InstantSearch>
				</ThemeRegistration>
			</AppInsightsContext.Provider>
		</QueryClientProvider>
	)
}
