import { useFlags } from 'launchdarkly-react-client-sdk'
import { benefitRoutes } from 'routes/routes-map'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { getBenefitPlanUrl } from 'utils/url'

export const useGetBenefitPlanUrl = () => {
	const { b4b4412_pdp_wizard } = useFlags()

	function getBenefitProductPageLink(bp: BenefitPlanV2) {
		return b4b4412_pdp_wizard
			? benefitRoutes.benefit.plan(bp.benefitPlanId)
			: getBenefitPlanUrl(bp.benefitPlanType, bp.benefitPlanId)
	}

	return getBenefitProductPageLink
}
