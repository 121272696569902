import BlockUI from 'components/block-ui'
import { useTrackLoginEvent } from 'hooks/analytics/login/useTrackLoginEvent'
import { useAppSelector } from 'hooks/redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { siteInfoSelector } from 'store/site/selectors'
import { isLoggedInSelector } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { LocalStore } from 'utils/utils'

const ProtectedRoute = (): JSX.Element => {
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)
	const location = useLocation()
	const { b4b_11274_sso_auto_redirect } = useFlags()
	const siteInfo: SiteInfoResponse = useAppSelector(siteInfoSelector)
	const trackSSOLogin = useTrackLoginEvent()
	// Pseudo Loading
	const [loading, setLoading] = useState<boolean>(true)

	if (!isLoggedIn) {
		LocalStore.set('prevRoute', location.pathname + location.search)

		if (b4b_11274_sso_auto_redirect && siteInfo.properties.ssoEnabled && siteInfo.properties.ssoAuthUrl) {
			trackSSOLogin({
				actionType: 'on leave',
				elementName: 'sso-login',
				elementType: 'page',
				pageName: 'login',
			})
			setTimeout(() => setLoading(false), 2000) // 2 second pseudo load

			if (!loading) window.location.href = siteInfo.properties.ssoAuthUrl

			return <BlockUI shouldBlock showSpinner message='Redirecting you to login page...' />
		}

		return <Navigate to='/' replace />
	} else {
		LocalStore.remove('prevRoute')

		teardownUrlStorage()
	}

	return <Outlet />
}

export default ProtectedRoute

function teardownUrlStorage() {
	LocalStore.remove('returnUrl')
	LocalStore.remove('isImpersonation')
	LocalStore.remove('query')
}
