import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { actions as wizardActions } from 'store/enrollment-wizard/slice'
import { actions as enrollmentActions } from 'store/enrollments/slice'
import { ExternalStoreLoadingStatus } from './types'

export type ExternalStoreState = {
	isRemoveNeeded: boolean
	isSavingNeeded: boolean
	loadingStatus: ExternalStoreLoadingStatus
}

const initialState: ExternalStoreState = {
	isRemoveNeeded: false,
	isSavingNeeded: false,
	loadingStatus: ExternalStoreLoadingStatus.Pending,
}

const externalStoreSlice = createSlice({
	extraReducers: {
		[wizardActions?.saveEnrollmentSuccessRedis.type]: (state: ExternalStoreState): void => {
			state.isSavingNeeded = true
		},
		[wizardActions?.saveEnrollmentError.type]: (state: ExternalStoreState): void => {
			state.isSavingNeeded = true
		},
		[enrollmentActions?.submitEnrollmentSuccessV2.type]: (state: ExternalStoreState): void => {
			state.isRemoveNeeded = true
		},
	},
	initialState,
	name: 'externalStore',
	reducers: {
		setIsRemoveNeeded: (state, action: PayloadAction<boolean>) => {
			state.isRemoveNeeded = action.payload
		},
		setIsSavingNeeded: (state, action: PayloadAction<boolean>) => {
			state.isSavingNeeded = action.payload
		},
		setLoadingStatus: (state, action: PayloadAction<ExternalStoreLoadingStatus>) => {
			state.loadingStatus = action.payload
		},
	},
})

const { actions, reducer } = externalStoreSlice
export { actions, reducer as externalStore }
