import { Draft } from '@reduxjs/toolkit'
import { add, differenceInDays } from 'date-fns'
import { TokenContainers } from './benefit-plans/types'

type WritableDraft<T> = {
	-readonly [K in keyof T]: Draft<T[K]>
}

const parser = new DOMParser()
const originalAttr = 'data-original'

export const createParameterSelector = (selector) => (_, params) => selector(params)
export const getSingleId = (_, id) => id

/**
 * Calculate number of days remaining to make QLE changes
 * @param qleDate User selected date of QLE
 * @param enrollmentQLECount Org specific # of days allowed to make changes after a QLE
 * @returns number of days left to make QLE changes
 */
export function calculateQLEDaysRemaining(qleDate: string, enrollmentQLECount: number): number {
	const lastDay = add(new Date(qleDate), { days: enrollmentQLECount })

	const daysLeft = differenceInDays(lastDay, new Date()) + 1

	// never display more than enrollmentQLECount
	if (daysLeft > enrollmentQLECount) return enrollmentQLECount
	// dont return negative values
	if (daysLeft < 1) return 0

	return daysLeft
}

/**
 * Loops through each of a product template's content containers and replaces
 * calculated token placeholders with real values.
 * @param productTemplate template obj
 * @param templateTokens list of calculated tokens and their values
 * @returns productTemplate with tokens hydrated or hidden
 */
export function hydrateTokens(productTemplate, templateTokens) {
	let template = { ...productTemplate }
	const tokenKeys = Object.keys(templateTokens)
	TokenContainers.forEach((tc) => {
		if (template[tc]?.length) {
			const parsedNode = parser.parseFromString(template[tc], 'text/html')
			traverseParsedHTML(parsedNode.body, (node: HTMLElement | null) => {
				// Ignore element nodes - we only care about nodes that have actual content
				if (node?.nodeValue != null && node?.nodeValue.trim()) {
					const parent = node.parentElement
					const hasOriginalAttr = parent?.hasAttribute(originalAttr)
					/*
					 * If we have updated the token previously we need to use the original node value to know
					 * what part of the string to replace. We should only be using node.nodeValue on first
					 * pass.
					 */
					let targetValue

					if (hasOriginalAttr) {
						const originalValue = parent?.getAttribute(originalAttr)
						const parsedTargetValue = parser.parseFromString(originalValue as string, 'text/html')
						// if the current node has siblings we need to get the correct node from the parent to update
						if ((parent?.childNodes?.length ?? 0) > 1) {
							const index = Array.prototype.indexOf.call(parent?.childNodes, node)

							targetValue = parsedTargetValue.body.childNodes[index].nodeValue
						} else {
							// need to decode html entities
							targetValue = parsedTargetValue.documentElement.textContent
						}
					} else {
						targetValue = node.nodeValue
					}
					const { hasTokenPlaceHolder, updatedNodeValue } = hydrateTokensHelper(targetValue, templateTokens, tokenKeys)
					// set data attr once with token placeholder intact for use in future updates
					if (hasTokenPlaceHolder && !hasOriginalAttr) parent?.setAttribute(originalAttr, parent.innerHTML)
					// hydrateTokensHelper returns '' if the string has a token placeholder but no value to replace it with
					if (updatedNodeValue.length) {
						node.nodeValue = updatedNodeValue
						// keep nodes with placeholders hidden
						if (parent?.classList.contains('hide-token') && updatedNodeValue !== targetValue && hasTokenPlaceHolder) {
							parent?.classList.remove('hide-token')
						}
					} else {
						// update element with hidden class
						parent?.classList.add('hide-token')
					}
				}
			})

			template = { ...template, [tc]: parsedNode.body.innerHTML }
		}
	})

	return template
}

/**
 * Loops through each of the template tokens to see if the current string contains
 * a placeholder value for that token and replace it
 * @param content string to search for token placeholder
 * @param templateTokens list of token objects
 * @param keysArray list of token keys
 * @returns {
 *  hasTokenPlaceHolder: boolean - whether string contains token placeholder or not
 *  updatedNodeValue: string - either an empty string(when token does not have a value)
 *  or a string with token placeholder replaced with the current token value
 * }
 */
function hydrateTokensHelper(content: string, templateTokens, keysArray: string[]) {
	let hasTokenPlaceHolder = false
	let strHolder = content
	// use classic for loop for break functionality
	for (let i = 0; i < keysArray.length; i++) {
		const tokenKey = keysArray[i]
		const tkn = templateTokens[tokenKey]
		// if the token has no value set we need to mark the element as hidden
		if (!tkn.value) {
			// see if the string needs updating or if we can ignore it
			// eslint-disable-next-line no-useless-escape
			hasTokenPlaceHolder = strHolder.includes(`\$\{${tkn.tokenKey}}`)
			strHolder = hasTokenPlaceHolder ? '' : strHolder
			// string has a token that we dont have a value for so we need to hide the parent element
			if (hasTokenPlaceHolder) break
		} else {
			strHolder = strHolder.replace(tkn.tokenRegex, tkn.value)
			// if string was updated we know it had a token placeholder
			hasTokenPlaceHolder = content !== strHolder
		}
	}

	return { hasTokenPlaceHolder, updatedNodeValue: strHolder }
}

/**
 * Traverse each node of the content
 * @param node current node of generated DOM tree
 * @param callback function to call on each node
 */
function traverseParsedHTML(node, callback) {
	callback(node)
	node = node.firstChild
	while (node) {
		traverseParsedHTML(node, callback)
		node = node.nextSibling
	}
}

/**
 *
 * @param array
 * @param keyToNormalizeBy
 * @param hasDuplicateKeys - if its possible to have multiple records with the same key, convert
 * the value for that key into an array when multiple records are found (i.e. pet elections)
 * @returns
 */
export function normalize<T = any>(
	array: Array<T>,
	keyToNormalizeBy: string,
	hasDuplicateKeys: boolean = false,
): Record<number, T> {
	return array.reduce((byId, item) => {
		const currentValRef = byId[item[keyToNormalizeBy]]
		if (hasDuplicateKeys && currentValRef) {
			return Array.isArray(currentValRef)
				? { ...byId, [item[keyToNormalizeBy]]: [...currentValRef, item] }
				: { ...byId, [item[keyToNormalizeBy]]: [currentValRef, item] }
		}

		return { ...byId, [item[keyToNormalizeBy]]: item }
	}, {})
}

/**
 * upsertItem takes a state, an item to add, a key, and a function
 * that checks if two items are equal. If the item is already in the
 * array, it is replaced with the new item. If it is not, it is added
 * to the array.
 * @param state
 * @param item
 * @param key
 * @param equals
 */
export function upsertItem<T, D>(
	state: WritableDraft<T>,
	item: D,
	key: keyof T,
	//only use default for primitives because of referential equality
	equals: (i: D, given: D) => boolean = (i, given) => i === given,
): void {
	const index = (state[key] as Array<D>).findIndex((i) => equals(i, item))

	if (index !== -1) {
		// if it is, replace it with the new item
		;(state[key] as Array<D>).splice(index, 1, item)
	} else {
		// otherwise add the new item
		;(state[key] as Array<D>).push(item)
	}
}

export function upsertWithoutImmer<D>(state: D[], item: D, equals: (i: D, given: D) => boolean): D[] {
	const index = state.findIndex((i) => equals(i, item))

	if (index !== -1) {
		// if it is, replace it with the new item
		state.splice(index, 1, item)
	} else {
		// otherwise add the new item
		state.push(item)
	}

	return state
}
