import { SelectItem } from 'components/select/select'

export enum Gender {
	MALE = 'Male',
	FEMALE = 'Female',
	UNKNOWN = 'Unknown',
	DECLINED = 'Declined to Provide',
	EMPTY = '',
}
export const genders: Gender[] = [Gender.EMPTY, Gender.MALE, Gender.FEMALE]

export const mapGenderToSelectItems = (genders: Gender[]): SelectItem[] => {
	return genders.map((gender) => ({
		label: gender,
		value: gender,
	}))
}

export const mapGenderToArray = (genders: Gender[]): string[] => {
	return genders.map((gender) => gender)
}
