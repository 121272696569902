import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QueryClient } from '@tanstack/react-query'
import { ApplicationStatus } from 'api/benefit-elections'
import { BenefitElectionsService as BenefitElectionsAPI } from 'api/benefit-elections/services'
import { AxiosError } from 'axios'
import { events } from 'config/analytics'
import { appInsights, reactPlugin } from 'config/app-insights'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { PersonalInfo, personalInitState } from 'pages/enrollment-wizard/info/types'
import { formatPhoneNumber } from 'pages/enrollment-wizard/info/util'
import { EnrollmentStatus, Question, SaveEnrollmentV2 } from 'pages/enrollment-wizard/types'
import { WizardErrorModalStatus } from 'pages/enrollment-wizard/wizard/types'
import { isTrustmarkLTC } from 'pages/trustmark-life/utils'
import { USER_FOUND } from 'redux-oidc-js'
import { BenefitElectionsService } from 'services/benefit-elections-service'
import { RateRequestBoolVals } from 'services/benefit-plans.service'
import { EnrollmentService, SaveEnrollmentResponseV2, StartEnrollmentPropsV2 } from 'services/enrollments.service'
import { WorkerService } from 'services/worker.service'
import { actions as benefitPlanActions } from 'store/benefit-plans/slice'
import type { BenefitPlanV2, BenefitPlansState, OEWindowPlan } from 'store/benefit-plans/types'
import { actions as enrollmentActions } from 'store/enrollments/slice'
import { EnrollmentsState } from 'store/enrollments/types'
import { RootState } from 'store/rootReducer'
import { AppThunkAction } from 'store/util-types/types'
import { P, match } from 'ts-pattern'
import { BenefitElectionDTO } from 'types/benefit-election'
import { EC, ES, FA, Tier } from 'types/benefit-plan'
import { BenefitPlanQuestion } from 'types/benefit-questions'
import { CHILD, DependentList, PartialDependent, PartialDependentList, Relationship, SPOUSE } from 'types/dependent'
import EnrollmentEvent from 'types/enrollment-event'
import { Gender } from 'types/genders'
import { PendingTrustmarkEnrollment } from 'types/ltc-enrollment-response'
import {
	EnrolledPet,
	EnrolledPetData,
	PET_MISSING_ZIP,
	PET_PLAN_ID,
	PetBreed,
	PetData,
	PetEnrollmentSaveV2,
	PetPostRes,
	PetSpecies,
	SplitPets,
	petEnrollmentsInitState,
} from 'types/pet'
import { StatusEnum } from 'types/status-enums'
import { ERROR, IDLE, LOADING, SUCCESS } from 'types/status-types'
import { USStatesMapping } from 'types/us-states'
import { EnrollmentRateFrequencyNative, UserEnrollmentRes } from 'types/user-enrollment'
import { formatDate, isAtLeast, isChildAgeValidV2, isYoungerThan } from 'utils/util-dates'
import { trackEvent } from 'utils/utils'
import {
	buildEmptySelections,
	formSelections,
	getSelectedTiers,
	isPlanDeclinedOrCanceled,
	shouldShowDependents,
} from './selectors'
import {
	EnrollmentWizardState,
	RateRequestParams,
	Selections,
	StartEnrollmentError,
	WizardPlanData,
	WizardValidationErrors,
	saveEnrollmentSuccess,
} from './types'
import { getEnrollmentIdFromState } from './util'

/**
 * The current version of the Enrollment Wizard slice.
 * If changes are made to the slice or how data is stored in the slice, we will need to update this number.
 * This prevents old continuations from being loaded in with incompatible data structures.
 */
export const ENROLLMENT_WIZARD_SLICE_VERSION: string = '1.0.0'

type SetDepsPayload = {
	deps: DependentList
	isOE: boolean
	maxChildAge: number
	maxSpouseAge: number
	minChildAge: number
	minSpouseAge: number
	oePlans: BenefitPlanV2[]
	und3484: boolean
}

export const initialState: EnrollmentWizardState = {
	acknowledgedFinalDisclaimer: false,
	activePlan: {} as BenefitPlanV2,
	areAnswersLoaded: false,
	benefitElectionsSubmitError: [],
	confirmDeclined: false,
	decliningInModalStatus: StatusEnum.IDLE,
	dependents: [],
	dependentsInfo: [],
	dependentsLoadError: null,
	dependentsLoadStatus: IDLE,
	enrollmentId: {} as Record<EnrollmentEvent, string>,
	enteredDob: '',
	event: null,
	firstPlanInOrder: -1,
	hasAnsweredKnockout: false,
	hasDobError: false,
	hasSubmissionErrors: false,
	isAddingDep: false,
	isDisplayingSystemErrors: false,
	isEditingRateRequestParameters: false,
	isSavingEnrollment: IDLE,
	isSingleProductFlow: false,
	isStartingEnrollment: IDLE,
	personal: { ...personalInitState },
	petDisclaimer: '',
	petEnrollmentFetchError: null,
	petEnrollmentFetchStatus: IDLE,
	petEnrollments: { ...petEnrollmentsInitState },
	petFrequency: '',
	petInfo: [],
	petRatesFetchStatus: IDLE,
	petSelections: {},
	petSubmissionInfo: [],
	petTotal: null,
	petZip: '',
	planFetchStatus: IDLE,
	planLoadError: null,
	planSelections: {},
	plansFetchStatus: IDLE,
	plansLoadError: null,
	saveEnrollmentError: [],
	savePersonStatus: IDLE,
	savePetError: null,
	savePetStatus: IDLE,
	selectedPlans: [],
	startEnrollmentData: null,
	startEnrollmentError: null,
	startEnrollmentResponse: null,
	submitBenefitElectionsStatus: IDLE,
	trustmarkEnrollment: { pendingTierTitle: undefined, plans: [] },
	validationResults: [],
	version: ENROLLMENT_WIZARD_SLICE_VERSION,
	wizardErrorModalStatus: null,
	wrp2596: false,
}

export const buildEmptyPlanSelections = (): WizardPlanData => ({
	...buildEmptySelections(),
	answeredQuestions: [],
	cancelReason: '',
	editedSinceLastSave: false,
	evidenceOfInsurabilityAgreement: false,
	isCompleted: false,
	isEditing: false,
	isHighlightingRequiredFields: false,
	isIncludingSpouseInfo: false,
	keyDate: undefined,
	planAnswers: {},
	planTiers: [],
	programEnrollmentId: '',
	rateRequestParams: {},
	spouseAnswers: {},
	termsConditionAgreement: false,
})

export const earliestDateReducerFn = (pre, curr): string => {
	const datePre = pre ? Date.parse(pre) : null
	const dateCurr = Date.parse(curr)

	return !datePre || datePre >= dateCurr ? curr : pre
}

const isTierSelected = (currSelections: Selections, newSelections: Selections): boolean =>
	JSON.stringify(currSelections) === JSON.stringify(newSelections)

export const getMinAge = ({
	minChildAge,
	minSpouseAge,
	relationship,
}: {
	minChildAge: any
	minSpouseAge: any
	relationship: Relationship
}) =>
	match(relationship)
		.with(SPOUSE, () => minSpouseAge)
		.with(CHILD, () => minChildAge)
		.exhaustive()
export const getMaxAge = ({
	maxChildAge,
	maxSpouseAge,
	relationship,
}: {
	maxChildAge: any
	maxSpouseAge: any
	relationship: Relationship
}) =>
	match(relationship)
		.with(SPOUSE, () => maxSpouseAge)
		.with(CHILD, () => maxChildAge)
		.exhaustive()

export const mapDep = (dep: PartialDependent, earliestDate, minAge, maxAge) => {
	const isDepOfAge = (birthdate, effectiveDate, relationship): boolean =>
		match(relationship)
			.with(CHILD, () => isChildAgeValidV2(formatDate(birthdate), effectiveDate, maxAge, minAge))
			.otherwise(
				() =>
					isAtLeast(minAge)(formatDate(birthdate), effectiveDate) &&
					isYoungerThan(maxAge)(formatDate(birthdate), effectiveDate),
			)

	const ofAge = earliestDate ? isDepOfAge(dep.birthDate, earliestDate, dep.relationship) : true

	return {
		...dep,
		birthDate: formatDate(dep.birthDate),
		isOfAge: dep?.isOfAge ?? ofAge,
	}
}
const formPet = (): PetData => ({
	age: '',
	coverage: '',
	coverageName: '',
	frequency: '',
	isCancelled: false,
	isCovered: false,
	petGuid: self.crypto.randomUUID(),
	petName: '',
	rates: [],
	species: PetSpecies.NONE,
})

const sanitizeTrueFalseString = (value: string): string =>
	match(value)
		.with('true', () => 'Yes')
		.with('false', () => 'No')
		.otherwise((val) => val)

export const enrollmentWizardSlice = createSlice({
	extraReducers: {
		// update active plans required questions after questions have been loaded
		[benefitPlanActions?.setHasRequiredQuestions?.type]: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ planId: number; requiredQuestionIds: number[] }>,
		): void => {
			if (state.activePlan.benefitPlanId === action.payload.planId && !state.activePlan.requiredQuestions) {
				state.activePlan.requiredQuestions = action.payload.requiredQuestionIds
			}
		},

		// Set the default phone number & employeeDoB based on the user claims
		[USER_FOUND]: (state: EnrollmentWizardState, action): void => {
			state.enteredDob = action.payload.profile.birthdate ? formatDate(action.payload.profile.birthdate) : ''
			state.personal.birthDate = action.payload.profile.birthdate ? formatDate(action.payload.profile.birthdate) : ''
			state.personal.phoneNumber = formatPhoneNumber(action.payload?.profile?.phone_number ?? '')
			state.personal.email = action.payload?.profile?.preferred_username ?? ''
		},
		[enrollmentActions.resetSubmitStatus.type]: (state: EnrollmentWizardState): void => {
			// This will keep the previous date of birth seletions if they exist for a safety net
			state.activePlan = {} as BenefitPlanV2
			state.acknowledgedFinalDisclaimer = false
			state.confirmDeclined = false
			state.dependents = []
			state.dependentsLoadStatus = IDLE
			state.event = null
			state.enrollmentId = {} as Record<EnrollmentEvent, string>
			state.isSavingEnrollment = IDLE
			state.personal.phoneNumber = ''
			state.personal.socialSecurityNumber = ''
			state.petEnrollments = initialState.petEnrollments
			state.petEnrollmentFetchStatus = initialState.petEnrollmentFetchStatus
			state.petFrequency = ''
			state.petInfo = []
			state.petRatesFetchStatus = IDLE
			state.petSelections = {}
			state.petSubmissionInfo = []
			state.petTotal = null
			state.petZip = ''
			state.planFetchStatus = IDLE
			state.plansFetchStatus = IDLE
			state.planSelections = {}
			state.selectedPlans = []
		},
		[enrollmentActions.submitEnrollmentError.type]: (
			state: EnrollmentWizardState,
			action: PayloadAction<any>,
		): void => {
			if (action.payload.response?.status === 400) {
				state.wizardErrorModalStatus = WizardErrorModalStatus.OPEN
				state.isDisplayingSystemErrors = true
			}
		},
		[saveEnrollmentSuccess.toString()]: (
			state: EnrollmentWizardState,
			action: PayloadAction<{
				benefitPlan: BenefitPlanV2 & Partial<WizardPlanData>
				enrollmentId: string
				isSavingPerson?: boolean
				planId: number
				programEnrollmentId: string
				validationResults: Array<WizardValidationErrors>
			}>,
		): void => {
			const { isSavingPerson } = action.payload
			/* DCox - 05-04-21 - We are resetting the fetch status of pets on a successful
			   general enrollment save so that we can have latest state of pets the next
			   time we visit the pet product in the wizard */
			state.petEnrollmentFetchStatus = IDLE
			if (isSavingPerson) {
				state.savePersonStatus = SUCCESS
			}

			const programEnrollmentId = state.planSelections?.[action.payload.planId]?.programEnrollmentId ?? ''

			// checks if there's a program enrollment id but isn't one in curr state
			if (action.payload.programEnrollmentId) {
				state.planSelections[action.payload.planId] = {
					...(state.planSelections?.[action.payload.planId] ?? {}),
					editedSinceLastSave: false,
					programEnrollmentId: programEnrollmentId ? programEnrollmentId : action.payload.programEnrollmentId,
				}
			}
			if (state.event && (action.payload.enrollmentId || state.enrollmentId?.[state.event])) {
				state.enrollmentId[state.event] = state.enrollmentId?.[state.event] ?? action.payload.enrollmentId
			}
			state.validationResults = [...action.payload.validationResults]
			state.isSavingEnrollment = SUCCESS
			state.startEnrollmentData = null
		},
	},
	initialState: { ...initialState },
	name: 'enrollmentWizard',
	reducers: {
		addAnsweredQuestions: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ availablePlans?: BenefitPlanV2[]; benefitPlanId: number; questions: Array<Question> }>,
		): void => {
			if (action.payload.benefitPlanId == null) {
				return
			}

			const hasDobQuestion = action.payload.questions.find((q) => q.fieldDefinitionId === -1)
			if (hasDobQuestion && hasDobQuestion.value !== state.enteredDob) {
				state.enteredDob = hasDobQuestion.value.toString()
				const ciPlan = action.payload?.availablePlans?.find(
					(p) => p.benefitPlanType === 'Critical Illness' && p.benefitPlanId !== action.payload.benefitPlanId,
				)
				// why filter out ci bp plans when someone has entered a new value of DOB?!!??!?!
				if (ciPlan) {
					state.planSelections[ciPlan.benefitPlanId] = {
						...state.planSelections[ciPlan.benefitPlanId],
						...buildEmptySelections(),
					}
					state.selectedPlans = state.selectedPlans.filter((p) => p !== ciPlan.benefitPlanId)
				}
			}

			if (state.planSelections?.[action.payload.benefitPlanId]) {
				// TODO:pdp this block occurs because we had an isDirty state that was
				// TOTALLY unused for both spouse / regular Qs
				// could avoid dispatching entirely if using isDirty properly and avoid complexity here to 'optimize perf'
				const incomingAnswers: Question[] = [...action.payload.questions]

				//Checking if incoming answers are equal to the existing answers.
				//If they are, don't update the state and don't clear the user's current rate selection
				const isIncomingEqualToExistingAnswers: boolean =
					incomingAnswers.length === state.planSelections[action.payload.benefitPlanId]?.answeredQuestions?.length &&
					state.planSelections[action.payload.benefitPlanId].answeredQuestions.every(
						(answer, index) =>
							// why only change values for  these 2 fields? is this because our local copy only has
							// those two fields populated and everything else is overridden?
							// using spread operator here would have made it clearer that was what was happening even
							// if it didn't really need to be done
							incomingAnswers[index].fieldDefinitionId === answer.fieldDefinitionId &&
							incomingAnswers[index].value === answer.value,
					)

				if (isIncomingEqualToExistingAnswers) return

				state.planSelections[action.payload.benefitPlanId] = {
					...buildEmptySelections(),
					...state.planSelections[action.payload.benefitPlanId],
					answeredQuestions: incomingAnswers,
				}
			} else {
				state.planSelections[action.payload.benefitPlanId] = {
					...buildEmptyPlanSelections(),
					answeredQuestions: action.payload.questions,
				}
			}
		},
		addAnsweredSpouseQuestions: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ planId: number; answers: Record<string, any> }>,
		): void => {
			state.planSelections[action.payload.planId] = {
				...state.planSelections[action.payload.planId],
				spouseAnswers: action.payload.answers,
			}
		},
		/**
		 * Instead of calling this action, consider calling declineOrCancelBenefitPlan.
		 *
		 * declineOrCancelBenefitPlan will automatically handle choosing declined or canceled for a plan.
		 */
		addCanceledPlan: (state: EnrollmentWizardState, action: PayloadAction<number>): void => {
			state.selectedPlans = state.selectedPlans.filter((selectedPlanId: number) => selectedPlanId !== action.payload)
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				enrollmentStatus: EnrollmentStatus.CANCELED,
				isHighlightingRequiredFields: false,
				termsConditionAgreement: false,
			}
		},
		/**
		 * Instead of calling this action, consider calling declineOrCancelBenefitPlan.
		 *
		 * declineOrCancelBenefitPlan will automatically handle choosing declined or canceled for a plan.
		 */
		addDeclinedPlan: (state: EnrollmentWizardState, action: PayloadAction<number>): void => {
			// TODO: Figure out if they are declined or cancelled
			state.selectedPlans = state.selectedPlans.filter((selectedPlanId: number) => selectedPlanId !== action.payload)
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				...buildEmptySelections(),
				enrollmentStatus: EnrollmentStatus.DECLINED,
				isHighlightingRequiredFields: false,
				termsConditionAgreement: false,
			}
		},
		addDependentInfo: (state: EnrollmentWizardState, action: PayloadAction<PartialDependent>): void => {
			if (action.payload.relationship === SPOUSE) {
				state.dependentsInfo = [action.payload, ...state.dependentsInfo]
			} else {
				state.dependentsInfo.push(action.payload)
			}
		},
		addDependentsInfoList: (state: EnrollmentWizardState, action: PayloadAction<PartialDependentList>): void => {
			state.dependentsInfo = [...action.payload]
		},
		addExistingPets: (state: EnrollmentWizardState, action: PayloadAction<PetData[]>) => {
			const oldPetInfo = action.payload.filter(
				(fetchedPet) => !state.petInfo.find((alreadyIncludedPet) => fetchedPet.petGuid === alreadyIncludedPet.petGuid),
			)
			state.petInfo = [...state.petInfo, ...oldPetInfo]
		},
		addLeavedPlan: (state: EnrollmentWizardState, action: PayloadAction<number>) => {
			state.selectedPlans = state.selectedPlans.filter((planId: number) => planId !== action.payload)
			state.planSelections[action.payload].enrollmentStatus = EnrollmentStatus.LEAVED
		},
		addPet: (state: EnrollmentWizardState) => {
			state.petInfo.push(formPet())
		},
		addPets: (state: EnrollmentWizardState, action: PayloadAction<number>) => {
			let count = 0
			const emptyArray: PetData[] = []
			while (count < action.payload) {
				emptyArray.push(formPet())
				count++
			}

			state.petInfo.push(...emptyArray)
		},
		// Coverage and tier are populated to bypass validation in Step 2 for Pet and Trustmark
		addSelectedPlan: (state: EnrollmentWizardState, action: PayloadAction<number>) => {
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				...buildEmptySelections(),
				coverage: 'selected',
				enrollmentStatus: EnrollmentStatus.NEW,
				tier: Tier.EO,
			}
			state.selectedPlans = [...new Set([...state.selectedPlans, action.payload])]
		},
		addingDep: (state: EnrollmentWizardState, action: PayloadAction<boolean>) => {
			state.isAddingDep = action.payload
		},
		clearActivePlan: (state) => {
			state.activePlan = initialState.activePlan
		},
		clearFromSelectedPlans: (state, action) => {
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				...buildEmptySelections(),
			}
			state.selectedPlans = state.selectedPlans.filter((p) => p !== action.payload)
		},
		clearSelectionsByPlan: (state, action) => {
			state.planSelections[action.payload] = {
				...buildEmptyPlanSelections(),
				keyDate: state.planSelections?.[action.payload]?.keyDate ?? '',
				termsConditionAgreement: state.planSelections?.[action.payload]?.termsConditionAgreement,
			}
		},
		fetchPetEnrollmentsError(state, action: PayloadAction<{ error: string[] }>) {
			state.petEnrollmentFetchError = new Error(action.payload.error.join(' '))
			state.petEnrollmentFetchStatus = ERROR
		},
		fetchPetEnrollmentsSuccess(state, action: PayloadAction<{ enrolledPetData: EnrolledPetData; splitPets: any }>) {
			state.petEnrollmentFetchStatus = SUCCESS
			// frequency strings may contain numbers at the end, we only care about the first 2 letters
			state.petEnrollments = {
				...action.payload.enrolledPetData,
				frequency: action.payload.enrolledPetData.frequency.substring(0, 2),
				splitPets: action.payload.splitPets,
			}
		},
		fetchingPetEnrollments: (state: EnrollmentWizardState): void => {
			state.petEnrollmentFetchStatus = LOADING
		},
		hasDobError: (state: EnrollmentWizardState, action: PayloadAction<boolean>): void => {
			state.hasDobError = action.payload
		},
		hydrateQuestions: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ benefitPlanId: number; employeeDob?: string; questions: Array<BenefitPlanQuestion> }>,
		): void => {
			const { benefitPlanId, employeeDob, questions } = action.payload
			const employeeDobQuestion = questions.find((question) => question.questionName === 'employee-dob')

			if (
				employeeDobQuestion &&
				employeeDob &&
				!state.planSelections[benefitPlanId]?.planAnswers[employeeDobQuestion.questionId]
			) {
				const planSelections = state.planSelections[benefitPlanId]
				if (!planSelections) {
					state.planSelections[benefitPlanId] = buildEmptyPlanSelections()
				}
				state.planSelections[benefitPlanId].planAnswers[employeeDobQuestion.questionId] = {
					questionName: employeeDobQuestion.questionName,
					value: employeeDob,
				}
			}
		},
		loadPetData: (state: EnrollmentWizardState, action: PayloadAction<PetData[]>) => {
			state.petInfo = action.payload
		},
		loadingDependents: (state: EnrollmentWizardState): void => {
			state.dependentsLoadStatus = LOADING
		},
		populateSliceFromExternalStore: (
			state: EnrollmentWizardState,
			action: PayloadAction<Partial<EnrollmentWizardState>>,
		): EnrollmentWizardState => {
			if (state.version !== action.payload.version) {
				return state
			}

			return { ...state, ...action.payload, firstPlanInOrder: -1 }
		},
		removeAllPets: (state: EnrollmentWizardState): void => {
			state.petInfo = initialState.petInfo
			state.petEnrollments = initialState.petEnrollments
		},
		removeDependent: (state: EnrollmentWizardState, { payload }: PayloadAction<number>): void => {
			state.dependentsInfo = state.dependentsInfo.filter((_, index) => index !== payload)
		},
		removePets: (
			state: EnrollmentWizardState,
			{ payload }: PayloadAction<{ index: number; numToRemove?: number }>,
		): void => {
			state.petInfo.splice(payload.index, payload.numToRemove ?? 1)
		},
		removeSelectedPlan: (state: EnrollmentWizardState, action: PayloadAction<number>): void => {
			state.selectedPlans = state.selectedPlans.filter((x) => x !== action.payload)
		},
		resetBenefitElectionsSubmitStatus(state: EnrollmentWizardState): void {
			state.submitBenefitElectionsStatus = IDLE
		},
		resetFromKnockout: (state: EnrollmentWizardState, { payload }: PayloadAction<number[]>): void => {
			state.selectedPlans = state.selectedPlans.filter((x) => !payload.includes(x))

			const updatedPlanSelections = {}

			const buildFilteredArray = (item, payload: number[]): number[] =>
				Object.keys(item)
					.map(Number)
					.filter((x) => !payload.includes(x))

			buildFilteredArray(state.planSelections, payload).forEach((x) => {
				updatedPlanSelections[x] = { ...state.planSelections[x], answeredQuestions: [], isEditing: false }
			})

			state.planSelections = { ...updatedPlanSelections }
		},
		resetPetEnrollments: (state: EnrollmentWizardState): void => {
			state.petEnrollments = initialState.petEnrollments
			state.petEnrollmentFetchStatus = initialState.petEnrollmentFetchStatus
		},
		resetPlanBackToEnrolled: (state: EnrollmentWizardState, action: PayloadAction<number>): void => {
			state.selectedPlans = [...state.selectedPlans, action.payload]
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				enrollmentStatus: EnrollmentStatus.ENROLLED,
				isHighlightingRequiredFields: false,
				termsConditionAgreement: false,
			}
		},
		saveEnrollmentError(state: EnrollmentWizardState, action: PayloadAction<string[]>): void {
			state.saveEnrollmentError = action.payload
			state.isSavingEnrollment = ERROR
		},
		saveEnrollmentSuccessRedis(state: EnrollmentWizardState): void {
			state.petEnrollmentFetchStatus = IDLE
			state.isSavingEnrollment = SUCCESS
		},
		savePetError(state: EnrollmentWizardState, action: PayloadAction<{ error: Error }>): void {
			state.savePetError = action.payload.error
			state.savePetStatus = ERROR
		},
		savePetLoading(state: EnrollmentWizardState) {
			state.savePetStatus = LOADING
		},
		savePetSuccess(state, action: PayloadAction<any>) {
			state.savePetStatus = SUCCESS
			state.petInfo = state.petInfo.map((pet) => {
				const apiPet = action.payload.pets.find(({ petGuid }) => petGuid === pet.petGuid)
				if (apiPet) {
					// update amount for pets that already had coverage(rate can change for selected coverage)
					const hasCoverage = pet.coverage ? apiPet.rates.find((rate) => rate.coverage === pet.coverage) : null

					return {
						...pet,
						coverageAmount: hasCoverage ? hasCoverage.amount : 0,
						rates: apiPet.rates,
					}
				}

				return pet
			})
			// frequency strings may contain numbers at the end, we only care about the first 2 letters
			state.petFrequency = action.payload.frequency.substring(0, 2)
			state.petDisclaimer = action.payload.disclaimer
		},
		savingEnrollment(state: EnrollmentWizardState): void {
			state.isSavingEnrollment = LOADING
		},
		savingEnrollmentIdle(state: EnrollmentWizardState): void {
			state.isSavingEnrollment = IDLE
		},
		savingEnrollmentSuccessWithoutSubmission(state: EnrollmentWizardState): void {
			state.isSavingEnrollment = SUCCESS
		},
		savingPersons(state: EnrollmentWizardState): void {
			state.savePersonStatus = LOADING
		},
		savingPersonsError(state: EnrollmentWizardState): void {
			state.savePersonStatus = ERROR
		},
		setActivePlan: (state: EnrollmentWizardState, action: PayloadAction<BenefitPlanV2>): void => {
			if (action.payload && action.payload.benefitPlanId) {
				const plan = { ...action.payload }
				state.activePlan = plan
				if (state.isSingleProductFlow) {
					state.selectedPlans = []
					state.planSelections = {}
				}
				state.planSelections[plan.benefitPlanId] = {
					...buildEmptyPlanSelections(),
					...(state.planSelections?.[plan.benefitPlanId] ?? {}),
					keyDate: state.planSelections?.[plan.benefitPlanId]?.keyDate ?? (plan.keyDate as string),
					programEnrollmentId: state.planSelections?.[plan.benefitPlanId]?.programEnrollmentId ?? '',
					termsConditionAgreement:
						plan.programId === PET_PLAN_ID ||
						plan?.effectiveMarketingContent?.productTemplate?.termsAndConditionsText?.length > 0
							? state.planSelections?.[plan.benefitPlanId]?.termsConditionAgreement ?? false
							: true,
				}
			}
		},
		setAnsweredKnockout: (state: EnrollmentWizardState, action: PayloadAction<boolean>): void => {
			state.hasAnsweredKnockout = action.payload
		},
		setAnswersLoaded: (state: EnrollmentWizardState, action: PayloadAction<boolean>) => {
			state.areAnswersLoaded = action.payload
		},
		setCompletedPlan: (state: EnrollmentWizardState, action: PayloadAction<number>) => {
			state.planSelections[action.payload].isCompleted = true
		},
		setDecliningStatus: (state: EnrollmentWizardState, action: PayloadAction<StatusEnum>) => {
			state.decliningInModalStatus = action.payload
		},
		setDependents: (state: EnrollmentWizardState, action: PayloadAction<SetDepsPayload>): void => {
			const { maxChildAge, maxSpouseAge, minChildAge, minSpouseAge } = action.payload
			const earliestDate = Object.values(state.planSelections)
				.map((plan) => plan.keyDate)
				.filter((x) => x)
				.reduce(earliestDateReducerFn, formatDate(new Date()))
			const sortedDeps = [...action.payload.deps].sort((a, b) => {
				if (b.relationship > a.relationship) return 1
				if (b.relationship < a.relationship) return -1

				return 0
			})

			const dependents = sortedDeps.length
				? sortedDeps
						.filter((dep) => (action.payload.und3484 ? dep : dep.isActive))
						.map((dep) => ({
							...dep,
							birthDate: formatDate(dep.birthDate),
							gender: dep.gender === Gender.UNKNOWN ? Gender.DECLINED : dep.gender,
						}))
				: state.dependentsInfo.map((dep) => ({
						...dep,
						gender: dep?.gender === Gender.UNKNOWN ? Gender.DECLINED : dep?.gender ?? Gender.EMPTY,
				  }))

			state.dependentsInfo = dependents.map((dep) => {
				const minAge = getMinAge({ minChildAge, minSpouseAge, relationship: dep.relationship })
				const maxAge = getMaxAge({ maxChildAge, maxSpouseAge, relationship: dep.relationship })

				return mapDep(dep, earliestDate, minAge, maxAge)
			})
			state.dependents = dependents as DependentList
			state.dependentsLoadStatus = SUCCESS
		},
		setDepsError: (state: EnrollmentWizardState, action: PayloadAction<Error>): void => {
			state.dependentsLoadError = action.payload
			state.dependentsLoadStatus = ERROR
		},
		setEnrollmentIdForEvent: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ event: EnrollmentEvent; enrollmentId: string }>,
		) => {
			state.enrollmentId[action.payload.event] = action.payload.enrollmentId
		},
		setEnteredDob: (state: EnrollmentWizardState, action: PayloadAction<string>) => {
			state.enteredDob = action.payload
		},
		setEvent: (
			state: EnrollmentWizardState,
			action: PayloadAction<{
				event: EnrollmentEvent
				isSingleProductFlow: boolean
			}>,
		) => {
			const { event, isSingleProductFlow } = action.payload
			state.event = event
			state.isSingleProductFlow = isSingleProductFlow
		},
		setFirstPlanInOrder: (state: EnrollmentWizardState, action: PayloadAction<number>) => {
			state.firstPlanInOrder = action.payload
		},
		setIsEditingPlan: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ isEditing: boolean; planId: number }>,
		): void => {
			state.planSelections[action.payload.planId] = {
				...buildEmptyPlanSelections(),
				...state.planSelections[action.payload.planId],
				isEditing: action.payload.isEditing,
			}
		},
		setIsEditingPlanWithoutClearingSelections: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ isEditing: boolean; planId: number }>,
		): void => {
			state.planSelections[action.payload.planId] = {
				...state.planSelections[action.payload.planId],
				isEditing: action.payload.isEditing,
			}
		},
		setIsEditingRateRequestParameters: (state: EnrollmentWizardState, action: PayloadAction<boolean>): void => {
			state.isEditingRateRequestParameters = action.payload
		},
		setIsHighlightingRequiredFields: (state: EnrollmentWizardState, action: PayloadAction<boolean>): void => {
			state.planSelections[state.activePlan.benefitPlanId] = {
				...state.planSelections[state.activePlan.benefitPlanId],
				isHighlightingRequiredFields: action.payload,
			}
		},
		setIsIncludingSpouseInfo: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ isIncludingSpouseInfo: boolean; planId: number }>,
		) => {
			const { planId, isIncludingSpouseInfo } = action.payload
			if (!state.planSelections[planId]) return

			state.planSelections[planId].isIncludingSpouseInfo = isIncludingSpouseInfo
		},
		setPetCoverage: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ amount: number; coverage: string; coverageName: string; index: number }>,
		) => {
			const { amount, coverage, coverageName, index } = action.payload
			if (state.petInfo[index]) {
				state.petInfo[index].coverage = coverage
				state.petInfo[index].coverageAmount = amount
				state.petInfo[index].coverageName = coverageName
			}
		},
		setPetData: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ field: string; index: number; value: string | null }>,
		) => {
			const { field, index, value } = action.payload
			if (state.petInfo[index]) {
				state.petInfo[index][field] = value
			}
		},
		setPetRatesFetchStatus: (state: EnrollmentWizardState, action: PayloadAction<StateMachineStatus>) => {
			state.petRatesFetchStatus = action.payload
		},
		setPetTotal: (state: EnrollmentWizardState, action: PayloadAction<number | null>) => {
			state.petTotal = action.payload
		},
		setPetZip: (state: EnrollmentWizardState, action: PayloadAction<string>) => {
			state.petZip = action.payload
		},
		setPlanCancelReason: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ planId: number; cancelReason: string }>,
		) => {
			state.planSelections[action.payload.planId] = {
				...state.planSelections[action.payload.planId],
				cancelReason: action.payload.cancelReason,
			}
		},
		setPlanSelectionEnrollmentStatus: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ id: number; status: EnrollmentStatus | undefined }>,
		) => {
			state.planSelections[action.payload.id] = {
				...state.planSelections[action.payload.id],
				enrollmentStatus: action.payload.status,
			}
		},
		setSelectedTier: (state: EnrollmentWizardState, action: PayloadAction<Selections>): void => {
			const { amount, coverage, coverageId, frequency, tier, tierId } = action.payload
			// Remove selected plan from declined plans
			const id = state.activePlan.benefitPlanId
			if (isTierSelected(formSelections(state.planSelections[id], id), action.payload)) {
				state.planSelections[id] = {
					...state.planSelections[id],
					...buildEmptySelections(),
					editedSinceLastSave: true,
				}
				state.selectedPlans = state.selectedPlans.filter((planId) => planId !== id)
			} else {
				state.planSelections[id] = {
					...state.planSelections[id],
					...formSelections({ amount, coverage, coverageId, frequency, tier, tierId } as WizardPlanData, id),
					editedSinceLastSave: true,
				}
				state.selectedPlans = [...new Set([...state.selectedPlans, id])]
			}
			state.planSelections[id].termsConditionAgreement =
				state.activePlan.effectiveMarketingContent.productTemplate.termsAndConditionsText == null
					? true
					: state.planSelections[id]?.termsConditionAgreement ?? false
			state.planSelections[id].enrollmentStatus = EnrollmentStatus.NEW
		},
		setStartEnrollmentData: (state: EnrollmentWizardState, action: PayloadAction<StartEnrollmentPropsV2>): void => {
			state.startEnrollmentData = action.payload
		},
		setTrustmarkEnrollment: (state: EnrollmentWizardState, action: PayloadAction<PendingTrustmarkEnrollment>) => {
			state.trustmarkEnrollment = action.payload
		},
		setWrp2596: (state: EnrollmentWizardState, action: PayloadAction<boolean>) => {
			state.wrp2596 = action.payload
		},
		startEnrollmentError(state: EnrollmentWizardState, action: PayloadAction<StartEnrollmentError>): void {
			state.startEnrollmentError = action.payload
			state.isStartingEnrollment = ERROR
		},
		startEnrollmentReset(state: EnrollmentWizardState): void {
			state.startEnrollmentError = null
			state.isStartingEnrollment = IDLE
			state.startEnrollmentResponse = null

			state.personal.socialSecurityNumber = ''
		},
		startEnrollmentSuccess(
			state: EnrollmentWizardState,
			action: PayloadAction<{ event: EnrollmentEvent; planId?: number; res: SaveEnrollmentResponseV2 }>,
		): void {
			state.isStartingEnrollment = SUCCESS
			state.startEnrollmentError = null
			const { res } = action.payload
			state.startEnrollmentResponse = res
			state.enrollmentId[action.payload.event] = action.payload.res.enrollmentId
			// if the event is not the state event, change it so we grab the correct enrollment id
			if (action.payload.event !== state.event) {
				state.event = action.payload.event
			}
			if (!action.payload.planId) {
				return
			}

			if (!state.planSelections[action.payload.planId].programEnrollmentId) {
				state.planSelections[action.payload.planId].programEnrollmentId = res.programEnrollmentId
			}
		},
		startingEnrollment(state: EnrollmentWizardState): void {
			state.isStartingEnrollment = LOADING
		},
		submitBenefitElectionsError(state: EnrollmentWizardState, action: PayloadAction<AxiosError>): void {
			const { payload: error } = action
			const errorMessage: string[] = []
			if (error.response?.data && Array.isArray(error.response.data)) errorMessage.push(...error.response.data)
			else if (error.response?.data) errorMessage.push(error.response.data as string)

			state.benefitElectionsSubmitError = errorMessage
			state.submitBenefitElectionsStatus = ERROR
		},
		submitBenefitElectionsSuccess(state: EnrollmentWizardState): void {
			state.submitBenefitElectionsStatus = SUCCESS
		},
		submittingBenefitElections(state: EnrollmentWizardState): void {
			state.submitBenefitElectionsStatus = LOADING
		},
		toggleDeclinedConfirmation: (state: EnrollmentWizardState): void => {
			state.confirmDeclined = !state.confirmDeclined
		},
		toggleDisclaimerAcknowledgement: (state: EnrollmentWizardState): void => {
			state.acknowledgedFinalDisclaimer = !state.acknowledgedFinalDisclaimer
		},
		toggleEOIAgreement: (state: EnrollmentWizardState): void => {
			state.planSelections[state.activePlan.benefitPlanId].evidenceOfInsurabilityAgreement =
				!state.planSelections[state.activePlan.benefitPlanId].evidenceOfInsurabilityAgreement
		},
		toggleTermsConditionAgreement: (state: EnrollmentWizardState): void => {
			if (state.activePlan.benefitPlanId) {
				state.planSelections[state.activePlan.benefitPlanId].termsConditionAgreement =
					!state.planSelections[state.activePlan.benefitPlanId].termsConditionAgreement
			}
		},
		uncheckTermsConditionAgreement: (state: EnrollmentWizardState): void => {
			if (state.activePlan.benefitPlanId) {
				state.planSelections[state.activePlan.benefitPlanId].termsConditionAgreement = false
			}
		},
		updateAnswers: (
			state: EnrollmentWizardState,
			action: PayloadAction<{
				name: string
				questionId: string
				value: string
			}>,
		): void => {
			const { questionId, name, value } = action.payload
			const planId = state.activePlan.benefitPlanId
			const planSelections = state.planSelections[planId]
			if (!planSelections) {
				state.planSelections[planId] = buildEmptyPlanSelections()
			}
			state.planSelections[planId].planAnswers[questionId] = {
				questionName: name,
				value,
			}
		},
		// TODO:pdp - move this into new slice that will house answers going forward
		updateAnswersByBenefitPlanId: (
			state: EnrollmentWizardState,
			action: PayloadAction<{
				planId: number
				name: string
				questionId: string
				value: string
			}>,
		): void => {
			const { questionId, name, value } = action.payload
			const planSelections = state.planSelections[action.payload.planId]
			if (!planSelections) {
				state.planSelections[action.payload.planId] = buildEmptyPlanSelections()
			}
			state.planSelections[action.payload.planId].planAnswers[questionId] = {
				questionName: name,
				value,
			}
		},

		/**
		 * TODO:pdp move this to hook with useCallback handler.
		 *
		 * - BP data should come from useBenefits() react query data,
		 * - handler should contain all logic and action should be simply finding dependentInfo
		 * record to update and set that value
		 *    - makes things much easier to debug rather than hiding it in redux action
		 */
		updateDependentInfo: (
			state: EnrollmentWizardState,
			action: PayloadAction<{
				benefitPlans: BenefitPlanV2[]
				index: number
				name: string
				shouldIgnoreChildAge: boolean
				value: string
			}>,
		): void => {
			const filteredPlans = action.payload.benefitPlans.filter(
				(plan) =>
					(plan?.minChildAge ?? -1) >= 0 &&
					(plan?.maxChildAge ?? -1) >= 0 &&
					(plan?.maxSpouseAge ?? -1) >= 0 &&
					(plan?.minSpouseAge ?? -1) >= 0,
			)
			const minChildAge = filteredPlans.reduce((prev, curr) => Math.min(prev, curr.minChildAge ?? 0), 0)
			const maxChildAge = filteredPlans.reduce(
				(prev, curr) => Math.min(prev, curr.maxChildAge as number),
				filteredPlans[0].maxChildAge as number,
			)
			const maxSpouseAge = filteredPlans.reduce((prev, curr) => Math.min(prev, curr.maxSpouseAge ?? 99), 99)
			const minSpouseAge = filteredPlans.reduce((prev, curr) => Math.min(prev, curr.minSpouseAge ?? 18), 18)

			if (
				(action.payload.shouldIgnoreChildAge &&
					action.payload.name === 'isDisabled' &&
					!isNaN(new Date(state.dependentsInfo[action.payload.index].birthDate).getTime())) ||
				(action.payload.name === 'birthDate' && !isNaN(new Date(action.payload.value).getTime()))
			) {
				const planDates: Array<string> = Object.values(state.selectedPlans).map(
					(planId): string => state.planSelections?.[planId]?.keyDate?.toString() ?? '',
				)
				/*
          If no plans are selected use the active plan's effective date. This ensures
          we have a benchmark date for age tests when no plans have been selected.
        */
				const earliestDate: string = planDates.length
					? planDates.reduce(earliestDateReducerFn, '')
					: state.activePlan?.keyDate?.toString() ?? ''

				let isDepOfAge

				if (state.dependentsInfo[action.payload.index].relationship === CHILD) {
					if (
						((action.payload.name === 'isDisabled' && action.payload.value) ||
							(action.payload.name !== 'isDisabled' && state.dependentsInfo[action.payload.index].isDisabled)) &&
						action.payload.shouldIgnoreChildAge
					) {
						isDepOfAge = true
					} else {
						isDepOfAge = isChildAgeValidV2(
							action.payload.name === 'isDisabled' && action.payload.shouldIgnoreChildAge
								? formatDate(state.dependentsInfo[action.payload.index].birthDate)
								: action.payload.value,
							earliestDate,
							maxChildAge,
							minChildAge,
						)
					}
				} else {
					isDepOfAge =
						isAtLeast(minSpouseAge)(formatDate(action.payload.value), earliestDate) &&
						isYoungerThan(maxSpouseAge)(formatDate(action.payload.value), earliestDate)
				}

				state.dependentsInfo[action.payload.index].isOfAge = isDepOfAge
			}
			state.dependentsInfo[action.payload.index][action.payload.name] = action.payload.value
		},
		updatePersonal: (state: EnrollmentWizardState, action: PayloadAction<PersonalInfo>) => {
			state.personal = { ...action.payload }
		},
		updatePersonalAddressValues: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ name: string; value: string }>,
		): void => {
			state.personal.address[action.payload.name] = action.payload.value
		},
		updatePersonalValues: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ name: string; value: string }>,
		): void => {
			state.personal[action.payload.name] = action.payload.value
		},
		updatePetSubmissionInfo: (state: EnrollmentWizardState, action: PayloadAction<string[]>): void => {
			state.petSubmissionInfo = action.payload
		},
		updatePetWellness: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ amount: number; coverage: string | null; coverageName: string; index: number }>,
		) => {
			const { amount, coverage, coverageName, index } = action.payload
			if (state.petInfo[index]) {
				state.petInfo[index].hasWellness = !state.petInfo[index].hasWellness
				// need to update amount and coverage selections
				state.petInfo[index].coverage = coverage ?? ''
				state.petInfo[index].coverageAmount = amount
				state.petInfo[index].coverageName = coverageName
			}
		},
		/**
		 * Updates the planSelections EnrollmentStatus. If the user skips a plan the second time through
		 * the wizard, it will still get picked up by the Submit method since we filled in the enrollment status
		 */
		updatePlanSelections: (state: EnrollmentWizardState, action: PayloadAction<BenefitPlanV2>): void => {
			// Create an empty selection and add to planselection
			if (!state.planSelections[action.payload.benefitPlanId])
				state.planSelections[action.payload.benefitPlanId] = {
					...buildEmptyPlanSelections(),
					enrollmentStatus:
						action.payload.enrollmentStatus === EnrollmentStatus.ENROLLED
							? EnrollmentStatus.LEAVED
							: action.payload.enrollmentStatus,
					isCompleted: true,
					termsConditionAgreement: true,
				}
			// Update an existing selection. Needed to update the first plan in the wizard
			else if (state.planSelections[action.payload.benefitPlanId]) {
				state.planSelections[action.payload.benefitPlanId] = {
					...state.planSelections[action.payload.benefitPlanId],
					enrollmentStatus:
						action.payload.enrollmentStatus === EnrollmentStatus.ENROLLED
							? EnrollmentStatus.LEAVED
							: action.payload.enrollmentStatus,
					isCompleted: true,
					termsConditionAgreement: true,
				}
			}
		},
		updateRatesParams: (state: EnrollmentWizardState, action: PayloadAction<RateRequestBoolVals>) => {
			const { benefitPlanId } = action.payload
			const rateRequestParams: RateRequestParams = {
				employeeIsSmoker: action.payload.employeeIsSmoker,
				spouseIsSmoker: action.payload.spouseIsSmoker,
			}
			if (state.planSelections[benefitPlanId]) {
				state.planSelections[benefitPlanId].rateRequestParams = rateRequestParams
			}
		},
		updateWizardErrorModalStatus: (
			state: EnrollmentWizardState,
			action: PayloadAction<WizardErrorModalStatus | null>,
		): void => {
			state.wizardErrorModalStatus = action.payload
			if (action.payload === WizardErrorModalStatus.CLOSED || action.payload === null) {
				state.isDisplayingSystemErrors = false
			}
			if (action.payload === WizardErrorModalStatus.OPEN) {
				state.hasSubmissionErrors = true
			}
		},
	},
})

const { actions, reducer } = enrollmentWizardSlice
export { actions, reducer as enrollmentWizard }

const formatLeavedState = (
	userEnrollment: UserEnrollmentRes,
	isDeclined: boolean,
	enrollmentId: string = '',
	programEnrollmentId: string = '',
	enrollmentEvent: EnrollmentEvent,
	benefitPlan: BenefitPlanV2,
	isSingleProductFlow: boolean,
	effectiveDate?: Date,
	confirmedDisclaimer?: string,
):
	| Omit<SaveEnrollmentV2, 'amount'>
	| Omit<SaveEnrollmentV2, 'amount' | 'frequency' | 'tier' | 'coverage' | 'coverageId' | 'tierId'> => {
	if (isDeclined) {
		return {
			benefitPlanId: benefitPlan.benefitPlanId,
			confirmedDisclaimer: confirmedDisclaimer ?? '',
			effectiveDate: effectiveDate as Date,
			enrollmentEvent: enrollmentEvent as EnrollmentEvent,
			enrollmentId,
			isSingleProductFlow,
			programEnrollmentId,
			programId: benefitPlan.programId,
			questions: [],
			statusCode: EnrollmentStatus.CANCELED,
		}
	}

	return {
		benefitPlanId: benefitPlan.benefitPlanId,
		confirmedDisclaimer: '',
		coverage: userEnrollment.coverage,
		coverageId: '',
		effectiveDate: new Date(Date.parse(userEnrollment.planEffectiveDate)),
		enrollmentEvent,
		enrollmentId,
		frequency: userEnrollment.rateFrequency,
		isSingleProductFlow,
		programEnrollmentId,
		programId: benefitPlan.programId,
		questions: [],
		statusCode: EnrollmentStatus.LEAVED,
		tier: userEnrollment.tier,
		tierId: '',
	}
}

const formatCanceled = (
	state: Pick<
		EnrollmentWizardState & { planId: number; programId: number },
		'event' | 'planId' | 'enrollmentId' | 'planSelections' | 'programId' | 'isSingleProductFlow'
	>,
	effectiveDate: Date | string,
	enrollmentId?: string,
): Omit<SaveEnrollmentV2, 'frequency' | 'tier' | 'coverage' | 'coverageId' | 'tierId'> => ({
	amount: 0,
	benefitPlanId: state.planId,
	confirmedDisclaimer: '',
	effectiveDate: new Date(effectiveDate),
	enrollmentEvent: state.event as EnrollmentEvent,
	enrollmentId: enrollmentId ?? getEnrollmentIdFromState(state),
	isSingleProductFlow: state.isSingleProductFlow,
	programEnrollmentId: state.planSelections[state.planId].programEnrollmentId ?? '',
	programId: state.programId,
	questions: [],
	statusCode: EnrollmentStatus.CANCELED,
})

const formatDeclined = (
	state: Pick<
		EnrollmentWizardState & { planId: number; programId: number },
		'event' | 'planId' | 'enrollmentId' | 'planSelections' | 'programId' | 'isSingleProductFlow'
	>,
	effectiveDate: Date | string,
	enrollmentId?: string,
): Omit<SaveEnrollmentV2, 'frequency' | 'tier' | 'coverage' | 'coverageId' | 'tierId'> => ({
	amount: 0,
	benefitPlanId: state.planId,
	confirmedDisclaimer: '',
	effectiveDate: new Date(effectiveDate),
	enrollmentEvent: state.event as EnrollmentEvent,
	enrollmentId: enrollmentId ?? getEnrollmentIdFromState(state),
	isSingleProductFlow: state.isSingleProductFlow,
	programEnrollmentId: state.planSelections[state.planId].programEnrollmentId ?? '',
	programId: state.programId,
	questions: [],
	statusCode: EnrollmentStatus.DECLINED,
})

const formatIntermediateSave = (
	state: EnrollmentWizardState & { planId: number; programId: number },
	overrideEvent?: EnrollmentEvent,
):
	| SaveEnrollmentV2
	| Omit<SaveEnrollmentV2, 'amount' | 'frequency' | 'tier' | 'coverage' | 'coverageId' | 'tierId'> => {
	const isNWPet = state.activePlan?.programId === PET_PLAN_ID

	const questions = state?.planSelections[state.planId]?.answeredQuestions?.map((question) => ({
		...question,
		value: sanitizeYesNo(question.value?.toString() ?? ''),
	}))

	// find if dob question exists, if not, then add it, regardless of whether we need it
	// backend tosses this out, so no harm no foul
	// TODO: we can remove this when we use the new Questions structure.
	const dobQuestion = questions?.find((question) => question.fieldDefinitionId === -1)
	if (!dobQuestion && state.enteredDob) {
		questions?.push({
			fieldDefinitionId: -1, // dob question
			fieldId: '',
			value: state.enteredDob,
		})
	}

	let confirmedDisclaimer = ''
	if (state.planSelections?.[state.planId]?.termsConditionAgreement) {
		confirmedDisclaimer = isNWPet
			? state.petDisclaimer
			: state.activePlan.effectiveMarketingContent.productTemplate.termsAndConditionsText

		if (isNWPet) {
			confirmedDisclaimer = state.petDisclaimer
		}
	}

	// If including spouse info, we need to add additonal questions to the request payload
	if (state.planSelections?.[state.planId]?.isIncludingSpouseInfo) {
		// if smoking question is required we need to add the question to the list
		if (state.activePlan.hasSpouseTobaccoQuestion) {
			questions.push({
				fieldDefinitionId: 79, // spouse smoker field definition id
				fieldId: '',
				value: state.planSelections?.[state.planId]?.rateRequestParams?.spouseIsSmoker ?? false,
			})
		}

		// Add the Spouse DOB question to the request because the Rates require it
		const spouse = state.dependentsInfo.find((s) => s.relationship === 'Spouse')

		if (spouse)
			questions.push({
				fieldDefinitionId: -2, // hardcoded value for spouse dob
				fieldId: '',
				value: spouse.birthDate.toString(),
			})
	}

	return {
		benefitPlanId: state.planId,
		confirmedDisclaimer,
		coverage: state.planSelections?.[state.planId]?.coverage,
		coverageId: state.planSelections?.[state.planId]?.coverageId ?? '',
		effectiveDate: state.activePlan?.keyDate as Date,
		enrollmentEvent: overrideEvent ?? (state.event as EnrollmentEvent),
		enrollmentId: state.enrollmentId?.[state.event as EnrollmentEvent] ?? '',
		frequency: (state.planSelections?.[state.planId].frequency as EnrollmentRateFrequencyNative) ?? '',
		isSingleProductFlow: state.isSingleProductFlow,
		programEnrollmentId: state.planSelections?.[state.planId]?.programEnrollmentId,
		programId: state.programId,
		questions,
		statusCode: EnrollmentStatus.NEW,
		tier: state.planSelections[state.planId].tier as Tier,
		tierId: state.planSelections[state.planId]?.tierId ?? '',
	}
}

const sanitizeYesNo = (value: string): boolean | string =>
	match(value)
		.with('Yes', 'No', (val) => val === 'Yes')
		.otherwise((val) => val)

export const saveStartEnrollmentDataToRedux =
	(props: StartEnrollmentPropsV2): AppThunkAction =>
	async (dispatch, getState): Promise<void> => {
		try {
			const { benefitPlans } = getState()

			const benefitPlan = benefitPlans.availablePlans.find((p) => p.benefitPlanId === props.benefitPlanId)
			if (!benefitPlan) {
				return
			}

			dispatch(actions.startingEnrollment())

			if (props.enrollmentEvent === EnrollmentEvent.NEWHIRE) {
				await EnrollmentService.VerifyNewHireDateForStartingEnrollment()
			}

			dispatch(actions.setStartEnrollmentData(props))

			const res: SaveEnrollmentResponseV2 = {
				benefitPlan,
				confirmationId: '',
				enrollmentId: '',
				programEnrollmentId: '',
				validationResults: [],
			}

			dispatch(actions.startEnrollmentSuccess({ event: props.event, res }))
		} catch (error) {
			const e = error as any
			appInsights.trackException({ error: e, properties: { Caller: 'saveStartEnrollmentDataToRedux' } })

			// incoming error is an axios response
			if (e && e.response && e.response.data) {
				dispatch(actions.startEnrollmentError(e.response.data))
				throw e.response.data
			} else {
				throw e
			}
		}
	}

export const startEnrollment =
	(props: StartEnrollmentPropsV2): AppThunkAction =>
	async (dispatch): Promise<SaveEnrollmentResponseV2 | void> => {
		try {
			dispatch(actions.startingEnrollment())
			const res = await EnrollmentService.startEnrollmentV2(props)
			dispatch(actions.startEnrollmentSuccess({ event: props.event, res }))
			if (res.benefitPlan) {
				// TODO: Fix up this thunk
				dispatch(saveEnrollmentSuccess({ ...res, planId: props.benefitPlanId, validationResults: [] }))
			}

			return res
		} catch (error) {
			const e = error as any
			appInsights.trackException({ error: e, properties: { Caller: 'StartEnrollment' } })

			// incoming error is an axios response
			if (e && e.response && e.response.data) {
				dispatch(actions.startEnrollmentError(e.response.data))
				throw e.response.data
			} else {
				throw e
			}
		}
	}

export const saveKnockoutEnrollment =
	(): AppThunkAction =>
	async (dispatch): Promise<SaveEnrollmentResponseV2 | void> => {
		dispatch(actions.savingEnrollment())
		try {
			dispatch(actions.saveEnrollmentSuccessRedis())
		} catch (error) {
			appInsights.trackException({ error: error as Error, properties: { Caller: 'saveKnockoutEnrollment' } })

			dispatch(actions.saveEnrollmentError(getErrorMessage(error)))
		}
	}

/**
 * * TODO: pdp - convert this to hook / react-query
 *
 * API should force consumer to handle enrollment data lookup for benefit plan to keep responsibilities low here
 * API should send in (programEnrollmentId, benefitPlanId)
 * 1) load enrollment answers
 * 2) store answers under benefitPlanId
 */
export const addProgramEnrollmentAnswers =
	(benefitPlanId: number): AppThunkAction =>
	async (dispatch, getState): Promise<PayloadAction<Error | any> | void> => {
		const {
			benefitPlans: { availablePlans },
			enrollments: enrollment,
		}: RootState = getState()

		const programEnrollmentId = enrollment.userEnrollmentOverview[benefitPlanId]?.[0]?.programEnrollmentId

		if (programEnrollmentId && benefitPlanId) {
			const answeredQuestions =
				(await EnrollmentService.getAnsweredQuestionsForProgramEnrollment(programEnrollmentId)) ?? []

			const planSelectionFormattedAnsweredQuestions = answeredQuestions.map<Question>((q) => {
				return {
					fieldDefinitionId: q.fieldDefinitionId ?? -1,
					fieldId: q.fieldId,
					value: sanitizeTrueFalseString(q.value),
				}
			})

			dispatch(
				actions.addAnsweredQuestions({
					availablePlans,
					benefitPlanId,
					questions: planSelectionFormattedAnsweredQuestions,
				}),
			)

			dispatch(actions.setAnswersLoaded(true))
		}
	}

const getLastEnrollmentFromOverview = (id, enrollment): UserEnrollmentRes =>
	enrollment.userEnrollmentOverview[id]?.[enrollment.userEnrollmentOverview[id].length - 1]

/**
 * We only want to mark as cancelled plans that are currently effective. Previously
 * the data from the UserEnrollmentSummary call didnt include plans that had a
 * future effective date. Data from the UserEnrollmentOverview call does, so here
 * make sure to only return a plan if it is currently effective.
 * @param id benefit plan id
 * @param enrollment enrollment slice state
 * @returns benefit plan or null
 */
const getLastEffectiveEnrollmentFromOverview = (id, enrollment): UserEnrollmentRes | null => {
	const lastEnrollment = getLastEnrollmentFromOverview(id, enrollment)

	return match(lastEnrollment)
		.with(P.not(P.nullish), () => (new Date(lastEnrollment.planEffectiveDate) > new Date() ? null : lastEnrollment))
		.otherwise(() => lastEnrollment)
}

export const declineOrCancelBenefitPlan = (benefitPlanId: number) => (dispatch, getState) => {
	const { benefitPlans, enrollments: enrollment }: RootState = getState()
	const programId = benefitPlans.availablePlans.find((bp) => bp.benefitPlanId === benefitPlanId)?.programId

	const currentEnrollment = getLastEffectiveEnrollmentFromOverview(benefitPlanId, enrollment)

	const isEnrolled =
		currentEnrollment?.statusCode === EnrollmentStatus.LEAVED ||
		currentEnrollment?.statusCode === EnrollmentStatus.ENROLLED

	if (isEnrolled && programId !== PET_PLAN_ID) {
		return dispatch(actions.addCanceledPlan(benefitPlanId))
	}

	return dispatch(actions.addDeclinedPlan(benefitPlanId))
}

export const saveEnrollment = (benefitPlanId: number, programId: number) => (dispatch, getState) => {
	const { enrollmentWizard: wizard, enrollments: enrollment }: RootState = getState()

	// TODO: update this as we remove other enrollment props from slice
	const currentEnrollment =
		// overview data does not currently contain declined data but recents does
		getLastEnrollmentFromOverview(benefitPlanId, enrollment) || enrollment.recentUserEnrollment[programId]

	const isLeaved =
		(currentEnrollment?.statusCode === EnrollmentStatus.LEAVED ||
			currentEnrollment?.statusCode === EnrollmentStatus.ENROLLED) &&
		!isPlanDeclinedOrCanceled(wizard.planSelections[benefitPlanId]) &&
		!wizard.planSelections[benefitPlanId].isEditing

	if (isLeaved) {
		dispatch(actions.addLeavedPlan(benefitPlanId))
	}

	return Promise.resolve(dispatch(actions.saveEnrollmentSuccessRedis()))
}

export const startEnrollmentBeforeSubmit = (plan: BenefitPlanV2) => async (dispatch, getState) => {
	const { enrollments }: { enrollments: EnrollmentsState } = getState()

	// We are now going to check the enrollments > qleSumbission state rather than
	// the enrollmentWizard > startEnrollmentData state

	// Checks if there is a NEWHIRE or LIFECHANGE qleSubmission
	if (
		enrollments.qleSubmissions.find(
			(e) => e.event === EnrollmentEvent.NEWHIRE || e.event === EnrollmentEvent.LIFECHANGE,
		)
	) {
		// Grabs the proper qleSubmission, if there are multiple
		// Will check to see if the plan being submitted is available for new hires and if so, grab that submission (if one)
		// Otherwise, it will attempt to grab the lifechange sumbission (if one)
		const qleSubmission = plan.isAvailableForNewhires
			? enrollments.qleSubmissions.find((e) => e.event === EnrollmentEvent.NEWHIRE) ??
			  enrollments.qleSubmissions.find((e) => e.event === EnrollmentEvent.LIFECHANGE)
			: enrollments.qleSubmissions.find((e) => e.event === EnrollmentEvent.LIFECHANGE)

		// If a submission was found, continue to dispatch a startEnrollment like normal
		if (qleSubmission) {
			await dispatch(
				startEnrollment({
					benefitPlanId: plan.benefitPlanId,
					enrollmentEvent: qleSubmission.enrollmentEvent,
					event: qleSubmission.event,
					eventDate: qleSubmission.eventDate,
					isSingleProductFlow: qleSubmission.isSingleProductFlow,
					programId: plan.programId,
					reason: qleSubmission.reason,
				}),
			)
		}
	}
}

export const saveProgramEnrollmentsBeforeSubmit = (benefitPlans: BenefitPlanV2[]) => async (dispatch, getState) => {
	const {
		enrollmentWizard,
		enrollments,
	}: {
		enrollmentWizard: EnrollmentWizardState
		enrollments: EnrollmentsState
	} = getState()
	dispatch(actions.savingEnrollment())

	const formSaveObject = (plan: BenefitPlanV2, enrollmentId: string): SaveEnrollmentV2 | null => {
		const { benefitPlanId: planId, programId } = plan

		return match(enrollmentWizard.planSelections[planId].enrollmentStatus)
			.with(
				EnrollmentStatus.NEW,
				() =>
					formatIntermediateSave({
						...enrollmentWizard,
						activePlan: plan,
						enrollmentId: { [enrollmentWizard.event as EnrollmentEvent]: enrollmentId } as Record<
							EnrollmentEvent,
							string
						>,
						planId,
						programId,
					}) as SaveEnrollmentV2,
			)
			.with(EnrollmentStatus.LEAVED, () => {
				// TODO: update this as we remove other enrollment props from slice
				// overview data does not currently contain declined data but recents does

				const enrolledObj =
					getLastEnrollmentFromOverview(planId, enrollments) || enrollments.recentUserEnrollment[programId]

				return formatLeavedState(
					enrolledObj ?? { event: enrollmentWizard.event, planId, programId },
					false,
					enrollmentId,
					enrollmentWizard.planSelections?.[planId]?.programEnrollmentId ?? '',
					enrollmentWizard.event as EnrollmentEvent,
					plan,
					enrollmentWizard.isSingleProductFlow,
				) as SaveEnrollmentV2
			})
			.with(
				EnrollmentStatus.DECLINED,
				() =>
					formatDeclined(
						{ ...enrollmentWizard, planId, programId },
						plan.keyDate as Date | string,
						enrollmentId,
					) as SaveEnrollmentV2,
			)
			.with(
				EnrollmentStatus.CANCELED,
				() =>
					formatCanceled(
						{ ...enrollmentWizard, planId, programId },
						plan.keyDate as Date | string,
						enrollmentId,
					) as SaveEnrollmentV2,
			)
			.otherwise(() => null)
	}

	const savePlan = async (planId: number, enrollmentId: string): Promise<SaveEnrollmentResponseV2 | null> => {
		const plan = benefitPlans.find((p) => p.benefitPlanId === planId) as BenefitPlanV2

		if (isTrustmarkLTC(plan)) return null

		if (plan.programId === PET_PLAN_ID) {
			return dispatch(
				saveEnrollmentPets(
					enrollmentWizard.planSelections[plan.benefitPlanId].enrollmentStatus as EnrollmentStatus,
					enrollmentId,
				),
			)
		} else {
			const saveObj = formSaveObject(plan, enrollmentId)

			if (!saveObj) {
				return null
			}

			return EnrollmentService.saveEnrollmentV2(saveObj)
		}
	}

	try {
		let enrollmentId = enrollmentWizard.enrollmentId[enrollmentWizard.event as EnrollmentEvent]

		const finished: (SaveEnrollmentResponseV2 & { planId: number })[] = []

		const planIdsInWizard = benefitPlans.map((bp) => bp.benefitPlanId)

		const planIdsToSave = Object.keys(enrollmentWizard.planSelections)
			.map((id) => +id)
			.filter((id) => planIdsInWizard.includes(id))

		const [firstPlanId, ...restOfPlanIds] = planIdsToSave

		const firstResponse = await savePlan(firstPlanId, enrollmentId)

		if (firstResponse) {
			enrollmentId = firstResponse.enrollmentId
			dispatch(
				actions.setEnrollmentIdForEvent({
					enrollmentId: firstResponse.enrollmentId,
					event: enrollmentWizard.event as EnrollmentEvent,
				}),
			)
		}

		const pendingSaveEnrollmentPromises = restOfPlanIds.map(async (planId) => {
			const response = await savePlan(planId, enrollmentId)
			if (response) {
				finished.push({ ...response, planId })
			}
		})

		await Promise.all(pendingSaveEnrollmentPromises)

		finished.forEach((res) => dispatch(saveEnrollmentSuccess({ ...res })))

		dispatch(actions.saveEnrollmentSuccessRedis())
	} catch (e) {
		appInsights.trackException({ error: e as Error, properties: { Caller: 'saveProgramEnrollmentsBeforeSubmit' } })
		dispatch(actions.saveEnrollmentError(getErrorMessage(e)))
		throw e
	}
}

export const declinePrograms = (planIds: number[]) => async (dispatch) => {
	for (const planId of planIds) {
		dispatch(declineOrCancelBenefitPlan(planId))
	}

	return dispatch(actions.saveEnrollmentSuccessRedis())
}

export const declineProgram = (planId: number) => async (dispatch) => {
	dispatch(declineOrCancelBenefitPlan(planId))

	return dispatch(actions.saveEnrollmentSuccessRedis())
}

export const saveEnrollmentPets =
	(status: EnrollmentStatus, overrideEnrollmentId?: string) => async (dispatch, getState) => {
		const state: RootState = getState()

		const formatSaveObj = (
			wizardState: EnrollmentWizardState,
			enrollmentId: string,
			programEnrollmentId: string,
		): PetEnrollmentSaveV2 => {
			const petPlan = state.benefitPlans.availablePlans.find((p) => p.programId === PET_PLAN_ID)
			if (!petPlan) {
				throw new Error('Unable to submit pet enrollment without a pet plan.')
			}

			return {
				benefitPlanId: petPlan.benefitPlanId,
				confirmedDisclaimer: wizardState.planSelections?.[petPlan.benefitPlanId]?.termsConditionAgreement
					? wizardState.petDisclaimer
					: '',
				effectiveDate: petPlan.keyDate,
				enrollmentEvent: wizardState.event as EnrollmentEvent,
				enrollmentId,
				isSingleProductFlow: wizardState.isSingleProductFlow,
				pets: wizardState.petInfo.map((pet) => ({
					age: pet?.age ?? '',
					breed: pet?.breed,
					coverage: pet?.coverage ?? '',
					name: pet.petName,
					petGuid: pet.petGuid,
					species: pet.species,
				})),
				postalCode: wizardState.petZip,
				programEnrollmentId,
				programId: petPlan.programId,
				statusCode: status,
			}
		}
		dispatch(actions.savingEnrollment())
		const enrollmentId =
			overrideEnrollmentId ??
			state.enrollmentWizard?.enrollmentId[state.enrollmentWizard?.event as EnrollmentEvent] ??
			''
		const programEnrollmentId =
			state.enrollmentWizard.planSelections?.[state.enrollmentWizard.activePlan.benefitPlanId]?.programEnrollmentId ??
			''
		try {
			const res = await EnrollmentService.savePetEnrollmentV2(
				formatSaveObj(state.enrollmentWizard, enrollmentId, programEnrollmentId),
			).then((res) => ({ ...res, planId: state.enrollmentWizard.activePlan.benefitPlanId }))

			return res
		} catch (e) {
			const errMsg = getErrorMessage(e)
			dispatch(actions.saveEnrollmentError(errMsg))
			throw errMsg
		}
	}

export const savePersonalInfo =
	(
		benefitPlanId: number,
		programId: number,
		wizardPlans: BenefitPlanV2[],
		und3484: boolean,
		isOnStepOne: boolean = false,
		formInfo?: PersonalInfo,
	) =>
	(dispatch, getState) => {
		const state: RootState = getState()
		const wizardState: EnrollmentWizardState = state.enrollmentWizard
		const benefitPlans: BenefitPlansState = state.benefitPlans
		const personal = formInfo ?? wizardState.personal
		if (formInfo) {
			dispatch(actions.updatePersonal(formInfo))
		}

		const formatAnswer = (answer: string, fieldDefinitionId: number): Question => ({
			fieldDefinitionId,
			fieldId: '',
			value: answer,
		})
		const filterDependents = (deps: PartialDependentList, selectedTiers): PartialDependentList => {
			const includes = (condition: Tier | string, arr: string | Array<string>): boolean => arr.includes(condition)
			const shouldShowChild = includes(EC, selectedTiers)
			const shouldShowSpouse = includes(ES, selectedTiers)
			const shouldShowSpouseAndChild = Boolean((shouldShowChild && shouldShowSpouse) || includes(FA, selectedTiers))
			const dependents = deps.length ? [...deps] : []
			const isChild = (relationship: string): boolean => includes('child', relationship.toLowerCase())
			const isSpouse = (relationship: string): boolean => includes('spouse', relationship.toLowerCase())

			if (isOnStepOne) {
				return dependents.filter((dep) => dep.relationship === SPOUSE)
			}

			if (dependents.length)
				return dependents.filter((dependent): boolean => {
					const child = isChild(dependent.relationship)
					const spouse = isSpouse(dependent.relationship)
					const hasRequiredFields: boolean = !!(
						dependent.birthDate.toString().length &&
						dependent.firstName.length &&
						dependent.gender.length &&
						dependent.lastName.length &&
						dependent.relationship.length
					)

					if (shouldShowSpouseAndChild) return hasRequiredFields && (child || spouse)

					if (shouldShowSpouse) return hasRequiredFields && spouse

					if (shouldShowChild) return hasRequiredFields && child

					return hasRequiredFields
				})

			return dependents
		}
		const personalQuestions: Array<Question> = [
			formatAnswer(personal.socialSecurityNumber, 1),
			formatAnswer(personal.phoneNumber, 2),
			formatAnswer(personal.email, -2),
		]
		dispatch(actions.savingPersons())

		const dependents = (
			shouldShowDependents(wizardState, {
				benefitPlans: state.benefitPlans.availablePlans,
				userEnrollmentOverview: state.enrollments.userEnrollmentOverview,
			}) || isOnStepOne
				? filterDependents(wizardState.dependentsInfo, getSelectedTiers(wizardState))
				: []
		).map((dep) => ({
			...dep,
			gender: dep.gender === Gender.DECLINED ? Gender.UNKNOWN : dep.gender,
			socialSecurityNumber: dep.socialSecurityNumber?.replaceAll('-', '') ?? null,
		}))
		const minChildAge = wizardPlans.reduce((prev, curr) => Math.min(prev, curr.minChildAge ?? 0), 0)
		const maxChildAge = wizardPlans.reduce((prev, curr) => Math.max(prev, curr.maxChildAge ?? 26), 0)
		const maxSpouseAge = wizardPlans.reduce((prev, curr) => Math.max(prev, curr.maxSpouseAge ?? 99), 99)
		const minSpouseAge = wizardPlans.reduce((prev, curr) => Math.min(prev, curr.minSpouseAge ?? 18), 18)
		const formSaveObj = (): { personalQuestions: Question[]; programEnrollmentId: string } => {
			if (isOnStepOne) {
				return {
					personalQuestions: [],
					programEnrollmentId: wizardState.planSelections?.[programId]?.programEnrollmentId,
				}
			}

			return { personalQuestions, programEnrollmentId: '' }
		}

		return EnrollmentService.saveEnrollmentPersonsV2({
			address: {
				...personal.address,
				state: personal.address.state ? USStatesMapping[personal.address.state] : '',
			},
			benefitPlanId: isOnStepOne ? benefitPlanId : null,
			birthDate: personal.birthDate ? new Date(Date.parse(personal.birthDate)) : null,
			dependents,
			enrollmentEvent: wizardState.event as EnrollmentEvent,
			enrollmentId: wizardState.enrollmentId[wizardState.event as EnrollmentEvent] ?? null,
			gender: personal.gender ? personal.gender.charAt(0).toUpperCase() : '',
			isSingleProductFlow: wizardState.isSingleProductFlow,
			programId: isOnStepOne ? programId : null,
			...formSaveObj(),
		})
			.then(async (res) => {
				if (dependents.length > 0) {
					const earliestDate = benefitPlans.isOE
						? benefitPlans.oeWindow?.electionsEffectiveDate
						: benefitPlans.effectiveDates[programId]

					const updatedDeps = und3484
						? await BenefitElectionsService.GetDependents()
						: await WorkerService.getDependents(state.user.userProfile.workerId)

					dispatch(
						actions.addDependentsInfoList(
							updatedDeps
								.filter((dependent) =>
									dependents.find(
										(dep) =>
											dependent.lastName === dep.lastName &&
											dep.firstName === dependent.firstName &&
											dep.gender === dependent.gender &&
											dep.relationship === dependent.relationship,
									),
								)
								.map((dep) => {
									const minAge = getMinAge({ minChildAge, minSpouseAge, relationship: dep.relationship })
									const maxAge = getMaxAge({ maxChildAge, maxSpouseAge, relationship: dep.relationship })

									return mapDep(dep, earliestDate, minAge, maxAge)
								})
								// need to sort here to match previous addDependentsInfoList call from dependents.tsx
								.sort((a, b) => {
									if (b.relationship > a.relationship) return 1
									if (b.relationship < a.relationship) return -1

									return 0
								}),
						),
					)
				}

				return dispatch(saveEnrollmentSuccess({ ...res, isSavingPerson: true, planId: benefitPlanId }))
			})
			.catch((error) => {
				appInsights.trackException({ error, properties: { Caller: 'savePersonalInfo' } })
				// unblock UI
				dispatch(actions.savingPersonsError())

				return dispatch(actions.saveEnrollmentError(getErrorMessage(error)))
			})
	}

export const getOEPlans = (availablePlans: BenefitPlanV2[], oeWindow: Record<number, OEWindowPlan>): BenefitPlanV2[] =>
	availablePlans.filter(({ benefitPlanId }) => oeWindow[benefitPlanId] != null)

export const fetchDependents =
	(wizardPlans: BenefitPlanV2[], und3484: boolean, clearDependentsInfo: boolean = false): AppThunkAction =>
	async (dispatch, getState): Promise<void> => {
		dispatch(actions.loadingDependents())
		const state: RootState = getState()
		const { availablePlans, isOE, oeWindow } = state.benefitPlans
		const workerId = state.user.userProfile.workerId
		const minChildAge = wizardPlans.reduce((prev, curr) => Math.min(prev, curr.minChildAge ?? 0), 0)
		const maxChildAge = wizardPlans.reduce((prev, curr) => Math.max(prev, curr.maxChildAge ?? 26), 0)
		const maxSpouseAge = wizardPlans.reduce((prev, curr) => Math.max(prev, curr.maxSpouseAge ?? 99), 99)
		const minSpouseAge = wizardPlans.reduce((prev, curr) => Math.min(prev, curr.minSpouseAge ?? 18), 18)
		try {
			const deps = und3484 ? await BenefitElectionsService.GetDependents() : await WorkerService.getDependents(workerId)
			if (clearDependentsInfo) {
				dispatch(actions.addDependentsInfoList([]))
			}
			dispatch(
				actions.setDependents({
					deps,
					isOE,
					maxChildAge,
					maxSpouseAge,
					minChildAge,
					minSpouseAge,
					oePlans: isOE
						? getOEPlans(availablePlans, oeWindow?.oeWindowPlans ?? ({} as Record<number, OEWindowPlan>))
						: [],
					und3484,
				}),
			)
		} catch (error) {
			const e = error as any
			dispatch(actions.setDepsError(e))
		}
	}

export const fetchPetRates = () => async (dispatch, getState) => {
	const state: RootState = getState()

	try {
		const progEnrollmentIds =
			state.enrollmentWizard.planSelections?.[state.enrollmentWizard.activePlan.benefitPlanId]?.programEnrollmentId ??
			''

		const updatedInfo: PetPostRes = await WorkerService.fetchPetRates(
			state.enrollmentWizard.petInfo,
			progEnrollmentIds,
			state.enrollmentWizard.petZip,
			state.user.userProfile.workerId,
		)
		// TODO: probably need a better check here
		// these are the error codes we have seen returned from Nationwide
		if (updatedInfo.messageCode === '500' || updatedInfo.messageCode === '400') {
			throw new Error(updatedInfo.userMessage)
		}
		dispatch(actions.savePetSuccess(updatedInfo))
	} catch (e) {
		const error = e as any
		if (error?.message?.toLowerCase() !== PET_MISSING_ZIP) dispatch(actions.savePetError({ error }))
		throw error
	}
}

const reduceEnrolledPetData = (enrolledPetData: EnrolledPetData): SplitPets =>
	enrolledPetData.pets.reduce(
		(acc: { existing: EnrolledPet[]; newlyEnrolled: EnrolledPet[] }, val: EnrolledPet) => {
			if (!val.programEnrollment?.statusCode) {
				return acc
			}

			if (val.isCovered) {
				acc.existing.push(val)

				return acc
			}
			/*
			 * Edge case where user started adding pets, left, and then
			 * started adding pets on a different profile. We don't want
			 * these pets to show up on different profiles. They will show
			 * up in data from the GET /pets call. So we need to filter them
			 * if they have a status of New and DO NOT have a matching
			 * programEnrollmentId from the UserEnrollmentsResponse for the
			 * active profileId
			 */
			// const plan: BenefitPlanV2 = state.enrollmentWizard.activePlan
			// const { recentUserEnrollment, userEnrollment, userEnrollmentSummary } = state.enrollments
			// const recentEnrollment = recentUserEnrollment[plan.programId]
			// const enrollment = userEnrollment[plan.programId]
			// const enrollmentSummary = userEnrollmentSummary[plan.programId]
			const newAndNotMatching = val.programEnrollment?.statusCode === EnrollmentStatus.NEW

			// only include pets that were not previously marked as declined
			if (val.programEnrollment?.statusCode !== EnrollmentStatus.DECLINED && !newAndNotMatching)
				acc.newlyEnrolled.push(val)

			return acc
		},
		{ existing: [], newlyEnrolled: [] },
	)
const breedsQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 60 * 60 * 60,
		},
	},
})

// Matches pet breed object based on breedCode from db
const matchPetBreed = (breedCode, breedArray) => {
	if (!breedCode) return null
	const codeStr = breedCode.toString()

	return breedArray.find((b) => b.code === codeStr) ?? null
}

const fetchEnrollmentsPetData = async (dispatch, getState) => {
	const state: RootState = getState()
	try {
		dispatch(actions.fetchingPetEnrollments())
		const enrolledPetData: EnrolledPetData = await WorkerService.fetchPetEnrollments(state.user.userProfile.workerId)

		if (state.enrollmentWizard.wrp2596) enrolledPetData.pets = enrolledPetData.pets.filter((x) => !x.isCancelled)

		const canineBreedData = await breedsQueryClient.fetchQuery<PetBreed[] | undefined>({
			queryFn: () => EnrollmentService.getNationwidePetBreeds('C'),
			queryKey: ['nationwidepetbreeds', 'C'],
		})
		const felineBreedData = await breedsQueryClient.fetchQuery<PetBreed[] | undefined>({
			queryFn: () => EnrollmentService.getNationwidePetBreeds('F'),
			queryKey: ['nationwidepetbreeds', 'F'],
		})
		const splitPets = reduceEnrolledPetData(enrolledPetData)
		const filteredNewlyEnrolled: Array<EnrolledPet> = [
			...splitPets.newlyEnrolled,
			...enrolledPetData.pets.filter(
				(pet) => !pet.statusCode || (pet.statusCode === EnrollmentStatus.NEW && !pet?.isPushed && !pet.isCovered),
			),
		].filter((pet, idx, arr) => arr.findIndex((x) => x.petGuid === pet.petGuid) === idx)
		const mappedPets: any = filteredNewlyEnrolled.map((pet) => {
			const targetBreedArray = pet.species === PetSpecies.DOG ? canineBreedData : felineBreedData

			return {
				age: pet.age,
				amount: pet.amount,
				breed: pet.breed ? pet.breed : matchPetBreed(pet.breedCode, targetBreedArray),
				breedCode: pet.breedCode,
				coverage: pet.coverage,
				coverageName: pet.coverageName,
				hasWellness: pet.hasWellness,
				isCancelled: pet.isCancelled,
				isCovered: pet.isCovered,
				petGuid: pet.petGuid,
				petName: pet.petName,
				rates: [],
				species: pet.species,
			}
		})
		dispatch(actions.addExistingPets(mappedPets))
		dispatch(actions.fetchPetEnrollmentsSuccess({ enrolledPetData, splitPets }))
	} catch (error) {
		dispatch(actions.fetchPetEnrollmentsError({ error: getErrorMessage(error) }))
	}
}

const fetchBenniesPetData = async (dispatch) => {
	try {
		dispatch(actions.fetchingPetEnrollments())
		const data = await BenefitElectionsAPI.GetPets()
		const getCoverageName = (cc: string): string =>
			cc.toLowerCase().startsWith('vbw') ? 'My Pet Protection With Wellness Plan' : 'My Pet Protection Plan'

		const pets: any = data.pets.map((pet) => ({
			age: pet.age,
			amount: pet.amount,
			breed: pet.breed,
			breedCode: pet.breed.code,
			coverage: pet.coverageCode,
			coverageName: pet.coverageName.length ? pet.coverageName : getCoverageName(pet.coverageCode),
			effectiveDate: pet.effectiveDate,
			isPushed: pet.isPushed,
			petGuid: pet.nationwidePetId,
			petName: pet.petName,
			policyNumber: pet.policyNumber,
			rates: [],
			species: pet.species,
		}))

		const enrolledPetData: any = {
			...data,
			mappedPets: pets,
		}

		dispatch(
			actions.fetchPetEnrollmentsSuccess({
				enrolledPetData,
				splitPets: { existing: [], newlyEnrolled: [] }, //splitPets is ignored with bennies
			}),
		)
	} catch (error) {
		dispatch(actions.fetchPetEnrollmentsError({ error: getErrorMessage(error) }))
	}
}

export const fetchEnrolledPetData = (und3484 = false) => (und3484 ? fetchBenniesPetData : fetchEnrollmentsPetData)

export const fetchTrustmarkEnrollment =
	(benefitPlanId: number, und3484?: boolean, didUserSubmitForTheFirstTime?: boolean) => async (dispatch) => {
		const data = und3484
			? await BenefitElectionsService.getEnrollmentData()
			: await EnrollmentService.getEnrollmentData()

		const pendingPlans = data?.pxResults?.filter((s) => s.applicationStatus === ApplicationStatus.PendingCompletion)
		const enrolledPlans = data?.pxResults?.filter(
			(s) => s.applicationStatus === ApplicationStatus.Completed || s.applicationStatus === ApplicationStatus.Active,
		)

		// User is enrolling
		if ((pendingPlans && pendingPlans.length) || (enrolledPlans && enrolledPlans.length)) {
			dispatch(actions.addSelectedPlan(benefitPlanId))
			dispatch(
				actions.setTrustmarkEnrollment({
					pendingTierTitle: data?.pendingTierTitle,
					plans: pendingPlans.length ? pendingPlans : enrolledPlans,
				}),
			)

			if (didUserSubmitForTheFirstTime) {
				trackEvent(reactPlugin, events.benefitsWizard.trustmark.enrollmentPendingCompletion)
			}
		} else if (!enrolledPlans || !enrolledPlans.length) {
			// User declined
			dispatch(actions.addDeclinedPlan(benefitPlanId))
		}
	}

export const submitBenefitElections = (plans: BenefitPlanV2[]) => async (dispatch, getState) => {
	const normalizeEnrollmentStatus = (status: EnrollmentStatus | undefined) => {
		if (!status) return ''
		if (status === EnrollmentStatus.NEW) return EnrollmentStatus.ENROLLED

		return status
	}
	const mapToSubmitBenefitElectionDTO = (plan: BenefitPlanV2): BenefitElectionDTO => {
		const planSelection = state.enrollmentWizard.planSelections[plan.benefitPlanId]
		const isNWPet = plan.programId === PET_PLAN_ID
		const disclaimerText = isNWPet
			? state.enrollmentWizard.petDisclaimer
			: plan.effectiveMarketingContent.productTemplate.termsAndConditionsText
		const disclaimer = planSelection.termsConditionAgreement ? disclaimerText : ''

		return {
			AuditAmount: planSelection.amount,
			BenefitPlanId: plan.benefitPlanId,
			CoverageId: planSelection.coverageId,
			Disclaimer: disclaimer,
			QuestionAnswers: Object.entries(planSelection.planAnswers).map(([questionId, answer]) => {
				return {
					QuestionId: questionId,
					Value: sanitizeYesNo(answer.value),
				}
			}),
			StatusCode: normalizeEnrollmentStatus(planSelection.enrollmentStatus),
			TierCode: planSelection.tier,
			TierId: planSelection.tierId,
		}
	}

	const mapToDependentDTO = (dependent: PartialDependent) => ({
		BirthDate: new Date(formatDate(dependent.birthDate)),
		FirstName: dependent.firstName,
		Gender: dependent.gender,
		HasDisability: dependent.isDisabled,
		IsStudent: dependent.isStudent,
		LastName: dependent.lastName,
		Relationship: dependent.relationship,
	})

	const getPetAnnualAmount = (pet: PetData) => {
		const selectedRate = pet.rates.find((r) => r.coverage === pet.coverage)

		return selectedRate?.annualAmount ?? 0
	}

	const mapToPetsDTO = (pet: PetData) => ({
		//TODO: is this ok?
		Age: pet.age ? +pet.age : 0,
		AnnualAmount: getPetAnnualAmount(pet),
		CoverageCode: pet.coverage,
		CoverageName: pet.coverageName,
		Name: pet.petName,
		NationwidePetBreedId: pet.breed?.id ?? '',
	})

	const state: RootState = getState()
	dispatch(actions.submittingBenefitElections())

	const eventDate = state.enrollmentWizard.startEnrollmentData?.eventDate
		? new Date(state.enrollmentWizard.startEnrollmentData?.eventDate)
		: undefined

	const pendingTrustmarkEnrollments = state.enrollmentWizard.trustmarkEnrollment.plans
	let trustmarkCoverageTiers: string[] | undefined = undefined
	if (pendingTrustmarkEnrollments) {
		trustmarkCoverageTiers = pendingTrustmarkEnrollments.map((e) => e.coverageTier)
	}

	try {
		const { additionalEvents } = await BenefitElectionsService.submitBenefitElections({
			BenefitElections: plans.filter((bp) => !isTrustmarkLTC(bp)).map(mapToSubmitBenefitElectionDTO),
			Dependents: state.enrollmentWizard.dependentsInfo.map(mapToDependentDTO),
			EventDate: eventDate,
			EventType: state.enrollmentWizard.event?.toString() ?? '',
			Pets: state.enrollmentWizard.petInfo.map(mapToPetsDTO),
			TrustmarkCoverageTiers: trustmarkCoverageTiers,
		})
		dispatch(actions.submitBenefitElectionsSuccess())

		if (
			pendingTrustmarkEnrollments &&
			pendingTrustmarkEnrollments.length > 0 &&
			!additionalEvents?.find((e) => e === 'TRUSTMARK_ENROLLMENT_SUBMISSION_FAILED')
		) {
			trackEvent(reactPlugin, events.benefitsWizard.trustmark.enrollmentCompleted)
		}
	} catch (error) {
		appInsights.trackException({
			exception: error as Error,
			properties: {
				Caller: 'submitBenefitElectiions',
				Event: state.enrollmentWizard.event,
				OrganizationId: state.site.siteInfo?.organizationId ?? '',
			},
		})
		dispatch(actions.submitBenefitElectionsError(error as AxiosError<any>))
	}
}

const getErrorMessage = (error): string[] => {
	const errorMessage: string[] = []
	if (error.response?.data && Array.isArray(error.response.data))
		errorMessage.push(...error.response.data.map((x) => x.text))
	else if (error.response?.data) errorMessage.push(error.response.data)
	else
		errorMessage.push(`An error occurred while processing your selection, please try again.
	If this error continues, please contact Customer Care.`)

	return errorMessage
}

// export const submitFromProductPage = () => (dispatch, getState) => {}
