import { axiosInstance as axios, petAxiosInstance } from 'config/axios'
import { Dependent, DependentList, PartialDependentList } from 'types/dependent'
import { Worker } from 'types/worker'
import UrlBuilder from './util/url-builder'
import { UserEnrollmentRes } from 'types/user-enrollment'
import { PetData, PetPostRes } from 'types/pet'

const enrollmentsSubdomain: string = 'enrollments'
const urlBuilder: UrlBuilder = new UrlBuilder(enrollmentsSubdomain)
export class WorkerService {
	static addDependent(workerId: string, dependent: Dependent): Promise<void> {
		return axios.post(urlBuilder.setEndpoint(`api/worker/${workerId}/dependent`).url(), { ...dependent })
	}

	static editDependent(workerId: string, dependent: Dependent): Promise<Dependent> {
		return axios.post(urlBuilder.setEndpoint(`api/worker/${workerId}/dependent`, dependent.dependentId).url(), {
			...dependent,
		})
	}

	static getDependentById(workerId: string, dependentId: number): Promise<Dependent> {
		return axios.get(urlBuilder.setEndpoint(`api/worker/${workerId}/dependent`, dependentId).url())
	}

	static getDependents(workerId: string): Promise<DependentList> {
		return axios.get(urlBuilder.setEndpoint(`api/worker/${workerId}/dependent`).url())
	}

	static getWorkerById(workerId: string): Promise<Worker> {
		return axios.get(urlBuilder.setEndpoint('api/worker', workerId).url())
	}

	/**
	 * TODO: Finish this service
	 * @param petData - Array of pet information
	 * @returns unknown at this time... rates?
	 */
	static fetchPetRates(
		pets: Array<PetData>,
		programEnrollmentId: string,
		postalCode: string,
		workerId: string,
	): Promise<PetPostRes> {
		const ratesEndpoint: string = `api/worker/${workerId}/dependent/pets`
		const trimmedPets = pets.map((pet) => ({
			...pet,
			name: pet.petName.trim(),
		}))

		return petAxiosInstance.post(urlBuilder.setEndpoint(ratesEndpoint).url(), {
			pets: trimmedPets,
			postalCode: postalCode ?? '',
			programEnrollmentId: programEnrollmentId ?? '',
		})
	}

	/**
	 *
	 * @param workerId - users id
	 * @returns
	 */
	static fetchPetEnrollments(workerId: string): Promise<any> {
		const saveEndpoint: string = `api/worker/${workerId}/dependent/pets`

		return axios.get(urlBuilder.setEndpoint(saveEndpoint).url())
	}

	/**
	 * Retrieves pets which may or may not have been submitted yet
	 * @param workerId - users id
	 * @returns
	 */
	static fetchTempPetEnrollments(workerId: string): Promise<any> {
		const saveEndpoint: string = `api/worker/${workerId}/dependent/temppets`

		return axios.get(urlBuilder.setEndpoint(saveEndpoint).url())
	}

	static async getUserEnrollments(): Promise<Array<UserEnrollmentRes>> {
		try {
			const userEnrollments: Array<UserEnrollmentRes> = await axios.get(
				urlBuilder.setEndpoint('api/worker/UserEnrollments').url(),
			)

			const sortDeps = (deps): PartialDependentList =>
				deps.sort((a, b) => {
					if (b.relationship > a.relationship) return 1
					if (b.relationship < a.relationship) return -1

					return 0
				})

			return userEnrollments.map((enrollment) => ({
				...enrollment,
				dependents: enrollment.dependents ? sortDeps(enrollment.dependents) : [],
			}))
		} catch (e) {
			throw new Error(e as string)
		}
	}

	static async getUserEnrollmentSummary(): Promise<Array<UserEnrollmentRes>> {
		try {
			const userEnrollments: Array<UserEnrollmentRes> = await axios.get(
				urlBuilder.setEndpoint('api/worker/UserEnrollmentSummary').url(),
			)

			const sortDeps = (deps): PartialDependentList =>
				deps.sort((a, b) => {
					if (b.relationship > a.relationship) return 1
					if (b.relationship < a.relationship) return -1

					return 0
				})

			return userEnrollments.map((enrollment) => ({
				...enrollment,
				dependents: enrollment.dependents ? sortDeps(enrollment.dependents) : [],
			}))
		} catch (e) {
			throw new Error(e as string)
		}
	}

	static async getRecentUserEnrollments(): Promise<UserEnrollmentRes[]> {
		try {
			return axios.get(urlBuilder.setEndpoint('api/worker/UserEnrollments/Recent').url())
		} catch (e) {
			throw new Error(e as string)
		}
	}
}
