import { Close, Search } from '@mui/icons-material'
import { Button, IconButton, Input, InputAdornment, useMediaQuery, useTheme } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { MQ_2LG } from 'types/breakpoints'
import { buildUrl } from 'utils/url'
import styles from './search-box.module.scss'
interface SearchBoxProps {
	closeMenu?: () => void
	currentRefinement?: string
	id?: string
	refine: (...args: any[]) => Promise<void>
	sendAnalytics: (...args: any[]) => unknown
	onCancelClick: () => void
}

const SearchBox: FC<SearchBoxProps> = ({
	closeMenu,
	currentRefinement,
	id,
	refine,
	sendAnalytics,
	onCancelClick,
}: SearchBoxProps) => {
	const location = useLocation()
	const navigate = useNavigate()
	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down(MQ_2LG + 1))
	const [searchParams] = useSearchParams()
	const [searchVal, setSearchVal] = useState<string>(searchParams.get('query') ?? '')
	const searchRef = useRef<any>(null)

	const updateUrl = useCallback(
		(query: string): void => {
			const { pathname } = location
			const searchRoute: { pathname: string; search?: string } = { pathname }
			const category = searchParams.get('category')
			const brand = searchParams.get('brand')
			if (query.length > 0 || brand || category) {
				searchRoute.pathname = '/search'
				let search = ''
				if (query.length > 0) {
					search = `query=${encodeURIComponent(query)}`
				}
				if (brand) {
					search = search ? `${search}&${buildUrl(brand, 'brand')}` : `${buildUrl(brand, 'brand')}`
				}
				searchRoute.search = search
			}
			navigate(searchRoute)
		},
		[location, navigate, searchParams],
	)

	const clearSearchValue = useCallback(
		(shouldRedirect: boolean = false): void => {
			refine('')
			setSearchVal('')
			if (shouldRedirect) {
				updateUrl('')
			}
		},
		[refine, updateUrl],
	)

	/**
	 * Resets search results when the location changes away from search
	 */
	useEffect(() => {
		if (!location.pathname.includes('search') && currentRefinement?.length) {
			clearSearchValue()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, currentRefinement])

	/**
	 * kicks off the search
	 * @param searchVal value to search on
	 * @returns nothing
	 */
	const search = (searchVal: string): void => {
		refine(searchVal)
		sendAnalytics(searchVal)
		document.getElementById('searchInput')?.blur()
	}

	/**
	 * Trigger focus on page render
	 */
	const onKeyPressed = (e): void => {
		if (e.key === 'Enter') {
			updateUrl(searchVal)
		}
	}

	const onSearchIconClick = useCallback(
		(e): void => {
			e.preventDefault()
			updateUrl(searchVal)
		},
		[searchVal, updateUrl],
	)

	const onChange = (e): void => {
		e.preventDefault()
		setSearchVal(e.currentTarget.value)
		if (closeMenu) closeMenu()
	}

	useEffect(() => {
		const query = searchParams.get('query')
		if (query) {
			search(query)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	const isClearIconVisible = currentRefinement || searchVal.length > 0
	const endAdornment: JSX.Element = useMemo(
		() => (
			<InputAdornment position='end'>
				<div className={styles['icons']}>
					{isClearIconVisible && (
						<IconButton aria-label='Clear Search' id='button-clear-search' onClick={() => clearSearchValue(true)}>
							{<Close />}
						</IconButton>
					)}
					<IconButton aria-label='Search' id='button-search' onClick={onSearchIconClick}>
						<Search />
					</IconButton>
				</div>
			</InputAdornment>
		),
		[clearSearchValue, isClearIconVisible, onSearchIconClick],
	)

	return (
		<div className={styles['container']}>
			<div className={styles['input-container']}>
				<Input
					id={id ?? 'searchInput'}
					inputRef={searchRef}
					className={styles['search-bar']}
					onChange={onChange}
					onKeyDown={onKeyPressed}
					placeholder='What can we help you find?'
					value={searchVal}
					endAdornment={endAdornment}
					disableUnderline
					inputProps={{
						'aria-label': 'What can we help you find?',
					}}
					autoComplete='cs-search'
					name='Benefits and Offers Search'
				/>
			</div>
			{mobileView && (
				<Button
					variant='text'
					color='error'
					aria-label={'Cancel Search'}
					className={styles['cancel-action']}
					onClick={onCancelClick}
				>
					CANCEL
				</Button>
			)}
		</div>
	)
}
export default connectSearchBox(SearchBox)
